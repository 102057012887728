import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import BalanceChart from "../../Shared/Chart/BalanceChart";
import VRMChart from "../../Shared/Chart/VRMChart";

const UserDashboard = ({ data }) => {
  const [leads, setLeads] = useState([]);
  const [userBalance, setUserBalance] = useState({});

  useEffect(() => {
   /*  const loadUser = async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/accounts/agentlogin/`,
        { username: `${data?.username}` }
      );
      const token = res?.data?.token;
      if (token) {
        loadLeadData(token);
        loadBalance(token);
      }
    }; */

    /* const loadBalance = async (token) => {
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/agent_lead/balance`,
        { headers: { Authorization: `Token ${token}` } }
      );
      if (res.status === 200) {
        setUserBalance(res?.data);
      }
    }; */
    /* const loadLeadData = async (token) => {
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/lead/?user=${data?.id}`,
        { headers: { Authorization: `Token ${token}` } }
      );
      setLeads(res?.data);
    }; */
    // loadUser();
  }, [data]);

  return (
    <div className=" mt-5 h-screen">
      <div className=" h-72 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <h1 className=" mx-10 my-3 text-2xl">
            Total Leads : {leads?.length}
          </h1>
          <div className=" h-72">
            <VRMChart data={leads} />
          </div>
        </div>
        <div>
          <h1 className=" mx-10 my-3 text-2xl">Total Submited Leads: 0</h1>
          <div className=" h-72">
            <VRMChart data={leads} />
          </div>
        </div>
      </div>
      <div className=" my-20 h-72 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <h1 className=" mx-10 my-3 text-2xl">
            Total Earning : {userBalance?.balance}
          </h1>
          <div className=" h-72">
            <BalanceChart data={leads} userBalance={userBalance} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
