import axios from "axios";
import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { MdOutlineFileDownloadDone } from "react-icons/md";
import useHeaders from "../../Shared/Header/Header";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import Select from "react-select";
import MiniLoader from "./../../Shared/Loader/MiniLoader";

const EditPolicy = ({
  setShowModal,
  changes,
  setChanges,
  setEditModal,
  policyData,
  loading,
  id,
}) => {
  const headers = useHeaders();
  const [institutes, setInstitutes] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = React.useState();
  const [selectedProduct, setSelectedProduct] = React.useState();
  const [subject, setSubject] = useState("");
  const [remarks, setRemarks] = useState("");

  //   All Event Handler
  const handleInstituteChange = (selectedOption) => {
    setSelectedInstitute(selectedOption);
  };

  const handleProductTypeChange = (selectedOption) => {
    setSelectedProduct(selectedOption);
  };

  //   Get All Institutes
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_MAIN_DOMAIN}/institute/list/`, headers)
      .then((res) => {
        setInstitutes(res?.data);
      });
  }, []);

  //   Get All Product Types Data
  const productTypeOptions = [
    { value: 1, label: "Credit Card" },
    { value: 2, label: "Personal Loan" },
    { value: 3, label: "Home Loan" },
    { value: 4, label: "Car Loan" },
    { value: 5, label: "Land Loan" },
  ];

  //   Submit The Form
  const handleUpdatePolicy = (event) => {
    event.preventDefault();
    const data = {
      institute: selectedInstitute?.value || policyData?.institute,
      product: selectedProduct?.value || policyData?.product,
      subject: subject || policyData?.subject,
      remarks: remarks || policyData?.remarks,
    };

    axios
      .put(
        `${process.env.REACT_APP_HOST_URL}/api/new_policy/${id}/`,
        data,
        headers
      )
      .then((response) => {
        if (response.status === 200) {
          SuccessAlert("Update Successfull", "success");
          setEditModal(false);
          setChanges(changes + 1);
        }
      })
      .catch((error) => {
        SuccessAlert(error?.message, "error");
      });
  };

  //   Custom Style For Input
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 43,
      minHeight: 43,
      paddingLeft: 3,
    }),
  };

  return (
    <div>
      <div className="fixed z-10 overflow-y-auto top-0 w-full left-0">
        <div className="flex items-center justify-center h-screen px-4 text-center">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div className="bg-white rounded-md text-left overflow-hidden shadow-xl transform my-8 2xl:w-[55%] xl:w-[60%] w-[55%] px-4">
            <div className="pt-5 px-2 pb-3">
              <h4 className="text-2xl font-semibold text-[#2563eb]">
                Edit Policy
              </h4>
            </div>
            {loading ? (
              <MiniLoader />
            ) : (
              <div className="px-3 rounded bg-white text-center border-[#2563eb] mx-auto text-gray-700 h-full pb-5">
                <form onSubmit={handleUpdatePolicy}>
                  <div className="lg:flex md:flex block justify-between items-center gap-x-4">
                    <div className="w-full mb-2">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-start"
                        htmlFor="institute"
                      >
                        Institute
                      </label>
                      <Select
                        onChange={handleInstituteChange}
                        options={institutes?.map((institute) => ({
                          value: institute?.id,
                          label: institute?.name,
                        }))}
                        styles={customStyles}
                        placeholder="Select Institute"
                        defaultInputValue={policyData?.bank_name}
                        className="w-full border-none text-gray-700 rounded mb-1 leading-tight text-start"
                      />
                    </div>
                    <div className="w-full mb-2">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-start"
                        htmlFor="product"
                      >
                        Product
                      </label>
                      <Select
                        onChange={handleProductTypeChange}
                        options={productTypeOptions?.map((product) => ({
                          value: product?.value,
                          label: product?.label,
                        }))}
                        styles={customStyles}
                        placeholder="Select Product"
                        defaultInputValue={policyData?.product_name}
                        className="w-full border-none text-gray-700 rounded mb-1 leading-tight text-start"
                      />
                    </div>
                  </div>
                  <div className="w-full mb-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2 text-start"
                      htmlFor="subject"
                    >
                      Subject
                    </label>
                    <input
                      className="w-full my-2 border border-gray-300 rounded py-2.5 px-2 focus:bg-gray-50 focus:outline-[#2563eb]"
                      type="text"
                      name="subject"
                      placeholder="Enter Subject"
                      onChange={(e) => setSubject(e.target.value)}
                      defaultValue={policyData?.subject}
                      required
                    />
                  </div>

                  <div className="w-full mb-2">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2 text-start"
                      htmlFor="remarks"
                    >
                      Remarks
                    </label>
                    <textarea
                      className="w-full my-2 border border-gray-300 rounded py-2.5 px-2 focus:bg-gray-50 focus:outline-[#2563eb]"
                      type="text"
                      name="remarks"
                      placeholder="Enter Yiour Remarks"
                      onChange={(e) => setRemarks(e.target.value)}
                      defaultValue={policyData?.remarks}
                      rows={4}
                      required
                    />
                  </div>
                  <div className="py-5 float-right">
                    <div className="flex items-center gap-x-4">
                      <button
                        type="submit"
                        className="py-1.5 px-3 text-white bg-[#2563eb] hover:text-[#2563eb] hover:bg-white border border-[#2563eb] rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                      >
                        <MdOutlineFileDownloadDone className="text-xl" />
                        <p> Submit</p>
                      </button>
                      <button
                        onClick={() => setEditModal(false)}
                        type="reset"
                        className="py-1.5 px-4 text-white bg-red-500 hover:text-red-600 hover:bg-white border border-red-600 rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                      >
                        <FaTimes />
                        <p> Cancel</p>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPolicy;
