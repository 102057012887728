import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LeadListTable from "../LeadListTable";
import axios from "axios";
import HeadingTitle from "../../../Shared/HeadingTitle/HeadingTitle";
import useHeaders from "../../../Shared/Header/Header";
import { useSelector } from "react-redux";
import { RiAddLine } from "react-icons/ri";
import usePermission from "./../../../lib/Hook/UsePermission";

const LoanLeadList = ({ vrmID, data }) => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const [leadList, setLeadList] = useState([]);
  const [isloading, setisLoading] = useState(false);
  const [api, setApi] = useState("api/lead");
  const userData = useSelector((state) => state.userSlice.user);
  const id = userData?.user_data?.user;
  const [path, setPath] = React.useState("loanLead");

  useEffect(() => {
    setisLoading(true);
    axios
      .get(`${process.env.REACT_APP_HOST_URL}/api/loan_lead/`, headers)
      .then((resp) => {
        if (resp) {
          setLeadList(resp?.data?.results);
          setPath("loanLead");
          setisLoading(false);
        }
      });
  }, [api, id]);

  return (
    <div className=" h-screen mx-4 mt-4 overflow-scroll">
      <div className="flex justify-between items-center">
        <HeadingTitle title="Loan Lead List" />
        <div className="flex items-center gap-x-2">
          {checkPermission("add_virtualagentlead") && (
            <div className="bg-[#2563eb] border border-[#2563eb] hover:bg-transparent hover:text-[#2563eb] duration-300 px-4 py-2 rounded-md text-white">
              <Link
                to={
                  vrmID ? `/newLoanlead/${data?.id || vrmID}` : `/newLoanlead`
                }
              >
                <div className="flex items-center gap-x-1">
                  <RiAddLine className="text-2xl" />
                  <span>Create Loan Lead</span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
      {checkPermission("view_virtualagentlead") && (
        <LeadListTable isloading={isloading} data={leadList} path={path} />
      )}
    </div>
  );
};

export default LoanLeadList;
