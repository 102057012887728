import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Account from "../Account/Account";
import PaymentStatusTable from "../PaymentStatus/PaymentStatusTable";
import Tabs from "./Tabs";
import { BiPhone } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { FaRegHandshake } from "react-icons/fa";
import useHeaders from "../../Shared/Header/Header";
import Loader from "../../Shared/Loader/Loader";
import { useSelector } from "react-redux";
import UserDashboard from "../UserDashboard/UserDashboard";
import SpecialCardCommission from "../SpecialCommission/SpecialCardCommission";
import SpecialLoanCommission from "./../SpecialCommission/SpecialLoanCommission";
import CardLeads from "./../VRMLeads/CardLeads";
import LoanLeads from "./../VRMLeads/LoanLeads";
import usePermission from './../../lib/Hook/UsePermission';

const VRMAccount = () => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const { vrmID } = useParams();
  const [vrmUser, setVRMUser] = useState("");
  const status = useSelector((state) => state.userSlice.status);
  const [changes,setChanges]=useState(0);

  useEffect(() => {
    const loadData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/agent/register/${vrmID}/`,
        headers
      );

      if (response?.status === 200) {
        setVRMUser(response?.data);
      }
    };
    loadData();
  }, [vrmID,changes]);

  const items = [
    {
      id: "Dashboard",
      label: "Dashboard",
      content: <UserDashboard vrmID={vrmID} data={vrmUser} />,
    },
    {
      id: "Account",
      label: "Account",
      content: <Account data={vrmUser} changes={changes} setChanges={setChanges} />,
    },
    {
      id: "cardLeads",
      label: "Card Leads",
      content: <CardLeads vrmID={vrmID} vrmUser={vrmUser} />,
    },
    {
      id: "loanLeads",
      label: "Loan Leads",
      content: <LoanLeads vrmID={vrmID} vrmUser={vrmUser} />,
    },
    {
      id: "CardCommission",
      label: "Card Commission",
      content: <SpecialCardCommission data={vrmUser} />,
    },
    {
      id: "LoanCommission",
      label: "Loan Commission",
      content: <SpecialLoanCommission data={vrmUser} />,
    },
    {
      id: "Transaction",
      label: "Transaction",
      content: <PaymentStatusTable />,
    },
  ];

  return (
    <>
      {vrmUser ? (
        <div className="h-screen overflow-y-scroll m-4 pb-4">
          {/* vrm Account header  */}
          <div className="border rounded-md bg-white pb-4">
            <div className="lg:flex block py-3 rounded-md items-center justify-start px-5">
              <div className="lg:w-[25%] w-full">
                <div className="flex items-center">
                  <img
                    className="h-20 w-20 flex justify-center items-center mx-2 border-2 border-sky-500 rounded-full"
                    src={vrmUser?.profile_image}
                    alt="Profile"
                  />
                  <div className="mx-2">
                    <h1 className="text-lg text-slate-900 font-semibold">
                      {vrmUser?.first_name + " " + vrmUser?.last_name}
                    </h1>
                    <div className="flex items-center">
                      <h1 className="bg-[#2563eb] text-white px-2 rounded-full mt-1">
                        {status || vrmUser?.status_name}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-[25%] w-full mt-3 lg:mt-0">
                <h1 className="font-semibold text-left text-lg">
                  Contact Details
                </h1>
                <div>
                  <div className="sm:whitespace-normal flex items-center my-1">
                    <BiPhone size={19} className="text-[#1e40afd3]" />
                    <p className="text-sm text-gray-900 mx-2 font-normal">
                      {vrmUser?.phone || "N/A"}
                    </p>
                  </div>
                  <div className="sm:whitespace-normal flex items-center my-1">
                    <AiOutlineMail size={18} className="text-[#1e40afd3]" />
                    <p className="text-sm text-gray-900 mx-2 font-normal">
                      {vrmUser?.email || "N/A"}
                    </p>
                  </div>
                  <div className="sm:whitespace-normal flex items-center my-1">
                    <FaRegHandshake size={19} className="text-[#1e40afd3]" />
                    <p className="text-sm text-gray-900 mx-2 font-normal">
                      Joined: {vrmUser?.created_at || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:w-[50%] w-full">
                <div className=" flex justify-end  h-36"></div>
              </div>
            </div>
            <div className="mx-2 p-2 border-t">
              <Tabs items={items} />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default VRMAccount;
