import { useEffect, useState } from "react";
import axios from 'axios';
import useHeaders from "../Header/Header";

const useInstitute = (contentType) => {
    const headers = useHeaders();
    const [institutes, setInstitutes] = useState([]);

    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_HOST_URL}/api/commission-wise-unique-institutes/?content_type=${contentType}`,
            headers
          )
        .then((res) => {
            setInstitutes(res?.data);
        })
    }, [contentType]); 

    return { institutes, setInstitutes };
}

export default useInstitute;
