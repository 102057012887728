import React, { useEffect, useState } from "react";
import axios from "axios";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useHeaders from "../../Shared/Header/Header";
import Loader from "../../Shared/Loader/Loader";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";

const CreateTeamForm = () => {
  const headers = useHeaders();
  const { token, user_data } = useSelector((state) => state.userSlice.user);
  const router = useNavigate();
  const [users, setUsers] = useState([]);
  const [permission, setPermission] = useState([]);
  const [team, setTeam] = useState([]);
  const [description, setDescription] = useState("");

  const [content_type, setContent_type] = useState([]);

  const handleChange = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      setPermission([...permission, name]);
    } else {
      const restData = permission.filter((item) => item !== name);
      setPermission(restData);
    }
    if (name === "allSelect") {
      let tempUser = content_type.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
    } else {
      let tempUser = content_type.map((user) =>
        user.name === name ? { ...user, isChecked: checked } : user
      );
      setUsers(tempUser);
    }
  };

  useEffect(() => {
    const loadPermission = async () => {
      const content_type = await fetch(
        `${process.env.REACT_APP_HOST_URL}/accounts/content_type/`,
        headers
      );
      const rest = await content_type.json();
      setContent_type(rest);
    };

    loadPermission();
  }, []);

  const handleName = (e) => {
    setTeam(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmitFile = async (event) => {
    event.preventDefault();

    const data = {
      description: description,
      name: team,
      permissions: permission,
      created_by: user_data && user_data.first_name + " " + user_data.last_name,
    };

    axios
      .post(`${process.env.REACT_APP_HOST_URL}/api/team/`, data, headers)
      .then((response) => {
        if (response.status === 201) {
          SuccessAlert("Team Created", "success");
          router("/manageteam");
        }
      })
      .catch((error) => {
        SuccessAlert(error?.message, "error");
      });
  };

  return (
    <div className="h-[87vh] overflow-scroll mx-4">
      <HeadingTitle title="Create Team" />
      {content_type.length > 0 ? (
        <form
          onSubmit={handleSubmitFile}
          className="border bg-white px-4 py-3 rounded-md mt-4"
        >
          <div className="mb-4">
            <label className=" mb-1 text-[15px] block font-medium">
              Team Name
            </label>
            <input
              required
              className="w-full px-4 py-2.5 rounded-md focus:outline-[0.5px] focus:outline-sky-500 border"
              type="text"
              name="name"
              id=""
              placeholder="Name"
              onChange={handleName}
            />
          </div>
          <p className="text-[15px] mb-1 font-medium">Permission</p>
          <div className="hidden md:grid grid-cols-5 place-content-center place-items-center py-2 text-[15px] uppercase bg-gray-700 text-white rounded-t-md text-center">
            <h5>Modal</h5>
            <h5>View</h5>
            <h5>Add</h5>
            <h5>Change</h5>
            <h5>Delete</h5>
          </div>
          <div className="border border-gray-200">
            {content_type &&
              content_type?.map((item, index) => (
                <div
                  className="grid grid-cols-1 gap-4 md:grid-cols-5 py-2.5 px-2.5 border-b border-b-100 bg-white hover:bg-gray-200 duration-300 hover:border-gray-300"
                  key={index + 1}
                >
                  <p className=" lg:text-black md:text-black text-[#1E40AF] lg:text-[14px] md:text-[14px] text-[17px] uppercase lg:text-center md:text-center text-left py-1 lg:border-b-0 md:border-b-0 border-b">
                    {item?.model}{" "}
                  </p>
                  <div className="grid grid-cols-2 md:grid-cols-1 place-items-center">
                    <p className="block md:hidden text-[16px]">View</p>
                    <input
                      type="checkbox"
                      value={item?.permissions?.view}
                      name={item?.permissions?.view}
                      checked={item ? item?.isChecked : false}
                      onChange={handleChange}
                      className="mx-auto cursor-pointer"
                    />
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-1 place-items-center">
                    <p className="block md:hidden text-[16px]">Add</p>
                    <input
                      type="checkbox"
                      value={item?.permissions?.add}
                      name={item?.permissions?.add}
                      checked={item ? item?.isChecked : false}
                      onChange={handleChange}
                      className="mx-auto cursor-pointer"
                    />
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-1 place-items-center">
                    <p className="block md:hidden text-[16px]">Change</p>
                    <input
                      type="checkbox"
                      value={item?.permissions?.change}
                      name={item?.permissions?.change}
                      checked={item ? item?.isChecked : false}
                      onChange={handleChange}
                      className="mx-auto cursor-pointer"
                    />
                  </div>
                  <div className="grid grid-cols-2  md:grid-cols-1 place-items-center">
                    <p className="block md:hidden text-[16px]">Delete</p>
                    <input
                      type="checkbox"
                      value={item?.permissions?.delete}
                      name={item?.permissions?.delete}
                      checked={item ? item?.isChecked : false}
                      onChange={handleChange}
                      className="mx-auto cursor-pointer"
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className="mt-5">
            <h1 className="text-[15px] mb-1 font-medium">Description</h1>
            <textarea
              required
              className="w-full h-24 px-4 py-2 rounded-md focus:outline-[0.5px] focus:outline-sky-500 border"
              type="text"
              name="description"
              id=""
              placeholder="Description"
              onChange={handleDescription}
            />
          </div>
          <button
            className="border border-[#1E40AF] rounded-md bg-[#1E40AF] hover:bg-transparent text-white hover:text-[#1E40AF] duration-300 px-10 py-2 mt-2 mb-4"
            type="submit"
          >
            SAVE
          </button>
        </form>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CreateTeamForm;
