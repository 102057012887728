import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import useHeaders from "../../Shared/Header/Header";
import { MdOutlineFileDownloadDone } from "react-icons/md";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import Select from "react-select";

const AddCommission = ({ setAddCommission, updated, setUpdated, pathType }) => {
  const headers = useHeaders();
  const [institutes, setInstitutes] = useState([]);
  const [cardType, setCardType] = React.useState();
  const [loanType, setLoanType] = React.useState();
  const [selectedInstitute, setSelectedInstitute] = React.useState();
  const [selectedCardType, setselectedCardType] = React.useState();
  const [commission, setCommission] = React.useState(0);
  const [contentType, setContentType] = React.useState(0);

  // Set SelectedInstitute ID
  const handleInstituteChange = (selectedOption) => {
    setSelectedInstitute(selectedOption);
  };
  const handleCardTypeChange = (selectedOption) => {
    setselectedCardType(selectedOption);
  };
  const handleLoanTypeChange = (selectedOption) => {
    setselectedCardType(selectedOption);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_MAIN_DOMAIN}/institute/list/`, headers)
      .then((res) => {
        setInstitutes(res?.data);
      });
  }, []);

  useEffect(() => {
    if (selectedInstitute) {
      // get Card Type Data
      if (pathType === "card_commission") {
        axios
          .get(
            `${process.env.REACT_APP_HOST_URL}/api/commission-not-exit-products/?institute_id=${selectedInstitute?.value}&content_type=${process.env.REACT_APP_CARD_CONTENT_TYPE}`,
            headers
          )
          .then((res) => {
            setCardType(res?.data);
            setContentType(132);
          });
      }
      if (pathType === "loan_commission") {
        // Get Loan Type Data
        axios
          .get(
            `${process.env.REACT_APP_HOST_URL}/api/commission-not-exit-products/?institute_id=${selectedInstitute?.value}&content_type=${process.env.REACT_APP_LOAN_CONTENT_TYPE}`,
            headers
          )
          .then((res) => {
            setLoanType(res?.data);
            setContentType(119);
          });
      }
    }
  }, [pathType, selectedInstitute]);

  // Submit Function Here
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      object_id: selectedCardType?.value,
      institute: selectedInstitute?.value,
      commission,
      content_type: contentType,
      product_type: parseInt(selectedCardType?.product_type) || 1,
    };
    // For Card Commission
    if (pathType === "card_commission") {
      if (data?.commission >= 100 && data?.commission <= 5000) {
        axios
          .post(
            `${process.env.REACT_APP_HOST_URL}/api/${pathType}/`,
            data,
            headers
          )
          .then((res) => {
            if (res) {
              setAddCommission(false);
              SuccessAlert("Added Successful", "success");
              setUpdated(updated + 1);
            }
          })
          .catch((error) => {
            SuccessAlert("Commision Already Exist!", "error");
          });
      } else {
        SuccessAlert("Commission Must Between 100 to 5000", "error");
      }
    }
    // For Loan Commission
    if (pathType === "loan_commission") {
      if (data?.commission >= 100 && data?.commission <= 1100) {
        axios
          .post(
            `${process.env.REACT_APP_HOST_URL}/api/${pathType}/`,
            data,
            headers
          )
          .then((res) => {
            if (res) {
              setAddCommission(false);
              SuccessAlert("Added Successful", "success");
              setUpdated(updated + 1);
            }
          })
          .catch((error) => {
            SuccessAlert("Commision Already Exist!", "error");
          });
      } else {
        SuccessAlert("Commission Must Between 100 to 1100", "error");
      }
    }
  };

  return (
    <div className="fixed z-10 overflow-y-auto top-0 w-full left-0">
      <div className="flex items-center justify-center min-height-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div className="inline-block align-center bg-white rounded-md text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full min-h-[50vh]">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-between items-center mb-5">
              <h2 className="text-2xl text-[#2563eb] mb-0">
                Add {pathType === "card_commission" ? "Card" : "Loan"}{" "}
                Commission
              </h2>
              <button
                onClick={() => setAddCommission(false)}
                type="button"
                className="h-8 w-8 text-white bg-red-500 hover:text-red-600 hover:bg-white border border-red-600 rounded-full flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
              >
                <FaTimes className="text-xl" />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              {/* Select Institute */}
              <div className="w-full mb-4">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1 mb-2"
                  htmlFor="institute"
                >
                  Institute Name
                </label>
                <Select
                  onChange={handleInstituteChange}
                  options={institutes?.map((institute) => ({
                    value: institute?.id,
                    label: institute?.name,
                  }))}
                />
              </div>

              {/* Select Card Type */}
              {pathType === "card_commission" && (
                <div className="w-full mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1 mb-2"
                    htmlFor="cardType"
                  >
                    Select Card Type
                  </label>
                  <Select
                    onChange={handleCardTypeChange}
                    options={cardType?.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                    }))}
                  />
                </div>
              )}

              {pathType === "loan_commission" && (
                <div className="w-full mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1 mb-2"
                    htmlFor="loanType"
                  >
                    Select Loan Type
                  </label>
                  <Select
                    onChange={handleLoanTypeChange}
                    options={loanType?.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                      product_type: item?.product_type_id,
                    }))}
                  />
                </div>
              )}
              {/* Select Commissione */}
              <div className="w-full">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2"
                  htmlFor="commission"
                >
                  Commission
                </label>
                <input
                  className="w-full my-2 border border-gray-300 rounded py-2.5 px-2 focus:bg-gray-50 focus:outline-[#2563eb]"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  maxLength="4"
                  name="commission"
                  placeholder="Enter Commission Amount"
                  onChange={(e) => setCommission(e.target.value)}
                  required
                />
              </div>
              <div className="py-5 float-right">
                <div className="flex items-center gap-x-4">
                  <button
                    type="submit"
                    className="py-2 px-3 text-white bg-[#2563eb] hover:text-[#2563eb] hover:bg-white border border-[#2563eb] rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                  >
                    <MdOutlineFileDownloadDone className="text-xl" />
                    <p> Submit </p>
                  </button>
                  <button
                    onClick={() => setAddCommission(false)}
                    type="reset"
                    className="py-2 px-4 text-white bg-red-500 hover:text-red-600 hover:bg-white border border-red-600 rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                  >
                    <FaTimes />
                    <p> Cancel</p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCommission;
