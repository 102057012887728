import React from "react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

const LogPagination = ({ allData }) => {
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);
/*   const [collection, setCollection] = React.useState(
    allData.slice(0, countPerPage)
  ); */

  const updatePage = (p) => {
    setCurrentPage(p);
    // const to = countPerPage * p;
    // const from = to - countPerPage;
    // setCollection(allData.slice(from, to));
  };
  return (
    <>
      {allData.length > 0 ? (
        <Pagination
          pageSize={countPerPage}
          onChange={updatePage}
          current={currentPage}
          total={allData.length}
        />
      ) : (
        <div>
          <h1 className=" text-2xl text-gray-400">There Is no data</h1>
        </div>
      )}
    </>
  );
};

export default LogPagination;
