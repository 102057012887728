import { useEffect, useState } from "react";
import useHeaders from "./../../Shared/Header/Header";
import axios from "axios";

const usePermission = () => {
  const headers = useHeaders();
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (headers?.headers?.Authorization !== "Token undefined") {
      axios
        .get(`${process.env.REACT_APP_HOST_URL}/api/permissions/`, headers)
        .then((res) => {
          setPermissions(res?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, []);

  const checkPermission = (codeName) => {
    const result = permissions?.permmissions?.find(
      (per) => per?.codename === codeName || permissions?.is_superuser === true
    );
    return result;
  };

  return { permissions, setPermissions, checkPermission, isLoading };
};

export default usePermission;


