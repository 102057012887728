import React from "react";
import { FaTimes } from "react-icons/fa";
import useInstitute from "../../Shared/Hooks/UseInstitute";
import { useEffect } from "react";
import axios from "axios";
import useHeaders from "../../Shared/Header/Header";
import { MdOutlineFileDownloadDone } from "react-icons/md";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import Select from "react-select";

const AddSpecialCommission = ({
  setAddCommission,
  updated,
  setUpdated,
  pathType,
}) => {
  const headers = useHeaders();
  const userInfo = JSON.parse(localStorage.getItem("aamartaka"));
  const { institutes } = useInstitute(
    pathType === "agent_card_commission"
      ? process.env.REACT_APP_CARD_CONTENT_TYPE
      : process.env.REACT_APP_LOAN_CONTENT_TYPE
  );
  const [cardType, setCardType] = React.useState();
  const [loanType, setLoanType] = React.useState();
  const [agentType, setAgentType] = React.useState();
  const [selectedInstitute, setSelectedInstitute] = React.useState();
  const [selectedCardType, setselectedCardType] = React.useState();
  const [selectedLoanType, setselectedLoanType] = React.useState();
  const [commission, setCommission] = React.useState(0);
  const [expire_date, setExpire_date] = React.useState("");
  const [contentType, setContentType] = React.useState(0);
  const [selectedAgent, setSelectedAgent] = React.useState(null);
  const [status, setStatus] = React.useState(null);

  // Set SelectedInstitute ID

  const handleInstituteChange = (selectedOption) => {
    setSelectedInstitute(selectedOption);
  };
  const handleCardTypeChange = (selectedOption) => {
    setselectedCardType(selectedOption);
  };
  const handleLoanTypeChange = (selectedOption) => {
    setselectedLoanType(selectedOption);
  };
  const handleAgentTypeChange = (selectedOption) => {
    setSelectedAgent(selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  // Get Login User Data

  useEffect(() => {
    if (selectedAgent?.value && selectedInstitute?.value) {
      // get Card Type Data
      if (pathType === "agent_card_commission") {
        axios
          .get(
            `${process.env.REACT_APP_HOST_URL}/api/agent-commission-not-exit-products/?user=${selectedAgent?.value}&institute_id=${selectedInstitute?.value}&content_type=${process.env.REACT_APP_CARD_CONTENT_TYPE}`,
            headers
          )
          .then((res) => {
            setCardType(res?.data);
            setContentType(132);
          });
      }
      if (pathType === "agent_loan_commission") {
        // Get Loan Type Data
        axios
          .get(
            `${process.env.REACT_APP_HOST_URL}/api/agent-commission-not-exit-products/?user=${selectedAgent?.value}&institute_id=${selectedInstitute?.value}&content_type=${process.env.REACT_APP_LOAN_CONTENT_TYPE}`,
            headers
          )
          .then((res) => {
            setLoanType(res?.data);
            setContentType(119);
          });
      }
    }
  }, [pathType, selectedInstitute, selectedAgent?.value]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_HOST_URL}/api/agent/register?assign_member=${userInfo?.user_data?.user}`,
        headers
      )
      .then((resp) => {
        setAgentType(resp?.data?.results);
      });
  }, [userInfo?.user_data?.user]);

  // Submit Function Here

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      object_id:
        pathType === "agent_card_commission"
          ? selectedCardType?.value
          : selectedLoanType?.value,
      institute: selectedInstitute?.value,
      agent: selectedAgent?.value,
      commission: parseInt(commission),
      expire_date,
      content_type: contentType,
      product_type: 1,
      status: status?.value,
    };

    // For Card Commission
    if (pathType === "agent_card_commission") {
      if (data?.commission >= 100 && data?.commission <= 5000) {
        axios
          .post(
            `${process.env.REACT_APP_HOST_URL}/api/${pathType}/`,
            data,
            headers
          )
          .then((res) => {
            if (res) {
              setAddCommission(false);
              SuccessAlert("Added Successful", "success");
              setUpdated(updated + 1);
            }
          })
          .catch((error) => {
            SuccessAlert("Commision Already Exist!", "error");
          });
      } else {
        SuccessAlert("Commission Must Between 100 to 5000", "error");
      }
    }

    // For Loan Commission
    if (pathType === "agent_loan_commission") {
      if (data?.commission >= 100 && data?.commission <= 1500) {
        axios
          .post(
            `${process.env.REACT_APP_HOST_URL}/api/${pathType}/`,
            data,
            headers
          )
          .then((res) => {
            if (res) {
              setAddCommission(false);
              SuccessAlert("Added Successful", "success");
              setUpdated(updated + 1);
            }
          })
          .catch((error) => {
            SuccessAlert("Commision Already Exist!", "error");
          });
      } else {
        SuccessAlert("Commission Must Between 100 to 1100", "error");
      }
    }
  };

  const statusData = [
    { value: 1, label: "Active" },
    { value: 2, label: "Expire" },
  ];

  return (
    <div className="fixed z-10 overflow-y-auto top-0 w-full left-0">
      <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div className="inline-block align-center bg-white rounded-md text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full pb-3 min-h-[55vh]">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl text-[#2563eb] mb-0">
                Add Special{" "}
                {pathType === "agent_card_commission" ? " Card " : " Loan "}
                Commission
              </h2>
              <button
                onClick={() => setAddCommission(false)}
                type="button"
                className="h-8 w-8 text-white bg-red-500 hover:text-red-600 hover:bg-white border border-red-600 rounded-full flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
              >
                <FaTimes className="text-xl" />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex justify-between items-center gap-x-4">
                <div className="w-full mb-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1 mb-2"
                    htmlFor="institute"
                  >
                    Select Institute Name
                  </label>
                  <Select
                    onChange={handleInstituteChange}
                    placeholder="Select Institute Name"
                    options={institutes?.map((institute) => ({
                      value: institute?.id,
                      label: institute?.name,
                    }))}
                  />
                </div>
                <div className="w-full mb-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1 mb-2"
                    htmlFor="commission"
                  >
                    Search & Select Agent Name
                  </label>
                  <Select
                    value={selectedAgent}
                    onChange={handleAgentTypeChange}
                    placeholder="Search Agent By Number"
                    options={agentType?.map((item) => ({
                      value: item?.user,
                      label: `${item?.first_name} (${item?.username})`,
                    }))}
                  />
                </div>
              </div>
              <div className="flex justify-between items-center gap-x-4">
                {pathType === "agent_card_commission" && (
                  <div className="w-full mb-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1 mb-2"
                      htmlFor="cardType"
                    >
                      Select Card Type
                    </label>
                    <Select
                      onChange={handleCardTypeChange}
                      placeholder="Select Card Type"
                      options={cardType?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      }))}
                    />
                  </div>
                )}

                {pathType === "agent_loan_commission" && (
                  <div className="w-full mb-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1 mb-2"
                      htmlFor="loanType"
                    >
                      Select Loan Type
                    </label>
                    <Select
                      onChange={handleLoanTypeChange}
                      placeholder="Select Loan Type"
                      options={loanType?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      }))}
                    />
                  </div>
                )}
                <div className="w-full mb-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1 mb-2"
                    htmlFor="commission"
                  >
                    Select Commission
                  </label>
                  <input
                    type="number"
                    className="w-full border border-gray-300 rounded py-2 px-2 focus:bg-gray-50 focus:outline-[#2563eb]"
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                    }}
                    maxLength="4"
                    name="commission"
                    placeholder="Enter Commission Amount"
                    onChange={(e) => setCommission(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-between items-center gap-x-4">
                <div className="w-full mb-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2"
                    htmlFor="expire_date"
                  >
                    Expire Date
                  </label>
                  <input
                    type="date"
                    className="w-full my-2 border border-gray-300 rounded py-2 px-2 focus:bg-gray-50 focus:outline-[#2563eb]"
                    name="commission"
                    placeholder="Enter Expire Date"
                    onChange={(e) => setExpire_date(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full mb-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1 mb-2"
                    htmlFor="cardType"
                  >
                    Select Status
                  </label>
                  <Select
                    onChange={handleStatusChange}
                    placeholder="Select Status"
                    options={statusData?.map((item) => ({
                      value: item?.value,
                      label: item?.label,
                    }))}
                  />
                </div>
              </div>

              <div className="py-5 float-right">
                <div className="flex items-center gap-x-4">
                  <button
                    type="submit"
                    className="py-2 px-3 text-white bg-[#2563eb] hover:text-[#2563eb] hover:bg-white border border-[#2563eb] rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                  >
                    <MdOutlineFileDownloadDone className="text-xl" />
                    <p> Submit</p>
                  </button>
                  <button
                    onClick={() => setAddCommission(false)}
                    type="reset"
                    className="py-2 px-4 text-white bg-red-500 hover:text-red-600 hover:bg-white border border-red-600 rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                  >
                    <FaTimes />
                    <p> Cancel</p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSpecialCommission;
