import React from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loader from "./../../Shared/Loader/Loader";
import usePermission from "./../../lib/Hook/UsePermission";

const VrmListTable = ({ deleteAlert, users, handleSearch, isLoading }) => {
  const { checkPermission } = usePermission();
  return (
    <div className="flex flex-col mt-4">
      <div className="py-2 overflow-x-auto">
        <div className="relative lg:w-[45%] md:w-[60%] w-full mb-3">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:outline-blue-500 focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            onChange={handleSearch}
            type="text"
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
            }}
            maxLength="11"
            placeholder="Search by usename"
          />
        </div>
        <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-md">
          {users?.length > 0 ? (
            <table className="min-w-full overflow-scroll">
              <thead className=" bg-gray-700 text-white">
                <tr className="text-xs font-medium leading-4 tracking-wider text-center uppercase">
                  <th className="py-3 border-b border-gray-200">User Code</th>
                  <th className="py-3 border-b border-gray-200">User Name</th>
                  <th className="py-3 border-b border-gray-200">Name</th>
                  <th className="py-3 border-b border-gray-200">
                    Assigned User
                  </th>
                  <th className="py-3 border-b border-gray-200">Status</th>
                  <th className="py-3 border-b border-gray-200">Joing Date</th>
                  {/* <th className="py-3 border-b border-gray-200">
                    Last Active date
                  </th> */}
                  <th className="py-3 border-b border-gray-200">Total Lead</th>
                  <th className="py-3 border-b border-gray-200">Action</th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {users &&
                  users?.map((item, index) => (
                    <tr
                      className="text-center border hover:bg-gray-100 duration-300"
                      key={index + 1}
                    >
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.code || "N/A"}
                        </p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.username || "N/A"}
                        </p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.first_name + " " + item?.last_name}
                        </p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.assign_member_name}
                        </p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <div className="ml-4">
                          <p className="text-sm font-medium leading-5 text-gray-900">
                            {item?.status_name || "N/A"}
                          </p>
                        </div>
                      </td>

                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.created_at || "N/A"}
                        </p>
                      </td>

                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {" "}
                          {item?.total_lead || "N/A"}
                        </p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <div className="flex justify-center items-center">
                          {checkPermission("view_agentprofile") && (
                            <Link to={`/vrmaccount/${item?.id}`}>
                              <FaEye className="text-xl text-blue-600 hover:text-black duration-300" />
                            </Link>
                          )}
                          {checkPermission("change_agentprofile") && (
                            <Link to={`/vrmlist/${item?.id}`} className="mx-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-blue-500 hover:text-blue-700 duration-300"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                />
                              </svg>
                            </Link>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <>
              {isLoading ? (
                <Loader />
              ) : (
                <div className="flex justify-center items-center">
                  <h1 className=" text-2xl text-gray-300 my-4">
                    No Data Found
                  </h1>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VrmListTable;
