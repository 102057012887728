import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import HeadingTitle from "../../../Shared/HeadingTitle/HeadingTitle";
import useHeaders from "../../../Shared/Header/Header";
import CreatableSelect from "react-select/creatable";
import { SuccessAlert } from "../../../Shared/Alert/SuccessAlert";

const CreateLoanLead = () => {
  const navigate = useNavigate();
  const headers = useHeaders();
  const { id } = useParams();
  const cookies = localStorage.getItem("aamartaka");
  const { user_data } = JSON.parse(cookies);
  const contentType = process.env.REACT_APP_LOAN_CONTENT_TYPE;
  const [loanTypeOptions, setLoanTypeOptions] = useState([]);
  const [companyNameOptions, setcompanyNameOptions] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [vrmAgentData, setVrmAgentData] = useState([]);
  const [vRMUser, setVRMUser] = useState([]);
  const [profession, setProfession] = useState("");
  const [salaryType, setSalaryType] = useState("");
  const [selectedInstitute, setSelectedInstitute] = useState("");
  const [objectId, setObjectId] = React.useState();
  const [selectedCompany, setSelectedCompany] = React.useState();
  const [selectedCompanyType, setSelectedCompanyType] = useState(null);
  const [status, setStatus] = useState("");
  const [vrm, setVRM] = useState("");
  const [remarks, setRemarks] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedOptionData, setSelectedOptionData] = useState({
    name: "",
    mobile_no: "",
    yearly_transaction: 0,
    rental_income: 0,
    remarks: "",
  });

  // All Event Handler
  const handleChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newData = { ...selectedOptionData };
    newData[field] = value;
    setSelectedOptionData(newData);
  };

  const handleInstituteChange = (selectedInstitute) => {
    setSelectedInstitute(selectedInstitute);
  };

  const handleCardTypeChange = (selectedOption) => {
    setObjectId(selectedOption);
  };

  const handleCompanyChange = (selectedOption) => {
    setSelectedCompany(selectedOption);
    if (selectedOption && selectedOption.type) {
      setSelectedCompanyType({
        value: selectedOption.type,
        label: selectedOption.type,
      });
    } else {
      setSelectedCompanyType(null);
    }
  };

  // All Useeffect

  useEffect(() => {
    // Get All Institutes
    axios
      .get(
        `${process.env.REACT_APP_HOST_URL}/api/commission-wise-unique-institutes/?content_type=${contentType}`,
        headers
      )
      .then((res) => {
        setInstitutes(res?.data);
      });

    // Get All Company Data
    axios
      .get(`${process.env.REACT_APP_ADMIN_URL}/company`, headers)
      .then((resp) => {
        setcompanyNameOptions(resp?.data?.results);
      });
  }, []);

  // Get Company Data By Search
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ADMIN_URL}/company/?search=${searchText}`,
          headers
        );
        setcompanyNameOptions(response?.data?.results);
      } catch (error) {}
    };
    fetchData();
  }, [searchText]);

  const handleInputChange = (newValue) => {
    setSearchText(newValue);
  };

  // Get Card Type Options
  useEffect(() => {
    if (selectedInstitute?.value) {
      axios
        .get(
          `${process.env.REACT_APP_HOST_URL}/api/commission-wise-unique-product-institutes/?institute_id=${selectedInstitute?.value}&content_type=${contentType}`,
          headers
        )
        .then((res) => {
          setLoanTypeOptions(res?.data);
        });
    }
  }, [selectedInstitute?.value]);

  useEffect(() => {
    // Get VRM All Agent Data
    axios
      .get(
        `${process.env.REACT_APP_HOST_URL}/api/agent/register/?assign_member=${user_data?.user}`,
        headers
      )
      .then((response) => setVrmAgentData(response?.data?.results))
      .catch((error) => SuccessAlert(error?.message, "error"));

    // Get Single VRM Agent
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_HOST_URL}/api/agent/register/${id}`,
          headers
        )
        .then((response) => setVRMUser(response?.data))
        .catch((error) => SuccessAlert(error?.message, "error"));
    }
  }, [id]);

  // Get All Profession Data
  const professionData = [
    { value: "salaried", label: "Salaried" },
    { value: "business", label: "Business" },
    { value: "landloard", label: "Landloard" },
    { value: "professional", label: "Professional" },
  ];

  // Get Salary Type Data
  const salaryTypeData = [
    { value: "bank salary", label: "Bank Salary" },
    { value: "hand cash", label: "Hand Cash" },
    { value: "both", label: "Hand Cash + Bank Salary" },
  ];

  // Get Status Data
  const statusData = [
    { label: "New", value: "New" },
    { label: "CNI", value: "CNI" },
    { label: "Non Eligible", value: "Non Eligible" },
    { label: "Follow", value: "Follow" },
  ];

  // Submit The Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ...selectedOptionData,
      institute: selectedInstitute?.value,
      profession: profession?.value,
      salary_type: salaryType?.value || "",
      object_id: objectId?.value,
      company: selectedCompany?.value,
      company_type: selectedCompany?.type,
      status: status?.value,
      user: vrm?.value,
      content_type: contentType,
      remarks: remarks,
      product: objectId?.label,
    };
    if (selectedOptionData?.mobile_no?.length === 11) {
      axios
        .post(`${process.env.REACT_APP_HOST_URL}/api/loan_lead/`, data, headers)
        .then((resp) => {
          SuccessAlert("Lead Create Successful", "success");
          navigate("/loanLead");
        })
        .catch((error) => {
          SuccessAlert(error?.message, "error");
        });
    } else {
      SuccessAlert("Invalid Phone Number", "error");
    }
  };

  return (
    <div className="h-screen mx-4">
      <div className="flex items-center gap-x-2 mt-2 mb-5">
        <HeadingTitle title="New Loan Lead" />
        {vRMUser?.id && (
          <h1 className="text-2xl text-[#2563eb]">
            / {vRMUser?.first_name + " " + vRMUser?.last_name}
          </h1>
        )}
      </div>
      <div className="lg:w-4/5 md:w-4/5 w-full mx-auto bg-white p-6 rounded-md shadow-md">
        <h4 className="text-[#1E40AF] font-semibold text-lg mb-4">
          Create Loan Lead
        </h4>
        <form onSubmit={handleSubmit}>
          <div className="lg:grid block grid-cols-3 place-items-center place-content-between w-full gap-x-4">
            <div className="w-full">
              <label>Institute Name</label>
              <Select
                isSearchable={true}
                requred
                defaultValue={institutes}
                onChange={handleInstituteChange}
                name="interested_bank"
                options={institutes?.map((institute) => ({
                  value: institute?.id,
                  label: institute?.name,
                }))}
                placeholder="Select Institute Name"
                classNamePrefix="select"
                className="mt-1 mb-2.5"
              />
            </div>
            <div className="w-full">
              <label> Loan Type </label>
              <Select
                isSearchable={true}
                required
                defaultValue={objectId}
                onChange={handleCardTypeChange}
                name="interested_product"
                options={loanTypeOptions?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
                placeholder="Select Loan Type"
                classNamePrefix="select"
                className="mt-1 mb-2.5"
              />
            </div>

            <div className="w-full">
              <label>Name</label>
              <input
                required
                type="text"
                name="name"
                onChange={handleChange}
                placeholder="Your Name"
                className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
              />
            </div>
            <div className="w-full">
              <label> Phone</label>
              <input
                required
                type="text"
                name="mobile_no"
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                }}
                maxLength="11"
                onChange={handleChange}
                placeholder="Phone Number"
                className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
              />
            </div>
            <div className="w-full">
              <label> Profession</label>
              <Select
                isSearchable={false}
                required
                name="profession"
                onChange={setProfession}
                options={professionData}
                placeholder="Select Profession"
                className="mt-1 mb-2.5"
              />
            </div>
            {profession?.value === "salaried" && (
              <div className="w-full">
                <label> Company Name</label>
                <CreatableSelect
                  isSearchable={true}
                  required
                  name="company_name"
                  options={companyNameOptions?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                    type: item?.company_type_name,
                  }))}
                  placeholder="Select Company Name"
                  onChange={handleCompanyChange}
                  onInputChange={handleInputChange}
                  className="mt-1 mb-2.5"
                />
              </div>
            )}
            {profession?.value === "business" && (
              <div className="w-full">
                <label>Create or Select Company Name</label>
                <CreatableSelect
                  isSearchable={true}
                  required
                  name="company_name"
                  options={companyNameOptions?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                    type: item?.company_type_name,
                  }))}
                  placeholder="Select Company Name"
                  onChange={handleCompanyChange}
                  onInputChange={handleInputChange}
                  className="mt-1 mb-2.5"
                />
              </div>
            )}
            {profession?.value === "business" && selectedCompany && (
              <div className="w-full">
                <label> Company Type</label>
                <Select
                  isSearchable={true}
                  isDisabled={selectedCompany}
                  value={selectedCompanyType}
                  required
                  name="companyType"
                  placeholder="Select Company Type"
                  className="mt-1 mb-2.5"
                />
              </div>
            )}
            {profession?.value !== "business" && selectedCompany && (
              <div className="w-full">
                <label> Company Type</label>
                <Select
                  isSearchable={true}
                  isDisabled={selectedCompany}
                  value={selectedCompanyType}
                  required
                  name="companyType"
                  placeholder="Select Company Type"
                  className="mt-1 mb-2.5"
                />
              </div>
            )}
            {profession?.value === "salaried" &&  (
              <div className=" w-full">
                <label> Salary Type</label>
                <Select
                  required
                  name="salary_type"
                  onChange={setSalaryType}
                  options={salaryTypeData}
                  placeholder="Select Salary Type"
                  className="mt-1 mb-2.5"
                />
              </div>
            )}
            {(profession?.value === "professional" ||
              profession?.value === "business") && (
              <div className=" w-full">
                <label>Yearly Transaction</label>
                <input
                  required
                  type="number"
                  name="yearly_transaction"
                  onChange={handleChange}
                  maxLength={8}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  placeholder="ex: 45000"
                  className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                />
              </div>
            )}
            {profession?.value === "landloard" && (
              <div className=" w-full">
                <label>Rental Income</label>
                <input
                  required
                  type="number"
                  name="rental_income"
                  onChange={handleChange}
                  maxLength={7}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  placeholder="ex: 45000"
                  className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                />
              </div>
            )}
            {salaryType?.value === "bank salary" && profession?.value === "salaried" &&(
              <div className="w-full">
                <label>Bank Salary Amount</label>
                <input
                  required
                  type="number"
                  name="salary_ac_amount"
                  onChange={handleChange}
                  maxLength={6}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  placeholder="ex: 45000"
                  className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                />
              </div>
            )}
            {salaryType?.value === "hand cash" && profession?.value === "salaried" && (
              <div className=" w-full">
                <label>Hand Cash Amount</label>
                <input
                  required
                  type="number"
                  name="hand_cash_amount"
                  onChange={handleChange}
                  maxLength={6}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  placeholder="ex: 45000"
                  className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                />
              </div>
            )}
            {salaryType?.value === "both" && profession?.value === "salaried" &&(
              <div className=" w-full">
                <label>Hand Cash Amount</label>
                <input
                  required
                  type="number"
                  name="hand_cash_amount"
                  onChange={handleChange}
                  maxLength={6}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  placeholder="ex: 45000"
                  className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                />
              </div>
            )}
            {salaryType?.value === "both" && profession?.value === "salaried" && (
              <div className=" w-full">
                <label>Bank Salary Amount</label>
                <input
                  required
                  type="number"
                  name="salary_ac_amount"
                  onChange={handleChange}
                  maxLength={6}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  placeholder="ex: 45000"
                  className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                />
              </div>
            )}

            <div className="w-full">
              <label> Status</label>
              <Select
                defaultValue={"in process"}
                required
                name="status"
                onChange={setStatus}
                options={statusData}
                placeholder="Select Status"
                className="mt-1 mb-2.5"
              />
            </div>
            <div className=" w-full">
              <label>VRM Agent</label>
              <Select
                required
                name="vrmagent"
                onChange={setVRM}
                options={vrmAgentData?.map((item) => ({
                  label: item?.first_name,
                  value: item?.user,
                }))}
                placeholder="Select VRM Agent"
                className="mt-1 mb-2.5"
              />
            </div>

            <div className="w-full">
              <label>Remarks</label>
              <input
                required
                type="text"
                name="remarks"
                onChange={(e) => setRemarks(e.target.value)}
                placeholder="Your Remarks"
                className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
              />
            </div>
          </div>

          <button
            className="w-32 mt-4 mx-auto text-white bg-[#1E40AF] border border-[#1E40AF] hover:bg-transparent hover:text-[#1E40AF] text-md px-5 py-2 rounded-md font-exo duration-300"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateLoanLead;
