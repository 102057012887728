import React from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";

const TogleSidebar = () => {
  const sidebar = useSelector((state) => state.userSlice.sidebar);

  return (
    <aside
      style={{ zIndex: 60 }}
      className={`show-sidebar mt-2.5 h-screen  ${sidebar ? "flex" : "hidden"}`}
    >
      <Sidebar />
    </aside>
  );
};

export default TogleSidebar;
