import React, { useEffect, useState } from "react";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import useHeaders from "../../Shared/Header/Header";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import Loader from "../../Shared/Loader/Loader";

const VRMListEdit = () => {
  const router = useNavigate();
  const { id } = useParams();
  const headers = useHeaders();
  const [user, setUser] = useState();

  // Get User Info
  useEffect(() => {
    const loadData = async () => {
      await fetch(
        `${process.env.REACT_APP_HOST_URL}/api/agent/register/${id}/`,
        headers
      )
        .then((response) => response.json())
        .then((res) => {
          setUser(res);
        });
    };
    loadData();
  }, [id]);

  // Update User Information
  const handleSubmit = async (event) => {
    event.preventDefault();
    const updateData = {
      id: 1,
      user: {
        id: user?.user,
        // "username": user?.username,
        first_name: user?.first_name,
        email: user?.email,
      },
      phone: user?.phone,
    };
    if (user?.phone?.length === 11) {
      await axios
        .patch(
          `${process.env.REACT_APP_HOST_URL}/api/vrmagentprofile/${id}/update/`,
          updateData,
          headers
        )
        .then((response) => {
          SuccessAlert("User Updated", "success");
          router(-1);
        })
        .catch((error) => {
          SuccessAlert(error.message, "error");
        });
    } else {
      SuccessAlert("Invalid Phone Number", "error");
    }
  };

  const handleBlur = (e) => {
    const newData = { ...user };
    newData[e.target.name] = e.target.value;
    setUser(newData);
  };

  return (
    <div className="h-screen mx-4 mt-4">
      <HeadingTitle title="Edit VRM List" />
      <div className="border bg-white rounded-md px-5 pt-3 pb-6 mt-4">
        {id > 0 ? (
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-4">
              <div className="w-full my-1.5">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1.5"
                  htmlFor="grid-last-name"
                >
                  Name
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border border-[#B3B3B3] rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-sky-500 focus:border-0"
                  id="grid-last-name"
                  type="text"
                  name="first_name"
                  placeholder="Name"
                  required
                  defaultValue={user?.first_name}
                  onBlur={handleBlur}
                />
              </div>
              <div className="w-full my-1.5">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1.5"
                  htmlFor="grid-last-name"
                >
                  Email
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border border-[#B3B3B3] rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 focus:outline-sky-500 focus:border-0"
                  id="grid-last-name"
                  type="email"
                  name="email"
                  placeholder="email"
                  required
                  defaultValue={user?.email}
                  onBlur={handleBlur}
                />
              </div>
              <div className="w-full my-1.5">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1.5"
                  htmlFor="grid-first-name"
                >
                  Phone
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border border-[#B3B3B3] rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:outline-sky-500 focus:border-0"
                  id="grid-first-name"
                  type="number"
                  name="phone"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  maxLength="11"
                  required
                  defaultValue={user?.phone}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <button
              className="bg-[#1E40AF] border border-[#1E40AF] hover:bg-transparent hover:text-[#1E40AF] duration-300 py-2 px-6 float-left rounded-md text-white mt-10"
              type="submit"
            >
              Submit
            </button>
          </form>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default VRMListEdit;
