import React, { useEffect, useState } from "react";

const Dashboard = () => {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const userdata = await localStorage.getItem("aamartaka");
      setUser(userdata);
    };

    loadData();
  }, [user]);

  return (
    <div className=" h-[87vh] p-3 my-3">
      <h1 className="text-2xl text-center">Welcome to <span className="text-[#2563eb]"> AamarTaka </span> Admin Dashboard</h1>
    </div>
  );
};

export default Dashboard;
