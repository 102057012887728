import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeadingTitle from "./../../Shared/HeadingTitle/HeadingTitle";
import Loader from "../../Shared/Loader/Loader";

const SubmittedLeadView = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const userData = localStorage.getItem("aamartaka");
  const { user_data, token } = JSON.parse(userData);

  useEffect(() => {
    const loadLead = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/lead/${id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (result?.status === 200) {
        const leads = result?.data;

        setData(leads);
      }
    };

    loadLead();
    // loadData();
  }, [id]);

  return (
    <div className="h-screen p-3 m-3">
      <HeadingTitle title="View Submitted Lead" />
      <div className="flex flex-col mt-8">
        {id > 0 ? (
          <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-md">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                      #
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                      Application Type
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                      Bank
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                      Product Name
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                      Bank Status
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                      Created At
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                      Profession Type
                    </th>

                    {/* <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Follow Up
                </th> */}
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                      Assign Member:
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  <tr>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 w-3">
                      <p className="text-sm leading-5 text-gray-500">{1}</p>
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      <div className="flex submittedLeads-center">
                        <div className="ml-4">
                          {data?.interested_product ? (
                            <p className="text-sm font-medium leading-5 text-gray-900">
                              {data?.interested_product}
                            </p>
                          ) : (
                            <p className="text-sm font-medium leading-5 text-gray-900">
                              N/A
                            </p>
                          )}
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {data?.interested_bank ? (
                        <p className="text-sm leading-5 text-gray-500">
                          {data?.interested_bank}
                        </p>
                      ) : (
                        <p className="text-sm leading-5 text-gray-500">N/A</p>
                      )}
                    </td>

                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      <p className="text-sm leading-5 text-gray-500">
                        {data?.card_type || data?.loan_type}
                      </p>
                    </td>

                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {data?.bank_status ? (
                        <p className="text-sm leading-5 text-gray-500">
                          {data?.bank_status}
                        </p>
                      ) : (
                        <p className="text-sm leading-5 text-gray-500">N/A</p>
                      )}
                    </td>

                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {data?.created_at ? (
                        <p className="text-sm leading-5 text-gray-500">
                          {data?.created_at}
                        </p>
                      ) : (
                        <p className="text-sm leading-5 text-gray-500">N/A</p>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {data?.profession ? (
                        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                          {data?.profession}
                        </span>
                      ) : (
                        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                          N/A
                        </span>
                      )}
                    </td>
                    {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <div className="text-sm leading-5 text-gray-500">
                    {submittedLead?.application_followup?.user}
                  </div>
                </td> */}

                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {user_data?.first_name ? (
                        <p className="text-sm leading-5 text-gray-500">
                          {user_data?.first_name}
                        </p>
                      ) : (
                        <p className="text-sm leading-5 text-gray-500">N/A</p>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default SubmittedLeadView;
