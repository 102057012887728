import React, { useState } from "react";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import Loader from "../../Shared/Loader/Loader";
import PaymentModal from "./PaymentModal/PaymentModal";

const PaymentHistoryTable = ({ name, data, isLoading }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="flex flex-col">
      {data?.length > 0 ? (
        <>
          <div className="flex items-center mb-3">
            <HeadingTitle title={`Payment History of`} />
            <h1 className="mt-0 ml-2 text-xl text-sky-500 font-semibold">
              {name?.agent_name}
            </h1>
          </div>
          <div className="py-3 overflow-x-auto">
            <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-md">
              <table className="min-w-full overflow-scroll">
                <thead className=" bg-gray-700 text-white">
                  <tr className="text-xs font-medium leading-4 tracking-wider text-center uppercase">
                    <th className="py-3 border-b border-gray-200 pl-2">#</th>
                    <th className="py-3 border-b border-gray-200">
                      Requested Amount
                    </th>
                    <th className="py-3 border-b border-gray-200">
                      Requested Date
                    </th>

                    <th className="py-3 border-b border-gray-200">
                      Payment Disbursed Date
                    </th>
                    <th className="py-3 border-b border-gray-200">
                      Total Disbursed Time
                    </th>
                    <th className="py-3 border-b border-gray-200">
                      Bank / Method Name
                    </th>
                    <th className="py-3 border-b border-gray-200">Status</th>
                    {/* <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Action
                </th> */}
                  </tr>
                </thead>

                <tbody className="bg-white">
                  {data &&
                    data?.map((item, index) => (
                      <tr
                        className="text-center border hover:bg-gray-100 duration-300"
                        key={index + 1}
                      >
                        <td className="py-3 whitespace-no-wrap border-b border-gray-200 pl-2">
                          <p className="text-sm leading-5 text-gray-500">
                            {index + 1}
                          </p>
                        </td>

                        <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                          <p className="text-sm leading-5 text-gray-500">
                            {item?.amount}
                          </p>
                        </td>
                        <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                          <p className="text-sm leading-5 text-gray-500">
                            {item?.created_at}
                          </p>
                        </td>

                        <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                          <p className="text-sm leading-5 text-gray-500">
                            {item?.payment_disbursed_date ? (
                              <span> {item?.payment_disbursed_date}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                        </td>
                        <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                          <p className="text-sm leading-5 text-gray-500">
                            {item?.total_hr || 0} Working Days
                          </p>
                        </td>
                        <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                          <p className="text-sm leading-5 text-gray-500">
                            {item?.request_method?.payment_method || "N/A"}
                          </p>
                        </td>

                        <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                          {item?.status === "Confirm" && (
                            <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-200 rounded-full">
                              {item?.status}
                            </span>
                          )}
                          {item?.status === "Pending" && (
                            <span className="inline-flex px-2 text-xs font-semibold leading-5 text-orange-800 bg-orange-200 rounded-full">
                              {item?.status}
                            </span>
                          )}
                        </td>
                        {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      <div className="text-sm leading-5 text-gray-500">
                        <button
                          onClick={() => {
                            setShowModal(true);
                          }}
                          className=" rounded-md px-3 py-1 text-white bg-green-400"
                        >
                          Payment Confirmation
                        </button>
                      </div>
                    </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex justify-center items-center">
              <h1 className=" text-2xl text-gray-300 my-4">No Data Found</h1>
            </div>
          )}
        </div>
      )}
      <PaymentModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default PaymentHistoryTable;
