import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../Shared/Loader/Loader";
import usePermission from "./../../lib/Hook/UsePermission";

const LeadListTable = ({ isloading, data, path }) => {
  const { checkPermission } = usePermission();
  return (
    <div className="flex flex-col mt-2 min-h-screen pb-10 overflow-x-auto">
      <div class="w-full border bg-white">
        <div class="rounded bg-white">
          {data?.length > 0 ? (
            <div className="overflow-x-auto">
              <table class="w-full leading-normal table-auto">
                <thead class="text-xs font-semibold border-gray tracking-wider text-left px-5 py-3 bg-gray-700 text-white hover:cursor-pointer uppercase border-b-2 border-gray-200">
                  <tr class="border-b border-gray">
                    <th
                      scope="col"
                      class="border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 text-center text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      class="border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 text-start text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 text-start text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Company Name
                    </th>
                    <th
                      scope="col"
                      class="border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 text-start text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Mobile
                    </th>
                    <th
                      scope="col"
                      class="border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 text-start text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Income
                    </th>
                    <th
                      scope="col"
                      class="border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 text-start text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Bank
                    </th>
                    <th
                      scope="col"
                      class="border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 text-start text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      class="border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 text-start text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      AMR Status
                    </th>
                    <th
                      scope="col"
                      class="border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 text-start text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Bank Status
                    </th>
                    <th
                      scope="col"
                      class="border-gray border-b-2 border-t-2 border-gray-200 py-3 px-3 text-start text-xs font-semibold uppercase tracking-wider whitespace-nowrap"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr class="hover:bg-gray-100 hover:cursor-pointer">
                      <td class="py-3 px-3 border-b border-gray-200 text-gray-900 text-sm text-start whitespace-nowrap">
                        {index + 1}
                      </td>
                      <td class="py-3 px-3 border-b border-gray-200 text-gray-900 text-sm text-start whitespace-nowrap">
                        {item?.name || "N/A"}
                      </td>
                      <td class="py-3 px-3 border-b border-gray-200 text-gray-900 text-sm text-start whitespace-nowrap">
                        {item?.company_name || "N/A"}
                      </td>
                      <td class="py-3 px-3 border-b border-gray-200 text-gray-900 text-sm text-start whitespace-nowrap">
                        {item?.mobile_no || "N/A"}
                      </td>
                      <td class="py-3 px-3 border-b border-gray-200 text-gray-900 text-sm text-start whitespace-nowrap">
                        {item?.profession === "business" && (
                          <div className="inline-flex flex-col px-2 items-center justify-center">
                            <p className="text-sm font-semibold">
                              {item?.yearly_transaction?.toLocaleString(
                                "en-US"
                              )}
                            </p>
                            <p className="text-xs">Yearly Trx</p>
                          </div>
                        )}

                        {item?.profession === "landloard" && (
                          <span className="inline-flex px-2 text-sm font-semibold text-start">
                            {item?.rental_income?.toLocaleString("en-US")}
                          </span>
                        )}
                        {item?.profession === "professional" && (
                          <span className="inline-flex px-2 text-sm font-semibold text-start">
                            {item?.rental_income?.toLocaleString("en-US")}
                          </span>
                        )}

                        {item?.profession === "salaried" && (
                          <span className="inline-flex px-2 text-sm font-semibold text-start">
                            {item?.salary_ac_amount?.toLocaleString("en-US")}
                          </span>
                        )}

                        {item?.profession === "salaried" &&
                          item?.salary_ac_amount <= 0 && (
                            <div className="px-2">
                              <p className="text-sm font-semibold text-start">
                                {item?.hand_cash_amount?.toLocaleString(
                                  "en-US"
                                )}
                              </p>
                              <p className="text-xs text-start">Hand Cash</p>
                            </div>
                          )}
                      </td>
                      <td class="py-3 px-3 border-b border-gray-200 text-gray-900 text-sm whitespace-nowrap">
                        {item?.institute_name}
                      </td>
                      <td class="py-3 px-3 border-b border-gray-200 text-gray-900 text-sm whitespace-nowrap">
                        {item?.product || "N/A"}
                      </td>
                      <td class="py-3 px-3 border-b border-gray-200 text-gray-900 text-sm whitespace-nowrap">
                        {item?.status || "N/A"}
                      </td>
                      <td class="py-3 px-3 border-b border-gray-200 text-gray-900 text-sm whitespace-nowrap">
                        {item?.bank_status || "N/A"}
                      </td>
                      <td class="py-3 px-3 border-b border-gray-200 text-gray-900 text-sm whitespace-nowrap">
                        <div className="flex justify-center items-center gap-x-3">
                          {checkPermission("change_virtualagentlead") && (
                            <Link to={`/${path}/${item.id}`}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-blue-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                />
                              </svg>
                            </Link>
                          )}
                          {/* <button>
                          <ImForward className=" text-blue-500" size={20} />
                        </button> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : isloading ? (
            <Loader />
          ) : (
            <p className="text-xl text-gray-400 py-10 text-center">
              No Data Found
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadListTable;
