import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BalanceChart = ({ userBalance }) => {
  const data = [
    {
      name: "Total Earning",
      Credit_Card: userBalance?.cc_amount,
      Personal_loan: userBalance?.pl_amount,
      Home_loan: userBalance?.hl_amount,
      Car_loan: userBalance?.cl_amount,
    },
  ];

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Credit_Card" fill="#8882d8" />
          <Bar dataKey="Personal_loan" fill="#3C97DA" />
          <Bar dataKey="Home_loan" fill="#82ca9d" />
          <Bar dataKey="Car_loan" fill="#695990" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default BalanceChart;

// {
//   name: "Credit Card",
//   Credit_Card: userBalance?.cc_amount,
//   // Lead_Accept: 24,
//   amt: 10,
// },
// {
//   name: "Loan",
//   Personal_loan: userBalance?.pl_amount,
//   Home_loan: userBalance?.hl_amount,
//   Car_loan: userBalance?.cl_amount,
//   amt: 5000,
// },
