import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Collapsible from "react-collapsible";
import { BsChevronDown, BsFillCreditCard2FrontFill } from "react-icons/bs";
import {
  GiCardPlay,
  GiReceiveMoney,
  GiTakeMyMoney,
  GiMoneyStack,
} from "react-icons/gi";
import { TbLayoutDashboard, TbActivityHeartbeat } from "react-icons/tb";
import {
  FaCreditCard,
  FaList,
  FaCheckDouble,
  FaMoneyCheck,
  FaLaptop,
} from "react-icons/fa";
import { SiGoogleads } from "react-icons/si";
import { MdOutlineFeaturedPlayList, MdForwardToInbox } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { saveUser } from "../../Redux/Slices/userSlice";
import logo from "../../assets/Logo.png";
import { SuccessAlert } from "../Alert/SuccessAlert";
import usePermission from "./../../lib/Hook/UsePermission";

const Sidebar = () => {
  const { checkPermission, isLoading } = usePermission();
  const dispatch = useDispatch();
  const loccation = useLocation();
  const [count, setCount] = useState(0);

  const handleLogout = () => {
    localStorage.removeItem("aamartaka");
    localStorage.clear();
    dispatch(saveUser());
    SuccessAlert("Logout Successful", "success");
  };

  return (
    <div>
      {!isLoading && (
        <aside className="w-54 min-h-screen pb-5" aria-label="Sidebar ">
          <div className="overflow-y-auto overflow-x-hidden pb-4 bg-[#1E40AF]">
            <div className="flex justify-center items-center">
              <NavLink to="/">
                <img src={logo} alt="main Logo" className="h-14 mx-auto" />
              </NavLink>
            </div>

            <hr className="border-dashed my-2 border-gray-400" />
            <ul className="space-y-2">
              <li onClick={() => setCount(1)}>
                <NavLink
                  to="/"
                  className={`${
                    loccation.pathname.includes("test2") && "activeLink"
                  } use" h-10 flex pl-2 items-center text-base font-normal text-gray-900 rounded-md hover:bg-[#294AB3]"`}
                >
                  <TbLayoutDashboard className="text-white text-lg" />
                  <span className="pl-2 text-white">Dashboard </span>
                </NavLink>
              </li>
              {checkPermission("view_commission") && (
                <div className="pl-2 flex w-full justify-center">
                  <GiReceiveMoney className="text-white text-[21px] mt-1.5" />
                  <Collapsible
                    className="w-full text-base font-normal"
                    trigger={["Commission", <BsChevronDown />]}
                    open={count === 2}
                    handleTriggerClick={() => setCount(2)}
                  >
                    <li className="bg-[#1B399D] text-white w-full mt-4 mb-2 py-2">
                      <NavLink
                        to="/cclist"
                        className={`${
                          loccation.pathname.includes("cclist") && "activeLink"
                        } use" h-10 pl-2 flex items-center text-white rounded-md hover:bg-[#294AB3]"`}
                      >
                        <FaCreditCard className="text-lg" />
                        <span className="pl-2 text-base">Card Commission</span>
                      </NavLink>
                      <NavLink
                        to="/lclist"
                        className={`${
                          loccation.pathname.includes("lclist") && "activeLink"
                        } use" h-10 pl-2 flex items-center text-white rounded-md hover:bg-[#294AB3]"`}
                      >
                        <GiReceiveMoney className="text-xl" />
                        <span className="ml-2 text-base">Loan Commission</span>
                      </NavLink>
                    </li>
                  </Collapsible>
                </div>
              )}
              {checkPermission("view_agentprofile") && (
                <li onClick={() => setCount(3)}>
                  <NavLink
                    to="/vrmlist"
                    className={`${
                      loccation.pathname.includes("vrmlist") && "activeLink"
                    } use" h-10 flex items-center pl-2 text-base font-normal text-white rounded-md hover:bg-[#294AB3]"`}
                  >
                    <FaList className="text-base" />
                    <span className="pl-2 active:text-black">
                      VRM Users List
                    </span>
                  </NavLink>
                </li>
              )}
              {/* {checkPermission("view_agentprofile") && ( */}
              <li onClick={() => setCount(3)}>
                <NavLink
                  to="/forwardUser"
                  className={`${
                    loccation.pathname.includes("forwardUser") && "activeLink"
                  } use" h-10 flex items-center pl-2 text-base font-normal text-white rounded-md hover:bg-[#294AB3]"`}
                >
                  <FaList className="text-base" />
                  <span className="pl-2 active:text-black">
                    Assign User Forward
                  </span>
                </NavLink>
              </li>
              {/* )} */}
              {checkPermission("view_virtualagentlead") && (
                <div className="pl-2 flex w-full justify-center">
                  <SiGoogleads className="text-white text-lg mt-2" />
                  <Collapsible
                    className="w-full text-base font-normal"
                    trigger={["Lead List", <BsChevronDown />]}
                    open={count === 4}
                    handleTriggerClick={() => setCount(4)}
                  >
                    <li className="bg-[#1B399D] text-white w-full mt-4 mb-2 py-2">
                      <NavLink
                        to="/cardLead"
                        className={`${
                          loccation.pathname.includes("cardLead") &&
                          "activeLink"
                        } use" h-10 pl-2 flex items-center text-white rounded-md hover:bg-[#294AB3]"`}
                      >
                        <FaCreditCard className="text-lg" />
                        <span className="pl-2 text-base">Card Lead</span>
                      </NavLink>
                      <NavLink
                        to="/loanLead"
                        className={`${
                          loccation.pathname.includes("loanLead") &&
                          "activeLink"
                        } use" h-10 pl-2 flex items-center text-white rounded-md hover:bg-[#294AB3]"`}
                      >
                        <GiReceiveMoney className="text-xl" />
                        <span className="ml-2 text-base">Loan Lead</span>
                      </NavLink>
                    </li>
                  </Collapsible>
                </div>
              )}
              {checkPermission("view_virtualagentlead") && (
                <li onClick={() => setCount(5)}>
                  <NavLink
                    to="/submittedelead"
                    className={`${
                      loccation.pathname.includes("submittedelead") &&
                      "activeLink"
                    } use" h-10 pl-2 flex items-center text-base font-normal text-white rounded-md hover:bg-[#294AB3]"`}
                  >
                    <FaCheckDouble className="text-base" />
                    <span className="pl-1.5 active:text-black">
                      Submitted Lead
                    </span>
                  </NavLink>
                </li>
              )}
              {checkPermission("view_payment") && (
                <div className="pl-2 flex w-full justify-center">
                  <FaMoneyCheck className="text-white text-lg mt-2.5" />
                  <Collapsible
                    className="w-full text-base font-normal"
                    trigger={["Payment", <BsChevronDown />]}
                    open={count === 6}
                    handleTriggerClick={() => setCount(6)}
                  >
                    <li className="bg-[#1B399D] text-white w-full mt-4 mb-2 pb-2 pt-1">
                      <NavLink
                        to="/paymentrequest"
                        className={`${
                          loccation.pathname.includes("paymentrequest") &&
                          "activeLink"
                        } use" h-10 pl-2 flex items-center text-white rounded-md hover:bg-[#294AB3]"`}
                      >
                        <GiTakeMyMoney className="text-lg" />
                        <span className="pl-2 text-base">Payment Request</span>
                      </NavLink>
                      <NavLink
                        to="/paymentstatus"
                        className={`${
                          loccation.pathname.includes("paymentstatus") &&
                          "activeLink"
                        } use" h-10 pl-2 flex items-center text-white rounded-md hover:bg-[#294AB3]"`}
                      >
                        <GiMoneyStack className="text-xl" />
                        <span className="ml-2 text-base">Payment Details</span>
                      </NavLink>
                    </li>
                  </Collapsible>
                </div>
              )}
              {checkPermission("view_feature") && (
                <li onClick={() => setCount(7)}>
                  <NavLink
                    to="/feature"
                    className={`${
                      loccation.pathname.includes("feature") && "activeLink"
                    } use" h-10 pl-2 flex items-center text-base font-normal text-white rounded-md hover:bg-[#294AB3]"`}
                  >
                    <MdOutlineFeaturedPlayList className="text-[17px]" />
                    <span className="pl-1.5 active:text-black mt-0.5">
                      Feature
                    </span>
                  </NavLink>
                </li>
              )}
              {checkPermission("view_newpolicy") && (
                <li onClick={() => setCount(8)}>
                  <NavLink
                    to="/newPolicy"
                    className={`${
                      loccation.pathname.includes("newPolicy") && "activeLink"
                    } use" h-10 pl-2 flex items-center text-base font-normal text-white rounded-md hover:bg-[#294AB3]"`}
                  >
                    <MdOutlineFeaturedPlayList className="text-[17px]" />
                    <span className="pl-1.5 active:text-black mt-0.5">
                      New Policy
                    </span>
                  </NavLink>
                </li>
              )}

              {checkPermission("view_userbenefit") && (
                <li onClick={() => setCount(9)}>
                  <NavLink
                    to="/userBenefit"
                    className={`${
                      loccation.pathname.includes("userBenefit") && "activeLink"
                    } use" h-10 pl-2 flex items-center text-base font-normal text-white rounded-md hover:bg-[#294AB3]"`}
                  >
                    <FaLaptop className="text-[17px]" />
                    <span className="pl-1.5 active:text-black mt-0.5">
                      User Benefit
                    </span>
                  </NavLink>
                </li>
              )}
              {checkPermission("view_vrmsupport") && (
                <li onClick={() => setCount(10)}>
                  <NavLink
                    to="/inbox"
                    className={`${
                      loccation.pathname.includes("inbox") && "activeLink"
                    } use" h-10 pl-2 flex items-center text-base font-normal text-white rounded-md hover:bg-[#294AB3]"`}
                  >
                    <MdForwardToInbox className="text-[17px]" />
                    <span className="pl-1.5 active:text-black mt-0.5">
                      Inbox
                    </span>
                  </NavLink>
                </li>
              )}

              <li onClick={() => setCount(11)}>
                <NavLink
                  to="/logs"
                  className={`${
                    loccation.pathname.includes("logs") && "activeLink"
                  } use" h-10 pl-2 flex items-center text-base font-normal text-white rounded-md hover:bg-[#294AB3]"`}
                >
                  <TbActivityHeartbeat className="text-xl" />
                  <span className="pl-1.5 active:text-black mt-0.5">
                    VRM Activity
                  </span>
                </NavLink>
              </li>

              {checkPermission("view_agentcommission") && (
                <div className="pl-2 flex w-full justify-center">
                  <BsFillCreditCard2FrontFill className="text-white text-lg mt-2.5" />
                  <Collapsible
                    className="w-full text-base font-normal"
                    trigger={["Special Commission", <BsChevronDown />]}
                    open={count === 12}
                    handleTriggerClick={() => setCount(12)}
                  >
                    <li className="bg-[#1B399D] text-white w-full mt-4 mb-2 pb-2 pt-1">
                      <NavLink
                        to="/specialCardCommission"
                        className={`${
                          loccation.pathname.includes(
                            "specialCardCommission"
                          ) && "activeLink"
                        } use" h-10 pl-2 flex items-center text-white rounded-md hover:bg-[#294AB3]"`}
                      >
                        <GiCardPlay className="text-lg" />
                        <span className="pl-2 text-base">Card Commission</span>
                      </NavLink>
                      <NavLink
                        to="/specialLoanCommission"
                        className={`${
                          loccation.pathname.includes(
                            "specialLoanCommission"
                          ) && "activeLink"
                        } use" h-10 pl-2 flex items-center text-white rounded-md hover:bg-[#294AB3]"`}
                      >
                        <GiMoneyStack className="text-xl" />
                        <span className="ml-2 text-base">Loan Commission</span>
                      </NavLink>
                    </li>
                  </Collapsible>
                </div>
              )}

              <button onClick={handleLogout} className="pt-4 w-full">
                <p className="transition duration-500 py-1.5 flex justify-center items-center text-base font-normal rounded-full bg-red-400 text-white hover:text-red-500 hover:bg-white border border-red-400 mx-2 pl-2 cursor-pointer">
                  <AiOutlineLogout className="text-xl" />
                  <li className="ml-2 text-base font-normal">Log Out</li>
                </p>
              </button>
            </ul>
          </div>
        </aside>
      )}
    </div>
  );
};

export default Sidebar;
