import "./App.css";
import Layout from "./Components/Layout/Layout";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import Sidebar from "./Shared/Sidebar/Sidebar";
import CreateTeam from "./Components/CreateTeam/CreateTeam";
import ManageTeam from "./Components/ManageTeam/ManageTeam";
import CardCommissionList from "./Components/CardCommissionList/CardCommissionList";
import LoanCommissionList from "./Components/LoanCommissionList/LoanCommissionList";
import VRMList from "./Components/VRMList/VRMList";
import VRMAccount from "./Components/VRMAccount/VRMAccount";
import Account from "./Components/Account/Account";
import Agent from "./Components/Agent/Agent";
import PaymentStatus from "./Components/PaymentStatus/PaymentStatus";
import PaymentHistory from "./Components/PaymentStatus/PaymentHistory";
import Feature from "./Components/Feature/Feature";
import Inbox from "./Components/Inbox/Inbox";
import EditTeam from "./Components/EditTeam/EditTeam";
import VRMListEdit from "./Components/VRMList/VRMListEdit";
import AddFeature from "./Components/AddFeature/AddFeature";
import EditVRMList from "./Components/VRMCommission/EditVRMList";
import Editfeature from "./Components/Feature/Editfeature/Editfeature";
import ViewFeature from "./Components/Feature/ViewFeature/ViewFeature";
import Login from "./Components/Authentication/login/Login";
import PrivateRoute from "./Components/Authentication/login/PrivateRoute/PrivateRoute";
import { useSelector, useDispatch } from "react-redux";
import SubmittedLead from "./Components/SubmittedLead/SubmittedLead";
import SubmittedLeadView from "./Components/SubmittedLead/SubmittedLeadView";
import PaymentRequest from "./Components/PaymentRequest/PaymentRequest";
import { saveUser } from "./Redux/Slices/userSlice";
import { useEffect } from "react";
import NewVRM from "./Components/VRMList/NewVRM";
import TogleSidebar from "./Shared/TogleSidebar/TogleSidebar";
import VRMActivity from "./Components/VRMActivity/VRMActivity";
import UserBenefit from "./Components/UserBenefit/UserBenefit";
import EditUserBenefit from "./Components/UserBenefit/EditUserBenefit";
import ViewUserBenefit from "./Components/UserBenefit/ViewUserBenefit";
import SpecialCardCommission from "./Components/SpecialCommission/SpecialCardCommission";
import SpecialLoanCommission from "./Components/SpecialCommission/SpecialLoanCommission";
import CardLeadList from "./Components/LeadList/LeadList/CardLeadList";
import LoanLeadList from "./Components/LeadList/LeadList/LoanLeadList";
import CreateCardLead from "./Components/LeadList/CreateLead/CreateCardLead";
import CreateLoanLead from "./Components/LeadList/CreateLead/CreateLoanLead";
import EditCardLead from "./Components/LeadList/EditLeadList/EditCardLead";
import EditLoanLead from "./Components/LeadList/EditLeadList/EditLoanLead";
import NewPolicy from "./Components/NewPolicy/NewPolicy";
import ForwardUser from "./Components/ForwardUser/ForwardUser";

function App() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userSlice?.user);

  useEffect(() => {
    dispatch(saveUser());
    // const loadData = async () => {
    //   const userdata = await localStorage.getItem("aamartaka");
    //   setUser(userdata);
    // };

    // loadData();
  }, [dispatch]);

  return (
    <div>
      <div className="grid grid-cols-12 mt-5 mx-5">
        {userData?.token && (
          <div className=" hidden lg:block col-span-2 overflow-x-hidden example  ">
            <Sidebar />
          </div>
        )}
        <div className=" block lg:hidden example ">
          <TogleSidebar />
        </div>
        <div
          className={`${
            userData && userData?.token ? "lg:col-span-10" : "lg:col-span-12"
          }  col-span-12 `}
        >
          <Layout>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/createteam/"
                element={
                  <PrivateRoute>
                    <CreateTeam />
                  </PrivateRoute>
                }
              />
              <Route
                path="/createteam/:id"
                element={
                  <PrivateRoute>
                    <EditTeam />
                  </PrivateRoute>
                }
              />
              <Route
                path="/manageteam"
                element={
                  <PrivateRoute>
                    <ManageTeam />
                  </PrivateRoute>
                }
              />

              <Route
                path="/cclist"
                element={
                  <PrivateRoute>
                    <CardCommissionList />
                  </PrivateRoute>
                }
              />

              <Route
                path="/vrmedit/:id"
                element={
                  <PrivateRoute>
                    <EditVRMList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/lclist"
                element={
                  <PrivateRoute>
                    <LoanCommissionList />
                  </PrivateRoute>
                }
              />

              <Route
                path="/vrmlist"
                element={
                  <PrivateRoute>
                    <VRMList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/forwardUser"
                element={
                  <PrivateRoute>
                    <ForwardUser />
                  </PrivateRoute>
                }
              />
              <Route
                path="/newvrm"
                element={
                  <PrivateRoute>
                    <NewVRM />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vrmlist/:id"
                element={
                  <PrivateRoute>
                    <VRMListEdit />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vrmaccount/:vrmID"
                element={
                  <PrivateRoute>
                    <VRMAccount />
                  </PrivateRoute>
                }
              />
              <Route
                path="/account"
                element={
                  <PrivateRoute>
                    <Account />
                  </PrivateRoute>
                }
              />
              <Route
                path="/cardLead"
                element={
                  <PrivateRoute>
                    <CardLeadList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loanLead"
                element={
                  <PrivateRoute>
                    <LoanLeadList />
                  </PrivateRoute>
                }
              />
              {/* <Route path="/leadlist/:leadID" element={<LeadList />} /> */}
              <Route
                path="/cardLead/:id"
                element={
                  <PrivateRoute>
                    <EditCardLead />
                  </PrivateRoute>
                }
              />
              <Route
                path="/loanLead/:id"
                element={
                  <PrivateRoute>
                    <EditLoanLead />
                  </PrivateRoute>
                }
              />
              <Route
                path="/newagent"
                element={
                  <PrivateRoute>
                    <Agent />
                  </PrivateRoute>
                }
              />
              <Route
                path="/newCardlead"
                element={
                  <PrivateRoute>
                    <CreateCardLead />
                  </PrivateRoute>
                }
              />
              <Route
                path="/newLoanlead"
                element={
                  <PrivateRoute>
                    <CreateLoanLead />
                  </PrivateRoute>
                }
              />
              <Route
                path="/newCardlead/:id"
                element={
                  <PrivateRoute>
                    <CreateCardLead />
                  </PrivateRoute>
                }
              />
              <Route
                path="/newLoanlead/:id"
                element={
                  <PrivateRoute>
                    <CreateLoanLead />
                  </PrivateRoute>
                }
              />
              <Route
                path="/paymentstatus"
                element={
                  <PrivateRoute>
                    <PaymentStatus />
                  </PrivateRoute>
                }
              />
              <Route
                path="/paymentrequest"
                element={
                  <PrivateRoute>
                    <PaymentRequest />
                  </PrivateRoute>
                }
              />
              <Route
                path="/paymenthistory/:id"
                element={
                  <PrivateRoute>
                    <PaymentHistory />
                  </PrivateRoute>
                }
              />
              <Route
                path="/feature"
                element={
                  <PrivateRoute>
                    <Feature />
                  </PrivateRoute>
                }
              />
              <Route
                path="/newPolicy"
                element={
                  <PrivateRoute>
                    <NewPolicy />
                  </PrivateRoute>
                }
              />
              <Route
                path="/userBenefit"
                element={
                  <PrivateRoute>
                    <UserBenefit />
                  </PrivateRoute>
                }
              />
              <Route
                path="/userBenefit/:id"
                element={
                  <PrivateRoute>
                    <EditUserBenefit />
                  </PrivateRoute>
                }
              />
              <Route
                path="/viewUserBenefit/:id"
                element={
                  <PrivateRoute>
                    <ViewUserBenefit />
                  </PrivateRoute>
                }
              />
              <Route
                path="/addfeature"
                element={
                  <PrivateRoute>
                    <AddFeature />
                  </PrivateRoute>
                }
              />
              <Route
                path="/editfeature/:id"
                element={
                  <PrivateRoute>
                    <Editfeature />
                  </PrivateRoute>
                }
              />
              <Route
                path="/viewfeature/:id"
                element={
                  <PrivateRoute>
                    <ViewFeature />
                  </PrivateRoute>
                }
              />
              <Route
                path="/inbox"
                element={
                  <PrivateRoute>
                    <Inbox />
                  </PrivateRoute>
                }
              />
              <Route
                path="/specialCardCommission"
                element={
                  <PrivateRoute>
                    <SpecialCardCommission />
                  </PrivateRoute>
                }
              />
              <Route
                path="/specialLoanCommission"
                element={
                  <PrivateRoute>
                    <SpecialLoanCommission />
                  </PrivateRoute>
                }
              />
              <Route
                path="/submittedelead"
                element={
                  <PrivateRoute>
                    <SubmittedLead />
                  </PrivateRoute>
                }
              />
              <Route
                path="/slview/:id"
                element={
                  <PrivateRoute>
                    <SubmittedLeadView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/logs"
                element={
                  <PrivateRoute>
                    <VRMActivity />
                  </PrivateRoute>
                }
              />
              <Route path="/login" element={<Login />} />
            </Routes>
          </Layout>
        </div>
      </div>
    </div>
  );
}

export default App;
