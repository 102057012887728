import { useState } from "react";
import DotLoader from "react-spinners/DotLoader";

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#1EA7FD",
};

function ModalLoader() {
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#1EA7FD");

    return (
        <div className="sweet-loading flex justify-center items-center min-h-[40vh] h-auto">
            <DotLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={150}
            />
        </div>
    );
}

export default ModalLoader;
