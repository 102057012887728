import axios from "axios";
import React, { useEffect, useState } from "react";
import useHeaders from "../../Shared/Header/Header";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import PaymentRequestTable from "./PaymentRequestTable";
import usePermission from "./../../lib/Hook/UsePermission";

const PaymentRequest = () => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/payment/?status=Pending`,
        headers
      );
      if (res?.status === 200) {
        setIsLoading(false);
        setData(res?.data.results);
      } else {
        setIsLoading(false);
      }
    };
    loadData();
  }, []);

  return (
    <div className="h-[87vh] mx-4 mt-3">
      <HeadingTitle title="Requested Payment List" />
      {checkPermission("view_payment") && (
        <PaymentRequestTable data={data} isLoading={isLoading} />
      )}
    </div>
  );
};

export default PaymentRequest;
