import axios from "axios";
import React, { useState, useEffect } from "react";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import useHeaders from "../../Shared/Header/Header";
import AddBenefitModal from "./AddBenefitModal";
import BenefitTable from "./BenefitTable";
import { deleteAlert } from "../../Shared/Alert/deleteAlert";
import usePermission from "./../../lib/Hook/UsePermission";
import Loader from "./../../Shared/Loader/Loader";

const API = `${process.env.REACT_APP_HOST_URL}/api/user_benefit/`;

const UserBenefit = () => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const [allUserBenefits, setAllUserBenefits] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [changes, setChanges] = React.useState(0);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/user_benefit/`,
        headers
      );
      if (res?.status === 200) {
        setAllUserBenefits(res?.data?.results);
        setIsLoading(false);
      }
    };
    loadData();
  }, [changes]);

  const handleDelete = (id) => {
    deleteAlert(API, id, headers, changes, setChanges);
  };

  return (
    <div>
      <div className="min-h-screen">
        <div className="my-2 mx-3">
          <div className="flex justify-between items-center mt-2 mb-1">
            <HeadingTitle title="User Benefit" />
            {checkPermission("add_userbenefit") && (
              <button
                onClick={() => setShowModal(true)}
                className="bg-[#1E40AF] rounded-md text-white font-bold py-2 px-4 border border-[#1E40AF] hover:bg-transparent hover:text-[#1E40AF] duration-300 cursor-pointer"
              >
                Add User Benefit
              </button>
            )}
          </div>
          {checkPermission("view_userbenefit") && (
            <div>
              <BenefitTable
                handleDelete={handleDelete}
                data={allUserBenefits}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <AddBenefitModal
          showModal={showModal}
          setShowModal={setShowModal}
          changes={changes}
          setChanges={setChanges}
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default UserBenefit;
