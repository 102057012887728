import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { isLoaDing, saveUser } from "../../../Redux/Slices/userSlice";
import Loader from "../../../Shared/Loader/Loader";
import logo from "../../../assets/Logo.png";
import Swal from "sweetalert2";
import { SuccessAlert } from "./../../../Shared/Alert/SuccessAlert";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState({
    phone: "",
    password: "",
  });

  const [userData, setUserData] = useState(null);
  const oldUser = userData?.user_data?.user;

  useEffect(() => {
    const getDataFromLocalStorage = () => {
      const userDataLocalStorage = localStorage.getItem("aamartaka");
      if (userDataLocalStorage) {
        const userDataObj = JSON.parse(userDataLocalStorage);
        setUserData(userDataObj);
      }
    };
    getDataFromLocalStorage();
  }, []);

  useEffect(() => {
    if (oldUser) {
      navigate("/");
      SuccessAlert("Already Logged in", "error");
    }
  }, [oldUser]);

  const handleBlure = (e) => {
    const { name, value } = e.target;
    const newData = { ...loginData };
    newData[name] = value;
    setLoginData(newData);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      username: loginData?.phone,
      password: loginData?.password,
    };

    const login = async () => {
      dispatch(isLoaDing(true));

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_HOST_URL}/api/login/`,
          data
        );

        if (res.status === 200) {
          res.isLoading = true;
          localStorage.setItem("aamartaka", JSON.stringify(res?.data));
          dispatch(saveUser(res?.data));
          Swal.fire({
            icon: "success",
            title: "Login Successful",
            showConfirmButton: false,
            timer: 2000,
          });
          navigate("/");
        }

        if (res.data?.error) {
          Swal.fire({
            icon: "error",
            title: res.data?.error,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Invalid Credential",
          showConfirmButton: false,
          timer: 2000,
        });
      } finally {
        setLoading(false);
        dispatch(isLoaDing(false));
      }
    };

    login();
    e.target.reset();
  };

  return (
    <div>
      {!oldUser && (
        <div className="h-screen flex justify-center items-center w-full">
          {loading ? (
            <Loader />
          ) : (
            <div className="flex items-center justify-center min-h-screen bg-gray-100 w-full">
              <div className="px-8 py-6 text-left bg-white border border-gray-100 shadow-lg rounded-[3px] lg:w-2/5 md:w-4/5 w-full">
                <div className="flex justify-center">
                  <img src={logo} alt="" className=" h-20 mx-auto" />
                </div>
                <h3 className="text-xl text-center">Login to your account</h3>
                <form onSubmit={handleSubmit}>
                  <div className="mt-4">
                    <div>
                      <label className="block" htmlFor="email">
                        Username
                      </label>
                      <input
                        autoComplete="off"
                        required
                        name="phone"
                        type="text"
                        placeholder="Enter Username"
                        onChange={handleBlure}
                        className="w-full px-4 py-2.5 mt-2 border rounded-[3px] focus:outline-none focus:ring-1 focus:ring-blue-600"
                      />
                    </div>
                    <div className="mt-4">
                      <label className="block">Password</label>
                      <input
                        autoComplete="off"
                        required
                        name="password"
                        onChange={handleBlure}
                        type="password"
                        placeholder="Password"
                        className="w-full px-4 py-2.5 mt-2 border rounded-[3px] focus:outline-none focus:ring-1 focus:ring-blue-600"
                      />
                    </div>
                    <div className="flex items-baseline justify-between w-full">
                      <button
                        type="submit"
                        className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-[3px] w-[20%] border border-blue-600 hover:bg-white hover:text-blue-600 transition-all duration-300 ease-in-out"
                      >
                        Login
                      </button>
                      <a
                        href="#"
                        className="text-sm text-blue-600 hover:underline"
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Login;
