import React from "react";
import InboxChat from "./InboxChat";
import usePermission from "./../../lib/Hook/UsePermission";
import Loader from "../../Shared/Loader/Loader";

const Inbox = () => {
  const { checkPermission } = usePermission();
  return (
    <div className="pb-5 ">
      {checkPermission("view_vrmsupport") ? (
        <InboxChat />
      ) : (
        <div className="min-h-screen">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Inbox;
