import axios from "axios";
import React from "react";
import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useHeaders from "../../../Shared/Header/Header";
import HeadingTitle from "../../../Shared/HeadingTitle/HeadingTitle";
import Loader from "../../../Shared/Loader/Loader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useInstitute from "./../../../Shared/Hooks/UseInstitute";

const Editfeature = () => {
  const headers = useHeaders();
  const { id } = useParams();
  const [feature, setFeature] = useState([]);
  const [productType, setProductType] = useState({});
  const { institutes } = useInstitute(
    productType?.value === 1
      ? process.env.REACT_APP_CARD_CONTENT_TYPE
      : process.env.REACT_APP_LOAN_CONTENT_TYPE
  );

  const [bank, setBank] = useState("");
  const [cardTypeData, setCardTypeData] = useState([]);
  const [loanTypeData, setLoanTypeData] = useState([]);
  const [objectId, setObjectId] = useState(null);
  const [contentType, setContentType] = useState(0);
  // Use separate state variables for the Quill content
  const [editFeature, setEditFeature] = useState("");
  const [editEligibility, setEditEligibility] = useState("");
  const [editShortFeature, setEditShortFeature] = useState("");
  const [defaultFeature, setDefaultFeature] = useState("");
  const [defaultEligibility, setDefaultEligibility] = useState("");
  const [defaultShortFeature, setDefaultShortFeature] = useState("");
  const router = useNavigate();

  const productOptions = [
    { value: 1, label: "Credit Card" },
    { value: 2, label: "Loan" },
  ];

  useEffect(() => {
    if (bank?.value) {
      // get Card Type Data
      if (productType?.value === 1) {
        axios
          .get(
            `${process.env.REACT_APP_HOST_URL}/api/commission-wise-unique-product-institutes/?content_type=132&institute_id=${bank?.value}`,
            headers
          )
          .then((res) => {
            setCardTypeData(res?.data);
            setContentType(132);
          });
      }
      if (productType?.value === 2) {
        // Get Loan Type Data
        axios
          .get(
            `${process.env.REACT_APP_HOST_URL}/api/commission-wise-unique-product-institutes/?content_type=119&institute_id=${bank?.value}`,
            headers
          )
          .then((res) => {
            setLoanTypeData(res?.data);
            setContentType(119);
          });
      }
    }
  }, [productType?.value, bank]);

  // Load Default Feature Data
  useEffect(() => {
    const loadData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/feature/${id}/`,
        headers
      );

      setFeature(response?.data);
      if (response?.data?.product_type === "Credit Card") {
        setProductType({
          value: 1,
          label: "Credit Card ",
        });
      } else
        setProductType({
          value: 2,
          label: "Loan ",
        });
    };

    loadData();
  }, [id]);

  useEffect(() => {
    if (featureLength > 0) {
      setDefaultFeature(feature?.feature || "");
      setDefaultEligibility(feature?.eligibility || "");
      setDefaultShortFeature(feature?.short_feature || "");
    }
  }, [feature]);

  // All Event Handler
  const handleInstituteChange = (selectedOption) => {
    setBank(selectedOption);
  };

  const handleCardTypeChange = (selectedOption) => {
    setObjectId(selectedOption);
  };
  const handleLoanTypeChange = (selectedOption) => {
    setObjectId(selectedOption);
  };

  const handleEditFeature = (value) => {
    setEditFeature(value);
    setDefaultFeature(value);
  };

  const handleEditEligibility = (value) => {
    setEditEligibility(value);
    setDefaultEligibility(value);
  };

  const handleEditShortFeature = (value) => {
    setEditShortFeature(value);
    setDefaultShortFeature(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      institute: bank?.value || feature?.institute,
      product_type: productType?.value || feature?.product_type,
      content_type: contentType || feature?.content_type,
      object_id: objectId?.value || feature?.object_id,
      feature: editFeature || feature?.feature,
      eligibility: editEligibility || feature?.eligibility,
      short_feature: editShortFeature || feature?.short_feature,
    };

    axios
      .put(
        `${process.env.REACT_APP_HOST_URL}/api/feature/${id}/`,
        data,
        headers
      )
      .then(function (response) {
        if (response?.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Successfully Update",
            showConfirmButton: false,
            timer: 1500,
          });
          router("/feature");
        } else {
          Swal.fire({
            icon: "error",
            title: "Something Wrong",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch(function (error) {});
  };

  const featureLength = Object.keys(feature).length;

  return (
    <div className="h-screen mx-4">
      <div className="mt-4 mb-3">
        <HeadingTitle title="Edit Feature" />
      </div>
      {featureLength > 0 ? (
        <form
          onSubmit={handleSubmit}
          className="bg-white border rounded-md px-6 pt-4"
        >
          <div className="lg:flex block items-center justify-between gap-x-4 z-50">
            <div className="w-full z-50">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Institute Name
              </label>
              <Select
                defaultValue={{
                  label: feature?.institute_name,
                  value: feature?.institute_name,
                }}
                required
                name="bank_name"
                onChange={handleInstituteChange}
                options={institutes?.map((institute) => ({
                  value: institute?.id,
                  label: institute?.name,
                }))}
                className="w-full border-none text-gray-700 rounded mb-1 leading-tight"
              />
            </div>
            <div className="w-full">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Product Type
              </label>
              <Select
                defaultValue={{
                  label: feature?.product_type,
                  value: feature?.product_type,
                }}
                required
                name="product_type"
                onChange={setProductType}
                options={productOptions}
                className="w-full border-none text-gray-700 rounded mb-1 leading-tight"
              />
            </div>
            {productType?.value === 1 && (
              <div className="w-full">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Card Type
                </label>
                <Select
                  defaultValue={{
                    label: feature?.product,
                    value: feature?.product,
                  }}
                  required
                  name="card_type"
                  onChange={handleCardTypeChange}
                  options={cardTypeData?.map((card) => ({
                    value: card?.id,
                    label: card?.name,
                  }))}
                  className="w-full border-none text-gray-700 rounded mb-1 leading-tight"
                />
              </div>
            )}
            {productType?.value === 2 && (
              <div className="w-full">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
                  htmlFor="grid-first-name"
                >
                  Loan Type
                </label>
                <Select
                  defaultValue={{
                    label: feature?.product,
                    value: feature?.product,
                  }}
                  required
                  onChange={handleLoanTypeChange}
                  options={loanTypeData?.map((loan) => ({
                    value: loan?.id,
                    label: loan?.name,
                  }))}
                  className="w-full border-none text-gray-700 rounded mb-1 leading-tight"
                />
              </div>
            )}
          </div>
          <div className="mb-3 mt-7">
            <h4 className="block uppercase tracking-wide text-[#2563eb] text-md font-bold mb-1 ml-1">
              FEATURE
            </h4>
            <ReactQuill
              value={defaultFeature}
              onChange={handleEditFeature}
              className="quill-editor"
              modules={quillModules}
              style={{ color: "" }}
            />
          </div>
          <div className="my-6">
            <h4 className="block uppercase tracking-wide text-[#2563eb] text-md font-bold mb-1 ml-1">
              ELIGIBILITY
            </h4>
            <ReactQuill
              value={defaultEligibility}
              onChange={handleEditEligibility}
              className="quill-editor"
              modules={quillModules}
              style={{ color: "" }}
            />
          </div>
          <div className="my-6">
            <h4 className="block uppercase tracking-wide text-[#2563eb] text-md font-bold mb-1 ml-1">
              SHORT FEATURE
            </h4>
            <ReactQuill
              value={defaultShortFeature}
              onChange={handleEditShortFeature}
              className="quill-editor"
              modules={quillModules}
              style={{ color: "" }}
            />
          </div>
          <button
            type="submit"
            className="py-2 px-4 text-white bg-[#2563eb] border border-[#2563eb] hover:bg-white hover:text-[#2563eb] duration-300 rounded mt-2 mb-4"
          >
            Update Now
          </button>
        </form>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Editfeature;

// Configure Quill modules with an extensive toolbar
const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }, { direction: "ltr" }],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link"],
    ["clean"],
  ],

  clipboard: {
    matchVisual: false,
  },
};
