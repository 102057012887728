import React from "react";
import { FaTimes } from "react-icons/fa";
import { useEffect } from "react";
import axios from "axios";
import useHeaders from "../../Shared/Header/Header";
import { MdOutlineFileDownloadDone } from "react-icons/md";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import Loader from "../../Shared/Loader/ModalLoader";

const EditSpecialCommission = ({
  setEditCommission,
  updated,
  setUpdated,
  pathType,
  id,
}) => {
  const headers = useHeaders();
  const [singleData, setSingleData] = React.useState();
  const [commission, setCommission] = React.useState(0);
  const [expire_date, setExpire_date] = React.useState(0);

  // Find Single Product By ID
  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_HOST_URL}/api/${pathType}/${id}`, headers)
        .then((resp) => {
          setSingleData(resp?.data);
        });
    }
  }, [id, pathType]);

  // Submit Function Here

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      commission: commission || singleData?.commission,
      id: singleData?.id,
      expire_date:
        expire_date ||
        new Date(singleData?.expire_date).toISOString().slice(0, 10),
    };

    // For Card Commission
    if (pathType === "agent_card_commission") {
      if (data?.commission >= 100 && data?.commission <= 5000) {
        axios
          .patch(
            `${process.env.REACT_APP_HOST_URL}/api/${pathType}/${id}/`,
            data,
            headers
          )
          .then((res) => {
            if (res) {
              setEditCommission(false);
              SuccessAlert("Update Successful", "success");
              setUpdated(updated + 1);
            }
          })
          .catch((error) => {
            SuccessAlert(error?.message, "error");
          });
      } else {
        SuccessAlert("Commission Must Between 100 to 5000", "error");
      }
    }
    // For Card Commission
    if (pathType === "agent_loan_commission") {
      if (data?.commission >= 100 && data?.commission <= 1500) {
        axios
          .patch(
            `${process.env.REACT_APP_HOST_URL}/api/${pathType}/${id}/`,
            data,
            headers
          )
          .then((res) => {
            if (res) {
              setEditCommission(false);
              SuccessAlert("Update Successful", "success");
              setUpdated(updated + 1);
            }
          })
          .catch((error) => {
            SuccessAlert(error?.message, "error");
          });
      } else {
        SuccessAlert("Commission Must Between 100 to 5000", "error");
      }
    }
  };

  return (
    <div className="fixed z-10 overflow-y-auto top-0 w-full left-0">
      <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div className="inline-block align-center bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full pb-5">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {singleData?.id ? (
              <>
                <div className="flex justify-between items-center mb-5">
                  <h2 className="text-2xl text-[#2563eb] mb-0">
                    Edit Special{" "}
                    {pathType === "agent_card_commission" ? "Card" : "Loan"}{" "}
                    Commission
                  </h2>
                  <button
                    onClick={() => setEditCommission(false)}
                    type="button"
                    className="h-8 w-8 text-white bg-red-500 hover:text-red-600 hover:bg-white border border-red-600 rounded-full flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                  >
                    <FaTimes className="text-xl" />
                  </button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="flex justify-between items-center gap-4">
                    <div className="w-full">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1"
                        htmlFor="institute"
                      >
                        Institute Name
                      </label>
                      <select
                        placeholder="Select Institute"
                        type="text"
                        name="institute"
                        className="rounded px-4 py-2.5 text-md accent-sky-600 border-gray-300 bg-gray-200 border focus:outline-sky-500 w-full my-3"
                        disabled
                      >
                        <option
                          defaultValue={singleData?.bank_name}
                          value={singleData?.bank_name}
                        >
                          {singleData?.bank_name}
                        </option>
                      </select>
                    </div>

                    {pathType === "agent_card_commission" && (
                      <div className="w-full">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1"
                          htmlFor="cardType"
                        >
                          Select Card Type
                        </label>
                        <select
                          placeholder="Select Institute"
                          type="text"
                          name="cardType"
                          className="rounded px-4 py-2.5 text-md accent-sky-600 border-gray-300 bg-gray-200 border focus:outline-sky-500 w-full my-3"
                          disabled
                        >
                          <option
                            defaultValue={singleData?.product}
                            value={singleData?.product}
                          >
                            {singleData?.product}
                          </option>
                        </select>
                      </div>
                    )}

                    {pathType === "agent_loan_commission" && (
                      <div className="w-full">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-1 mb-2"
                          htmlFor="loanType"
                        >
                          Select Loan Type
                        </label>

                        <select
                          placeholder="Select Loan Type"
                          type="text"
                          name="loanType"
                          className="rounded px-4 py-2.5 text-md accent-sky-600 border-gray-300 bg-gray-200 border focus:outline-sky-500 w-full my-3"
                          disabled
                        >
                          <option
                            defaultValue={singleData?.product}
                            value={singleData?.product}
                          >
                            {singleData?.product}
                          </option>
                        </select>
                      </div>
                    )}
                  </div>

                  {/* Select Commissione */}
                  <div className="flex justify-between items-center gap-4">
                    <div className="w-full">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2"
                        htmlFor="commission"
                      >
                        Commission
                      </label>
                      <input
                        className="w-full my-2 border border-gray-300 rounded py-2.5 px-2 focus:bg-gray-50 focus:outline-[#2563eb]"
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                        }}
                        maxLength="4"
                        name="commission"
                        placeholder="Enter Commission Amount"
                        onChange={(e) => setCommission(e.target.value)}
                        defaultValue={singleData?.commission}
                        required
                      />
                    </div>
                    <div className="w-full">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2"
                        htmlFor="expire_date"
                      >
                        Expire Date
                      </label>
                      <input
                        defaultValue={singleData?.expire_date}
                        type="date"
                        className="w-full my-2 border border-gray-300 rounded py-2.5 px-2 focus:bg-gray-50 focus:outline-[#2563eb]"
                        name="expire_date"
                        placeholder="Enter Expire Date"
                        onChange={(e) => setExpire_date(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="py-5 float-right">
                    <div className="flex items-center gap-x-4">
                      <button
                        // onClick={() => setAddCommission(false)}
                        type="submit"
                        className="py-2 px-3 text-white bg-[#2563eb] hover:text-[#2563eb] hover:bg-white border border-[#2563eb] rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                      >
                        <MdOutlineFileDownloadDone className="text-xl" />
                        <p> Update</p>
                      </button>
                      <button
                        onClick={() => setEditCommission(false)}
                        type="reset"
                        className="py-2 px-4 text-white bg-red-500 hover:text-red-600 hover:bg-white border border-red-600 rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                      >
                        <FaTimes />
                        <p> Cancel</p>
                      </button>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSpecialCommission;
