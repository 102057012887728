import axios from "axios";
import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import useHeaders from "../../Shared/Header/Header";
import EditVRMCard from "./EditVRMCard";

const EditVRMList = () => {
  const headers = useHeaders();
  const { id } = useParams();
  const [institute, setInstitute] = useState(null);
  const [error, setError] = useState(false);
  const [inputList, setInputList] = useState([
    { product_type: "", from: 0, commission: 0, to: 0 },
  ]);

  const [user, setUser] = useState([]);

  useEffect(() => {
    const loadUser = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/agent_profile/${id}/`,
        headers
      );
      setUser(res?.data);
    };
    loadUser();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      product_type: [...inputList],
      bank_name: institute?.value,
    };
  };

  return (
    <div className="h-screen  overflow-scroll p-3 my-3 ">
      <EditVRMCard
        institute={institute}
        setInstitute={setInstitute}
        inputList={inputList}
        setInputList={setInputList}
        error={error}
      />

      <div className=" flex justify-between items-center ">
        <input
          onClick={handleSubmit}
          className=" bg-sky-500 py-1 px-8 rounded-[15px] text-white  mt-3 "
          type="submit"
          value=" Submit"
        />
      </div>
    </div>
  );
};

export default EditVRMList;
