import React, { useState, useEffect } from "react";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import Select from "react-select";
import axios from "axios";
import useHeaders from "./../../Shared/Header/Header";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import { VscChromeClose } from "react-icons/vsc";
import Spinner from "../../Shared/Spinner/Spinner";

const ForwardUser = () => {
  const headers = useHeaders();
  const [forwardUserData, setForwardUserData] = useState({});
  const [selectedFromUser, setSelectedFromUser] = useState(null);
  const [selectedToUser, setSelectedToUser] = useState(null);
  const [assignUserOptions, setAssignUserOptions] = useState([]);
  const [selectedAssignUsers, setSelectedAssignUsers] = useState([]);
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const resetForm = () => {
    setSelectedFromUser(null);
    setSelectedToUser(null);
    setAssignUserOptions([]);
    setSelectedAssignUsers([]);
    setAlert(false);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_HOST_URL}/api/admin-assign-user-forward/`,
        headers
      )
      .then((res) => {
        setForwardUserData(res?.data);
      });
  }, []);

  useEffect(() => {
    if (selectedFromUser?.value) {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_HOST_URL}/api/vrm-agent-users-filtering-for-forwarding/?assign_member=${selectedFromUser?.value}`,
          headers
        )
        .then((res) => {
          setAssignUserOptions(res?.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, [selectedFromUser]);

  // handle From user Select Change
  const handleFromUserChange = (selectedOptions) => {
    setSelectedFromUser(selectedOptions);
    setSelectedAssignUsers([]);
    setAssignUserOptions([]);
    setAlert(true);
  };

  // Handle To User Select Form Change
  const handleToUserChange = (selectedOptions) => {
    setSelectedToUser(selectedOptions);
  };

  // Handle CheckBox
  const handleCheckboxChange = (user) => {
    const userIndex = selectedAssignUsers?.findIndex(
      (selectedUser) => selectedUser?.user === user?.user
    );

    if (userIndex === -1) {
      setSelectedAssignUsers([...selectedAssignUsers, user]);
      setAssignUserOptions(
        assignUserOptions?.filter(
          (assignUser) => assignUser?.user !== user?.user
        )
      );
    } else {
      const updatedSelectedUsers = [...selectedAssignUsers];
      updatedSelectedUsers?.splice(userIndex, 1);
      setSelectedAssignUsers(updatedSelectedUsers);
    }
  };

  // Handle Checkbox Button
  const handleUserClick = (user) => {
    const userIndex = selectedAssignUsers?.findIndex(
      (selectedUser) => selectedUser?.user === user?.user
    );

    if (userIndex === -1) {
      setSelectedAssignUsers([...selectedAssignUsers, user]);
      setAssignUserOptions(
        assignUserOptions?.filter(
          (assignUser) => assignUser?.user !== user?.user
        )
      );
    } else {
      const updatedSelectedUsers = [...selectedAssignUsers];
      updatedSelectedUsers?.splice(userIndex, 1);
      setSelectedAssignUsers(updatedSelectedUsers);
    }
  };

  // Handle Reverting User Back to Assign Options
  const revertUser = (user) => {
    setAssignUserOptions([...assignUserOptions, user]);
    setSelectedAssignUsers(
      selectedAssignUsers?.filter(
        (selectedUser) => selectedUser?.user !== user?.user
      )
    );
  };

  // Submit The Form
  const submitForwardUser = (e) => {
    e.preventDefault();
    const selectedUserIds = selectedAssignUsers?.map((user) => user?.user);
    const data = {
      from_user: selectedFromUser?.value,
      to_user: selectedToUser?.value,
      users: selectedUserIds,
    };
    if (selectedToUser?.value) {
      axios
        .post(
          `${process.env.REACT_APP_HOST_URL}/api/admin-assign-user-forward/`,
          data,
          headers
        )
        .then((resp) => {
          if (resp?.status === 200) {
            SuccessAlert("User Forwared Successful", "success");
            resetForm();
          }
        })
        .catch((error) => {
          SuccessAlert("User Forwared Faild", "error");
        });
    } else {
      SuccessAlert("To VRM User Field Empty", "error");
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 42,
      minHeight: 42,
    }),
  };

  return (
    <div className="h-[95vh] overflow-scroll mx-4">
      <div className="mt-4">
        <HeadingTitle title="Forward User" />
      </div>
      <div className="mt-5 border bg-white px-6 pt-5 pb-10 rounded-lg">
        <form onSubmit={submitForwardUser}>
          <div className="flex justify-between items-center gap-x-4">
            <div className="w-full">
              <label
                className="block uppercase mb-1 font-semibold text-gray-700"
                htmlFor="from_user"
              >
                From VRM User
              </label>
              <Select
                required
                name="from_user"
                onChange={handleFromUserChange}
                options={forwardUserData?.from_users?.map((data) => ({
                  value: data?.user_id,
                  label: data?.username,
                }))}
                value={selectedFromUser}
                placeholder="Select From VRM Admin User"
                className="w-full border-none text-gray-700 rounded mb-1 leading-tight"
                styles={customStyles}
              />
            </div>
            <div className="w-full">
              <label
                className="block uppercase mb-1 font-semibold text-gray-700"
                htmlFor="to_user"
              >
                To VRM User
              </label>
              <Select
                required
                name="to_user"
                onChange={handleToUserChange}
                options={forwardUserData?.to_users?.map((data) => ({
                  value: data?.user_id,
                  label: data?.username,
                }))}
                value={selectedToUser}
                placeholder="Select To VRM Admin User"
                className="w-full border-none text-gray-700 rounded mb-1 leading-tight"
                styles={customStyles}
              />
            </div>
          </div>
          <button
            type="submit"
            className="mt-3 bg-[#2563eb] border border-[#2563eb] hover:bg-transparent hover:text-[#2563eb] duration-300 px-8 py-2 rounded text-white"
          >
            Forward
          </button>
        </form>
        <div className="mt-8">
          <div className="flex justify-between items-start gap-x-5">
            <div className="w-full text-left">
              <h4 className="border border-sky-500 bg-sky-500 text-white py-2 px-3 text-lg text-center">
                Assign Users List
              </h4>
              {assignUserOptions?.length > 0 ? (
                <div>
                  {assignUserOptions?.map((user, index) => (
                    <div
                      key={index + 1}
                      className="border py-3 text-[16px] pl-5 flex items-center gap-x-3"
                    >
                      <input
                        type="checkbox"
                        className="cursor-pointer"
                        onChange={() => handleCheckboxChange(user)}
                        checked={selectedAssignUsers?.some(
                          (selectedUser) => selectedUser?.user === user?.user
                        )}
                      />
                      <button
                        onClick={() => handleUserClick(user)}
                        className="w-full flex justify-start"
                      >
                        {user?.username}
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                alert &&
                (isLoading ? (
                  <Spinner />
                ) : (
                  <p className="py-3 text-[16px] text-center">No User Found</p>
                ))
              )}
            </div>
            <div className="w-full text-left">
              <h4 className="border border-sky-500 bg-sky-500 text-white py-2 px-3 text-lg text-center">
                Select Assign User
              </h4>
              {selectedAssignUsers?.map((selectedUser, index) => (
                <button
                  onClick={() => revertUser(selectedUser)}
                  key={index + 1}
                  className="border py-3 text-[16px] pl-5 flex items-center gap-x-2 w-full"
                >
                  <VscChromeClose className="text-red-500 text-xl" />
                  <p>
                    {selectedUser?.username}
                  </p>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForwardUser;
