import React from 'react';

const NoDataFound = () => {
    return (
        <div>
             <p className="text-center text-2xl text-gray-400 py-8">No Data Found</p>
        </div>
    );
};

export default NoDataFound;