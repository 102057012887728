import axios from "axios";
import React, { useEffect, useState } from "react";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";

import LogPagination from "../../Shared/Pagination/LogPagination";
const VRMActivity = () => {
  const [logs, setLogs] = useState([]);
  const [count, setCount] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    const loadLoags = async () => {
      const res = await axios.get(`https://vrmapi.aamartaka.com/logs/`);
      if (res) {
        setIsLoading(false);
        setLogs(res?.data?.results);
        setCount(res?.data);
      } else {
        setIsLoading(false);
      }
    };
    loadLoags();
  }, []);

  return (
    <div className="h-screen m-3 p-3 overflow-scroll">
      <div className="mb-4">
        <HeadingTitle title="Activity Logs" />
      </div>
      {logs?.length > 0 ? (
        <>
          <table className="min-w-full overflow-scroll">
            <thead className=" bg-gray-700 text-white">
              <tr className="text-xs font-medium leading-4 tracking-wider text-center uppercase">
                <th className="w-5"></th>
                <th className="py-3 border-b border-gray-200">NO:</th>
                <th className="py-3 border-b border-gray-200">User</th>
                <th className="py-3 border-b border-gray-200">
                  Execution Time
                </th>
                <th className="py-3 border-b border-gray-200">API</th>
                <th className="py-3 border-b border-gray-200">IP Address</th>
                <th className="py-3 border-b border-gray-200">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {logs?.map((item, index) => (
                <tr
                  className="text-center border hover:bg-gray-100 duration-300"
                  key={index + 1}
                >
                  <td className="w-5"></td>
                  <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                    <p className="text-sm leading-5 text-gray-500">
                      {index + 1}
                    </p>
                  </td>

                  <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                    <p className="text-sm leading-5 text-gray-500">
                      {item?.user}
                    </p>
                  </td>
                  <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                    <p className="text-sm leading-5 text-gray-500">
                      {item?.execution_time}
                    </p>
                  </td>
                  <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                    <p className="text-sm leading-5 text-gray-500">
                      {item?.api.split("http://vrmapi.aamartaka.com")}
                    </p>
                  </td>

                  <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                    <p className="text-sm leading-5 text-gray-500">
                      {item?.client_ip_address}
                    </p>
                  </td>
                  <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                    <p className="text-sm leading-5 text-gray-500">
                      {item?.status_code}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className=" flex justify-center items-center mt-5">
            <LogPagination allData={logs} />
          </div>
        </>
      ) : (
        <div>
          {/* {isLoading ? (
            <Loader />
          ) : ( */}
            <div className="flex justify-center items-center">
              <h1 className=" text-2xl text-gray-300 my-4">No Data Found</h1>
            </div>
          {/* )} */}
        </div>
      )}
    </div>
  );
};

export default VRMActivity;
