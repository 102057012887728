import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const VRMChart = () => {
  const data = [
    {
      name: "JAN",
      Lead_Submit: 40,
      Lead_Accept: 24,
      amt: 24,
    },
    {
      name: "FEB",
      Lead_Submit: 30,
      Lead_Accept: 13,
      amt: 22,
    },
    {
      name: "MAR",
      Lead_Submit: 20,
      Lead_Accept: 9,
      amt: 22,
    },
    {
      name: "APR",
      Lead_Submit: 27,
      Lead_Accept: 3,
      amt: 20,
    },
    {
      name: "MAY",
      Lead_Submit: 18,
      Lead_Accept: 4,
      amt: 21,
    },
    {
      name: "JUN",
      Lead_Submit: 23,
      Lead_Accept: 3,
      amt: 25,
    },
    {
      name: "JUL",
      Lead_Submit: 34,
      Lead_Accept: 14,
      amt: 21,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Lead_Submit" fill="#8884d8" />
        <Bar dataKey="Lead_Accept" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default VRMChart;
