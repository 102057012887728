import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import useHeaders from "../../Shared/Header/Header";
import ReactQuill from "react-quill"; // Import react-quill
import "react-quill/dist/quill.snow.css"; // Import the styles for react-quill

const EditUserBenefit = () => {
  const { id } = useParams();
  const headers = useHeaders();
  const navigate = useNavigate();
  // Initialize the state with default values
  const [userBenefits, setUserBenefits] = useState({
    title: "",
    benefit_one: "",
    benefit_two: "",
    benefit_three: "",
  });

  // Use separate state variables for the Quill content
  const [title, setTitle] = useState("");
  const [benefitOne, setBenefitOne] = useState("");
  const [benefitTwo, setBenefitTwo] = useState("");
  const [benefitThree, setBenefitThree] = useState("");

  useEffect(() => {
    const loadData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/user_benefit/${id}`,
        headers
      );
      if (res?.status === 200) {
        setUserBenefits(res?.data);
        setBenefitOne(res?.data.benefit_one || "");
        setBenefitTwo(res?.data.benefit_two || "");
        setBenefitThree(res?.data.benefit_three || "");
      }
    };
    loadData();
  }, [id]);

  const handleBenefitOneChange = (value) => {
    setBenefitOne(value);
  };

  const handleBenefitTwoChange = (value) => {
    setBenefitTwo(value);
  };

  const handleBenefitThreeChange = (value) => {
    setBenefitThree(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      title: title || userBenefits.title,
      benefit_one: benefitOne,
      benefit_two: benefitTwo,
      benefit_three: benefitThree,
    };
    const res = await axios.patch(
      `${process.env.REACT_APP_HOST_URL}/api/user_benefit/${id}/`,
      data,
      headers
    );
    if (res?.status === 200) {
      SuccessAlert("Successfully Updated", "success");
      navigate(-1);
    } else SuccessAlert("Something Wrong", "error");
  };

  return (
    <div className="m-3 p-3 h-screen">
      <HeadingTitle title="Edit User Benefit" />
      <div className="my-4 bg-white px-4 py-3 rounded-md border">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="form-label inline-block text-lg text-gray-700">
              Benefit Title
            </label>
            <input
              type="text"
              defaultValue={userBenefits?.title}
              className="text-[#4B4B4B] form-control block w-full px-3 py-3 text-base font-normal bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Benefit Title"
              name="title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="form-label inline-block text-lg text-gray-700">
              Benefit One
            </label>

            <ReactQuill
              value={benefitOne}
              onChange={handleBenefitOneChange}
              className="quill-editor"
              modules={quillModules}
              style={{ color: "" }}
            />
          </div>
          <div className="mb-4">
            <label className="form-label inline-block text-lg text-gray-700">
              Benefit Two
            </label>

            <ReactQuill
              value={benefitTwo}
              onChange={handleBenefitTwoChange}
              className="quill-editor"
              modules={quillModules}
              style={{ color: "" }}
            />
          </div>
          <div className="mb-4">
            <label className="form-label inline-block text-lg text-gray-700">
              Benefit Three
            </label>

            <ReactQuill
              value={benefitThree}
              onChange={handleBenefitThreeChange}
              className="quill-editor"
              modules={quillModules}
              style={{ color: "" }}
            />
          </div>
          <div className="text-white flex justify-end">
            <input
              className="rounded bg-[#1E40AF] hover:bg-blue-500 my-2 px-5 mx-3 py-2 hover:cursor-pointer"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserBenefit;

// Configure Quill modules with an extensive toolbar
const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }, { direction: "ltr" }],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link"],
    ["clean"],
  ],

  clipboard: {
    matchVisual: false,
  },
};
