import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import SubmittedLeadTable from "./SubmittedLeadTable";
import usePermission from "./../../lib/Hook/UsePermission";

const SubmittedLead = () => {
  const { checkPermission } = usePermission();
  const [data, setData] = useState([]);
  const userData = localStorage.getItem("aamartaka");
  const { user_data, token } = JSON.parse(userData);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const loadLead = async () => {
      setIsLoading(true);
      const result = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/submited/leads/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (result) {
        setIsLoading(false);
        setData(result?.data?.results);
      } else {
        setIsLoading(false);
      }
    };
    setIsLoading(false);
    loadLead();
  }, [token, user_data.user]);

  return (
    <div className="h-screen m-3 p-3">
      <HeadingTitle title="Submitted Lead" />
      {checkPermission("view_virtualagentlead") && (
        <SubmittedLeadTable data={data} isLoading={isLoading} />
      )}
    </div>
  );
};

export default SubmittedLead;
