import React from "react";
import { Link } from "react-router-dom";
import {FaEye} from "react-icons/fa";
import Loader from "../../Shared/Loader/Loader";

const SubmittedLeadTable = ({ data, isLoading }) => {
  return (
      <div className="h-screen p-3">
        {data?.length > 0 ? (
          <div className="flex flex-col ">
            <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-md">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                        #
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                        Application Code
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                        Agent Name
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                        Name
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                        Mobile
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                        Status
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                        Application Type
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                        Created At
                      </th>
                      <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-700">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data?.map((item, index) => (
                      <tr key={index + 1}>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 w-3">
                          <div className="text-sm leading-5 text-gray-500">
                            {index + 1}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          <div className="flex items-center">
                            <div className="ml-4">
                              {item?.application_id ? (
                                <p className="text-sm font-medium leading-5 text-gray-900">
                                  {item?.application_id}
                                </p>
                              ) : (
                                <p className="text-sm font-normal leading-5 text-gray-500 text-center">
                                  Not Submitted Yet
                                </p>
                              )}
                            </div>
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          {item?.agent_name ? (
                            <p className="text-sm leading-5 text-gray-500">
                              {item?.agent_name}
                            </p>
                          ) : (
                            <p className="text-sm leading-5 text-gray-500">
                              N/A
                            </p>
                          )}
                        </td>

                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          {item?.name ? (
                            <p className="text-sm leading-5 text-gray-500">
                              {item?.name}
                            </p>
                          ) : (
                            <p className="text-sm leading-5 text-gray-500">
                              N/A
                            </p>
                          )}
                        </td>

                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          {item?.mobile_no ? (
                            <p className="text-sm leading-5 text-gray-500">
                              {item?.mobile_no}
                            </p>
                          ) : (
                            <p className="text-sm leading-5 text-gray-500">
                              N/A
                            </p>
                          )}
                        </td>

                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          {item?.bank_status ? (
                            <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                              {item?.bank_status}
                            </span>
                          ) : (
                            <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                              N/A
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          {item?.interested_product ? (
                            <p className="text-sm leading-5 text-gray-500">
                              {item?.interested_product}
                            </p>
                          ) : (
                            <p className="text-sm leading-5 text-gray-500">
                              N/A
                            </p>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          <p className="text-sm leading-5 text-gray-500">
                            {new Date(item?.created_at).toLocaleDateString()}
                          </p>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                          <button className="text-sm leading-5 text-blue-600 hover:text-black flex justify-around">
                            <Link to={`/slview/${item?.id}`}>
                              <FaEye className="text-xl duration-300"/>
                            </Link>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="flex justify-center items-center">
                <h1 className=" text-2xl text-gray-300 my-4">No Data Found</h1>
              </div>
            )}
          </div>
        )}
      </div>
  );
};

export default SubmittedLeadTable;
