import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useHeaders from "../../Shared/Header/Header";
import PaymentHistoryTable from "./PaymentHistoryTable";

const PaymentHistory = () => {
  const headers = useHeaders();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [name, setName] = useState({});
  const [isLoading,setIsLoading]=React.useState(false);

  useEffect(() => {
    setIsLoading(true)
    const loadData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/payment/?agent=${id}`,
        headers
      );
      if(res){
        setIsLoading(false)
      }
      const result = res?.data?.results;
      const singleUserData = result?.find((item) => item?.agent == id);
      setName(singleUserData);
      setData(res?.data?.results);
    };
    loadData();
  }, [id]);

  return (
    <div className="h-screen m-3 p-3">
      <PaymentHistoryTable name={name} data={data} isLoading={isLoading} />
    </div>
  );
};

export default PaymentHistory;
