import axios from "axios";
import React, { useEffect, useState } from "react";
import VRMStatusTable from "../VRMStatusTable/VRMStatusTable";
import Select from "react-select";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import { FaEnvelope, FaPhoneAlt, FaUser } from "react-icons/fa";
import useHeaders from "../../Shared/Header/Header";
import Loader from "../../Shared/Loader/Loader";

const Account = ({ data, changes, setChanges }) => {
  const headers = useHeaders();
  const cookies = localStorage.getItem("aamartaka");
  const { user_data } = JSON.parse(cookies);
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [statusHistory, setStatusHistory] = useState([]);
  const [personalDetails, setPersonalDetails] = useState({
    name: "",
    email: "",
    phone: "",
  });

  // Get Status Data
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_HOST_URL}/api/user_status/`, headers)
      .then((resp) => {
        setStatusHistory(resp?.data?.results);
      });
  }, [changes]);

  // All Status Options
  const statusData = [
    { label: "Active", value: 1 },
    { label: "Hold", value: 2 },
    { label: "Suspend", value: 3 },
  ];
  // All Event Handler
  const handlechange = (e) => {
    const { name, value } = e.target;
    const newData = { ...personalDetails };
    newData[name] = value;
    setPersonalDetails(newData);
  };

  // Post New Status
  const handleStatus = async (id) => {
    const StatusData = {
      created_by: user_data?.user,
      updated_by: user_data?.user,
      status: status?.value,
      user: data?.user,
      remarks: remarks,
    };

    axios
      .post(
        `${process.env.REACT_APP_HOST_URL}/api/user_status/`,
        StatusData,
        headers
      )
      .then((resp) => {
        SuccessAlert("Status Change Successful", "success");
        setChanges(changes + 1);
      })
      .catch((error) => {
        SuccessAlert(error?.message, "error");
      });
  };

  return (
    <div className="h-screen">
      {data ? (
        <div className="w-full lg:flex block justify-between items-start gap-x-4 py-4">
          <div className="lg:w-[40%] w-full">
            <div className="bg-white pb-4 border rounded pt-2 shadow-md">
              <div className="lg:px-4 px-2 my-3">
                <h4 className="text-[16px] flex items-center gap-x-2">
                  <FaUser className="text-[#2563eb] text-[15px]" />
                  <span className="text-gray-600">Name:</span>
                </h4>
                <h4 className="text-[17px] ml-6">
                  {data?.first_name + data?.last_name}
                </h4>
              </div>

              <div className="lg:px-4 px-2 my-3">
                <h4 className="text-[16px] flex items-center gap-x-2">
                  <FaEnvelope className="text-[#2563eb]" />
                  <span className="text-gray-600">Email:</span>
                </h4>
                <h4 className="text-[17px] ml-6">{data?.email}</h4>
              </div>

              <div className="lg:px-4 px-2 my-3">
                <h4 className="text-[16px] flex items-center gap-x-2">
                  <FaPhoneAlt className="text-[#2563eb] text-[15px]" />
                  <span className="text-gray-600">Phone:</span>
                </h4>
                <h4 className="text-[17px] ml-6">{data?.phone}</h4>
              </div>
            </div>
          </div>
          {/* personal Details  End */}

          {/* status Update  */}
          <div className="bg-white pb-4 border rounded pt-2 shadow-md lg:w-[60%] w-full">
            <div className="lg:flex md:flex block justify-start items-start rounded mx-auto lg:px-4 px-2 gap-x-4">
              <div className="w-full my-3">
                <label className="text-slate-900 text-[16px] block mb-1">
                  Status
                </label>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "0 !important",
                      height: "38px",
                      boxShadow: "0 !important",
                      "&:hover": {
                        border: "0 !important",
                      },
                    }),
                  }}
                  defaultValue={"in process"}
                  required
                  name="status"
                  onChange={setStatus}
                  options={statusData}
                  className="w-full border border-gray-200 rounded-md"
                />
              </div>
              <div className="w-full mt-2 mb-3">
                <label className="text-slate-900 text-[16px] block mb-1 mt-1">
                  Remarks
                </label>
                <textarea
                  className="border border-gray-200 w-full px-2 rounded focus:outline-sky-500"
                  type="text"
                  name="remarks"
                  onChange={(e) => setRemarks(e.target.value)}
                  placeholder="Remarks"
                />
              </div>
            </div>
            <button
              onClick={() => handleStatus(data?.id)}
              className="bg-[#2563eb] border border-[#2563eb] hover:bg-transparent hover:text-[#2563eb] duration-300 py-1 px-6 rounded text-white text-[16px] float-right mx-4"
            >
              Submit
            </button>
            <div className="mt-10 w-full">
              <VRMStatusTable statusHistory={statusHistory} />
            </div>
          </div>
        </div>
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
};

export default Account;
