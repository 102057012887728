import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import ChatEmptyPage from "./ChatEmptyPage";
import useHeaders from "../../Shared/Header/Header";
import ChatTabs from "./ChatTabs/ChatTabs";
import avater from "../../assets/demo-user.jpg";
import Swal from "sweetalert2";
import usePermission from "./../../lib/Hook/UsePermission";
import SendMessageForm from "./SendMessageForm";

const InboxChat = () => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const [chatUser, setChatUser] = useState([]);
  const [agent, setAgent] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [ws, setWs] = useState({});
  const [message, setMessage] = useState([]);
  const [user, setUser] = useState([]);
  const [newMessage, setNewMessage] = React.useState();
  const [newFile, setNewFile] = React.useState();
  const [updated, setUpdated] = React.useState(0);
  const [closeUser, setCloseUser] = React.useState();
  const [activeTab, setActiveTab] = React.useState(1);
  const [openUpdate, setOpenUpdate] = useState(0);
  const [closeUpdate, setCloseUpdate] = useState(0);
  const messageEndRef = useRef(null);

  // Handle Active & Close Tab
  const handleAciveTab = () => {
    setActiveTab(1);
    setOpenUpdate(openUpdate + 1);
    setSelectedUser(null);
  };

  const handleCloseTab = () => {
    setActiveTab(2);
    setCloseUpdate(closeUpdate + 2);
    setSelectedUser(null);
  };

  // Get Message For Specific User
  useEffect(() => {
    if (selectedUser?.id) {
      axios
        .get(
          `${process.env.REACT_APP_ADMIN_URL}/vrm-support-comments/?vrm_support=${selectedUser?.id}`,
          headers
        )
        .then((resp) => {
          setMessage(resp?.data?.results);
        });
    }
  }, [selectedUser?.id, openUpdate, closeUpdate,updated]);

  // Get Open User Message
  useEffect(() => {
    const fetchData = async () => {
      if (user?.user_data?.user) {
        try {
          const apiUrl = `${process.env.REACT_APP_ADMIN_URL}/vrm-supports`;
          let responseData;

          if (user?.user_data?.is_superuser) {
            responseData = await axios.get(`${apiUrl}?status=OPEN`, headers);
          } else {
            responseData = await axios.get(
              `${apiUrl}/?assign_user=${user?.user_data?.user}&status=OPEN`,
              headers
            );
          }

          setChatUser(responseData?.data?.results);
        } catch (error) {}
      }
    };
    fetchData();
  }, [user?.user_data, openUpdate]);

  // Get Closed User Message
  useEffect(() => {
    const fetchData = async () => {
      if (user?.user_data?.user) {
        try {
          const apiUrl = `${process.env.REACT_APP_ADMIN_URL}/vrm-supports`;
          let responseData;

          if (user?.user_data?.is_superuser) {
            responseData = await axios.get(`${apiUrl}?status=CLOSE`, headers);
          } else {
            responseData = await axios.get(
              `${apiUrl}/?assign_user=${user?.user_data?.user}&status=CLOSE`,
              headers
            );
          }

          setCloseUser(responseData?.data?.results);
        } catch (error) {}
      }
    };

    fetchData();
  }, [user?.user_data, closeUpdate]);

  useEffect(() => {
    const loadAgent = async () => {
      const data = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/agent/register/`,
        headers
      );
      setAgent(data?.data?.results);
    };

    const loadUserData = async () => {
      const userdata = await localStorage.getItem("aamartaka");
      setUser(JSON.parse(userdata));
    };
    loadUserData();

    loadAgent();
  }, []);

  const handleSelectUser = (item) => {
    const rest = agent?.find((i) => i?.user === item?.user_id);
    const wss = new WebSocket(
      `wss://vrmapi.aamartaka.com/ws/chat/${item?.session}/?token=${
        user && user?.token
      }`
    );

    wss.onopen = function (event) {
      const data = { command: "fetch_messages" };
      wss.send(JSON.stringify(data));
    };
    setWs(wss);
    setSelectedUser(item);
  };

  // Set Chat Details For Send Message
  const handleChat = (event) => {
    if (event.key === "Enter") {
      const data = {
        command: "new_message",
        message: event.target.value,
      };

      ws.send(JSON.stringify(data));
      event.target.value = "";
      const rest = message && [...message];
    }
  };

  // Handle Send Message
  const handleMessageSend = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("vrm_support", selectedUser?.id);
    formData.append("document", newFile || "");
    formData.append("description", newMessage);
    axios
      .post(
        `${process.env.REACT_APP_ADMIN_URL}/vrm-support-comments/?vrm_support=${selectedUser?.id}/`,
        formData,
        headers
      )
      .then((resp) => {
        e.target.reset();
        setUpdated(updated + 1);
      });
  };

  // End Message Session
  const handleEnd = () => {
    const updateData = { id: selectedUser?.id, status: "CLOSE" };
    axios
      .patch(
        `${process.env.REACT_APP_ADMIN_URL}/vrm-supports/${selectedUser?.id}/`,
        updateData,
        headers
      )
      .then((resp) => {
        if (resp?.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Session Closed",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
  };

  useEffect(() => {
    messageEndRef.current?.scrollIntoView();
  }, [message]);

  return (
    <div className="grid grid-cols-12 ">
      <div className="col-span-3">
        {checkPermission("view_vrmsupport") && (
          <div className="py-2 px-3">
            <div className="flex items-center">
              <ChatTabs
                handleSelectUser={handleSelectUser}
                chatUser={chatUser}
                closeUser={closeUser}
                handleAciveTab={handleAciveTab}
                handleCloseTab={handleCloseTab}
              />
            </div>
          </div>
        )}
      </div>

      {/* chat box  */}

      {checkPermission("view_vrmsupport") && (
        <>
          {selectedUser?.id ? (
            <div className="col-span-9 border-l border-gray-200 break-all">
              {/*Active Chat Header  */}
              {
                <div className="bg-[#F6FBFF] py-2 px-3 border-b border-gray-300 ">
                  <div className="flex items-center">
                    <img
                      className="rounded-full h-12 w-12 mx-2"
                      src={selectedUser?.user_image}
                      alt="User Profile"
                    />
                    <div className="flex items-center gap-x-2">
                      <h1 className="text-[18px]">
                        {selectedUser?.user_name}{" "}
                      </h1>
                      {selectedUser?.id && (
                        <div className="flex items-center gap-x-1">
                          (
                          <p className="text-md">
                            {selectedUser.support_topic_issue}
                          </p>
                          <p className="text-md">:</p>
                          <p className="text-md">
                            {selectedUser.support_topic}
                          </p>
                          )
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              }

              {/* Receive & Send Message For Active Chat */}
              {checkPermission("view_vrmsupportcomment") && (
                <div className="overflow-y-auto px-2 h-[75vh] bg-white">
                  <div
                    id="messages"
                    className="  flex flex-col space-y-4 p-3  scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
                  >
                    {message &&
                      activeTab === 1 &&
                      message?.map((item) => {
                        return (
                          <>
                            {user?.user_data &&
                            user?.user_data?.username === item?.user ? (
                              <div className="chat-message">
                                <div className="flex items-end justify-end">
                                  <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                                    <div>
                                      <span className="px-4 py-2 rounded-md inline-block rounded-br-none bg-blue-600 text-white ">
                                        {item?.description}
                                      </span>
                                    </div>
                                  </div>
                                  <img
                                    src={item?.user_image || avater}
                                    alt="My profile"
                                    className="w-6 h-6 rounded-full order-2"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="chat-message">
                                <div className="flex items-end">
                                  <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                                    <div>
                                      <span className="px-4 py-2 rounded-md inline-block rounded-bl-none bg-gray-300 text-gray-600">
                                        {item?.description}
                                      </span>
                                    </div>
                                  </div>
                                  <img
                                    src={selectedUser?.user_image || avater}
                                    alt="My profile"
                                    className="w-6 h-6 rounded-full order-1"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    <div ref={messageEndRef} />
                  </div>
                </div>
              )}
              {/* Receive & Send Message For Closed User */}
              {checkPermission("view_vrmsupportcomment") && (
                <div className="overflow-y-auto px-2 h-[75vh] bg-white">
                  <div
                    id="messages"
                    className="  flex flex-col space-y-4 p-3  scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
                  >
                    {message &&
                      activeTab === 2 &&
                      message?.map((item) => {
                        return (
                          <>
                            {user?.user_data &&
                            user?.user_data?.username === item?.user ? (
                              <div className="chat-message">
                                <div className="flex items-end justify-end">
                                  <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                                    <div>
                                      <span className="px-4 py-2 rounded-md inline-block rounded-br-none bg-blue-600 text-white ">
                                        {item?.description}
                                      </span>
                                    </div>
                                  </div>
                                  <img
                                    src={item?.user_image || avater}
                                    alt="My profile"
                                    className="w-6 h-6 rounded-full order-2"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="chat-message">
                                <div className="flex items-end">
                                  <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                                    <div>
                                      <span className="px-4 py-2 rounded-md inline-block rounded-bl-none bg-gray-300 text-gray-600">
                                        {item?.description}
                                      </span>
                                    </div>
                                  </div>
                                  <img
                                    src={selectedUser?.user_image || avater}
                                    alt="My profile"
                                    className="w-6 h-6 rounded-full order-1"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    <div ref={messageEndRef} />
                  </div>
                </div>
              )}

              {/* Chat Input Box  */}
              {activeTab === 1 && (
                <div>
                  {checkPermission("add_vrmsupportcomment") && (
                    <SendMessageForm
                      handleMessageSend={handleMessageSend}
                      setNewFile={setNewFile}
                      handleChat={handleChat}
                      setNewMessage={setNewMessage}
                      chatUser={chatUser}
                      handleEnd={handleEnd}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <ChatEmptyPage />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InboxChat;
