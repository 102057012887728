import React, { useState } from "react";
import { VscChromeClose } from "react-icons/vsc";
import axios from "axios";
import { SuccessAlert } from "./../../Shared/Alert/SuccessAlert";
import useHeaders from "./../../Shared/Header/Header";

const AddUserModal = ({ addUser, setAddUser,changes,setChanges }) => {
  const headers = useHeaders();
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");

  const handleUserSubmit = (e) => {
    e.preventDefault();
    const data = {
      first_name: name,
      username: userName,
    };
    axios
      .post(
        `${process.env.REACT_APP_HOST_URL}/api/vrm-agent-user-create/`,
        data,
        headers
      )
      .then((resp) => {
        if (resp?.data?.token) {
          SuccessAlert("User Create Successful", "success");
          setAddUser(false);
          setChanges(changes+1);
        } else {
          SuccessAlert("Failed to Create User", "error");
        }
      })
      .catch((error) => {
        SuccessAlert("User Already Exist", "error");
      });
  };

  return (
    <div>
      {addUser ? (
        <>
          <div className=" amin justify-center items-center flex inset-0 outline-none focus:outline-none overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
            <div className="relative h-auto w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between px-4 py-2 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold">Add New User</h3>
                  <button
                    className="p-1 text-2xl text-red-600"
                    onClick={() => setAddUser(false)}
                  >
                    <VscChromeClose />
                  </button>
                </div>
                {/*body*/}
                <div className="p-6">
                  <form onSubmit={handleUserSubmit}>
                    <div className="w-full">
                      <label>Name</label>
                      <input
                        required
                        type="text"
                        name="first_name"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Type Full Name"
                        className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[8px] px-3 rounded-[3px] border-[#CCCCCC]"
                      />
                    </div>
                    <div className="w-full mt-2">
                      <label>Username</label>
                      <input
                        required
                        type="number"
                        name="user_name"
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                        }}
                        maxLength="11"
                        onChange={(e) => setUserName(e.target.value)}
                        placeholder="01XXXXXXXXX"
                        className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[8px] px-3 rounded-[3px] border-[#CCCCCC]"
                      />
                    </div>
                    <div className="flex items-center justify-end gap-x-3 px-4 py-2">
                      <button
                        className="bg-[#2563eb] text-white text-sm px-6 py-2 border border-[#2563eb] rounded hover:bg-white hover:text-[#2563eb] transition-all duration-300 ease-in-out"
                        type="submit"
                      >
                        Confirm
                      </button>
                      <button
                        className="bg-red-500 text-white text-sm px-6 py-2 border border-red-500 rounded hover:bg-white hover:text-red-600 transition-all duration-300 ease-in-out"
                        type="button"
                        onClick={() => setAddUser(false)}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
                {/*footer*/}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default AddUserModal;
