import React from "react";
const VRMStatusTable = ({ statusHistory }) => {

  return (
    <div className="flex flex-col mt-2 mx-2">
      <div className="py-2 overflow-x-auto">
        <div className="inline-block min-w-full overflow-hidden align-middle border border-gray-200 sm:rounded-md">
          <table className="min-w-full overflow-scroll">
            <thead className=" bg-gray-700 text-white">
              <tr className="text-xs font-medium leading-4 tracking-wider text-center uppercase">
                <th className="py-3 border-b border-gray-200">Status</th>
                <th className="py-3 border-b border-gray-200">Status BY</th>
                <th className="py-3 border-b border-gray-200">Created At</th>
                <th className="py-3 border-b border-gray-200">Remarks</th>
              </tr>
            </thead>
            {statusHistory?.length > 0 ? (
              <>
                {statusHistory?.map((item, index) => (
                  <tbody className="bg-white" key={index + 1}>
                    <tr className="text-center border hover:bg-gray-100 duration-300">
                      <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                        <p
                          className={`${
                            (item?.status === 2 && "bg-yellow-500") ||
                            (item?.status === 1 && "bg-green-500") ||
                            (item?.status === 3 && "bg-red-500")
                          } ml-1 text-sm leading-5 text-white rounded-md`}
                        >
                          <h1 className={`py-1 px-1 rounded-sm `}>
                            {item?.status_name}
                          </h1>
                        </p>
                      </td>
                      <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500 font-semibold">
                          {item?.created_by_name}
                        </p>
                      </td>

                      <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.created_at}
                        </p>
                      </td>

                      <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.remarks}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </>
            ) : (
              <p className="py-3 text-center w-full text-xl text-gray-400">
                No Data Found
              </p>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default VRMStatusTable;
