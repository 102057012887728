import React from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loader from "../../Shared/Loader/Loader";
import usePermission from "./../../lib/Hook/UsePermission";

const PaymentStatusTable = ({ data, isLoading }) => {
  const { checkPermission } = usePermission();
  return (
    <div className="flex flex-col mt-4">
      <div className="py-2 overflow-x-auto">
        {data?.length > 0 ? (
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-md">
            <table className="min-w-full">
              <thead className="bg-gray-700 text-white">
                <tr className="text-xs font-medium leading-4 tracking-wider text-center uppercase">
                  <th className="py-3 border-b border-gray-200"># ID</th>
                  <th className="py-3 border-b border-gray-200">Agent Name</th>
                  <th className="py-3 border-b border-gray-200">
                    Total Earned
                  </th>
                  <th className="py-3 border-b border-gray-200">
                    Withdrawal Money
                  </th>
                  <th className="py-3 border-b border-gray-200">
                    Payment Method
                  </th>
                  <th className="py-3 border-b border-gray-200">Action</th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {data &&
                  data?.map((item, index) => (
                    <tr
                      className="text-center border hover:bg-gray-100 duration-300"
                      key={index + 1}
                    >
                      <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {index + 1}
                        </p>
                      </td>

                      <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.agent_name || "N/A"}
                        </p>
                      </td>
                      <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.request_method?.current_balance +
                            item?.request_method?.old_balance || "N/A"}
                        </p>
                      </td>

                      <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.amount}
                        </p>
                      </td>
                      <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                          {item?.request_method?.payment_method}
                        </span>
                      </td>
                      {checkPermission("view_payment") && (
                        <td className="py-3 whitespace-no-wrap border-b border-gray-200 flex justify-center items-center">
                          <Link
                            to={`/paymenthistory/${item?.agent}`}
                            className="mx-2"
                          >
                            <FaEye className="text-xl text-blue-600 hover:text-black duration-300" />
                          </Link>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="flex justify-center items-center">
                <h1 className=" text-2xl text-gray-300 my-4">No Data Found</h1>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentStatusTable;
