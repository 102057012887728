import React, { useEffect, useState } from "react";
import Select from "react-select";
import TextEditor from "./TextEditor";
import axios from "axios";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { useNavigate } from "react-router-dom";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import useHeaders from "../../Shared/Header/Header";
import useInstitute from "../../Shared/Hooks/UseInstitute";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const AddFeature = () => {
  const headers = useHeaders();
  const router = useNavigate();
  const [cardTypeData, setCardTypeData] = useState([]);
  const [loanTypeData, setLoanTypeData] = useState([]);
  const [bank, setBank] = useState("");
  const [productType, setProductType] = useState("");
  const [objectId, setObjectId] = useState(null);
  const [contentType, setContentType] = useState(0);
  const { institutes } = useInstitute(
    productType?.value === 1
      ? process.env.REACT_APP_CARD_CONTENT_TYPE
      : process.env.REACT_APP_LOAN_CONTENT_TYPE
  );
  const [feature, setFeature] = useState("");
  const [eligibility, setEligibility] = useState("");
  const [shortFeature, setShortFeature] = useState("");

  // Handle Bank Name
  const handleInstituteChange = (selectedOption) => {
    setBank(selectedOption);
  };
  const handleCardTypeChange = (selectedOption) => {
    setObjectId(selectedOption);
  };
  const handleLoanTypeChange = (selectedOption) => {
    setObjectId(selectedOption);
  };
  const productTypeOptions = [
    { value: 1, label: "Credit Card" },
    { value: 2, label: "Loan" },
  ];

  useEffect(() => {
    if (bank?.value) {
      // get Card Type Data
      if (productType?.value === 1) {
        axios
          .get(
            `${process.env.REACT_APP_HOST_URL}/api/commission-wise-unique-product-institutes/?content_type=132&institute_id=${bank?.value}`,
            headers
          )
          .then((res) => {
            setCardTypeData(res?.data);
            setContentType(132);
          });
      }
      if (productType?.value === 2) {
        // Get Loan Type Data
        axios
          .get(
            `${process.env.REACT_APP_HOST_URL}/api/commission-wise-unique-product-institutes/?content_type=119&institute_id=${bank?.value}`,
            headers
          )
          .then((res) => {
            setLoanTypeData(res?.data);
            setContentType(119);
          });
      }
    }
  }, [productType?.value, bank]);

  const { handleSubmit } = useForm();
  const onSubmit = () => {
    const data = {
      institute: bank?.value,
      product_type: productType?.value,
      content_type: contentType,
      object_id: objectId?.value,
      feature: feature,
      eligibility: eligibility,
      short_feature: shortFeature,
    };
    axios
      .post(`${process.env.REACT_APP_HOST_URL}/api/feature/`, data, headers)
      .then(function (response) {
        if (response?.status === 201) {
          Swal.fire({
            icon: "success",
            title: "New Feature Added",
            showConfirmButton: false,
            timer: 1500,
          });
          router("/feature");
        } else {
          Swal.fire({
            icon: "error",
            title: "Something Wrong",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch(function (error) {});
  };

  return (
    <div className="mx-4 pb-6">
      <div className="mt-4">
        <HeadingTitle title="Add New Feature" />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-4 border bg-white p-4 rounded-md"
      >
        <div className="lg:flex block items-center justify-between gap-x-4 z-50">
          <div className="w-full">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
              htmlFor="grid-first-name"
            >
              Product Type
            </label>
            <Select
              required
              name="product_type"
              onChange={setProductType}
              options={productTypeOptions?.map((product) => ({
                value: product?.value,
                label: product?.label,
              }))}
              className="w-full border-none text-gray-700 rounded mb-1 leading-tight h-full z-50"
            />
          </div>
          <div className="w-full z-50">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
              htmlFor="grid-first-name"
            >
              Bank Name
            </label>
            <Select
              required
              name="bank_name"
              options={institutes?.map((institute) => ({
                value: institute?.id,
                label: institute?.name,
              }))}
              onChange={handleInstituteChange}
              className="w-full border-none text-gray-700 rounded mb-1 leading-tight h-full z-50"
            />
          </div>
          {productType?.value === 1 && (
            <div className="w-full">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
                htmlFor="grid-first-name"
              >
                Card Type
              </label>
              <Select
                required
                name="card_type"
                onChange={handleCardTypeChange}
                options={cardTypeData?.map((card) => ({
                  value: card?.id,
                  label: card?.name,
                }))}
                className="w-full border-none text-gray-700 rounded mb-1 leading-tight h-full z-50"
              />
            </div>
          )}
          {productType?.value === 2 && (
            <div className="w-full">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 "
                htmlFor="grid-first-name"
              >
                Loan Type
              </label>
              <Select
                required
                name="loan_type"
                onChange={handleLoanTypeChange}
                options={loanTypeData?.map((loan) => ({
                  value: loan?.id,
                  label: loan?.name,
                }))}
                className="w-full border-none text-gray-700 rounded mb-1 leading-tight h-full z-50"
              />
            </div>
          )}
        </div>
        <div>
          <h1 className="uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
            FEATURE
          </h1>
          <ReactQuill
            className="quill-editor text-left"
            modules={quillModules}
            theme="snow"
            placeholder="Type Feature"
            onChange={setFeature}
            style={{ color: "" }}
            required
          />
        </div>
        <div>
          <h1 className=" uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
            ELIGIBILITY
          </h1>
          <ReactQuill
            className="quill-editor text-left"
            modules={quillModules}
            theme="snow"
            placeholder="Type Eligibility"
            onChange={setEligibility}
            style={{ color: "" }}
            required
          />
        </div>
        <div>
          <h1 className=" uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
            SHORT FEATURE
          </h1>
          <ReactQuill
            className="quill-editor text-left"
            modules={quillModules}
            theme="snow"
            placeholder="Type Short Feature"
            onChange={setShortFeature}
            style={{ color: "" }}
            required
          />
        </div>
        <button
          className="mt-3 mb-1 bg-[#1E40AF] w-24 py-2 px-6 rounded-md border border-[#1E40AF] text-white -tracking-tighter hover:bg-transparent hover:text-[#1E40AF] duration-300"
          type="submit"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default AddFeature;

// / Configure Quill modules with an extensive toolbar
const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }, { direction: "ltr" }],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link"],
    ["clean"],
  ],

  clipboard: {
    matchVisual: false,
  },
};
