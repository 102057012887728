import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import useHeaders from "../../../Shared/Header/Header";
import PaymentConfirmationForm from "./PaymentConfirmationForm";
import {VscChromeClose} from "react-icons/vsc";

const PaymentModal = ({ id, showModal, setShowModal }) => {
  const headers = useHeaders();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const loadData = async () => {
     if(id){
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/payment/${id}`,
        headers
      );
      setData(res?.data);
     }
    };
    loadData();
  }, [id]);

  return (
    <div>
      {showModal ? (
        <>
          <div className="amin justify-center items-center flex inset-0 outline-none focus:outline-none overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
            <div className="relative h-auto w-full my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="ml-3 text-xl font-semibold tracking-[1px]">
                    Payment Confirmation
                  </h3>
                  <button
                    className="p-1 ml-auto border-0 text-red-600 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      setShowModal(false);
                      setStatus(null);
                    }}
                  >
                    <VscChromeClose/>
                  </button>
                </div>
                {/*body*/}
                <div className="p-6 flex flex-col">
                  {/* main contenst  */}
                  <PaymentConfirmationForm
                    id={id}
                    setStatus={setStatus}
                    status={status}
                    data={data}
                    setShowModal={setShowModal}
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default PaymentModal;
