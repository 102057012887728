import React, { useState } from "react";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import useHeaders from "../../Shared/Header/Header";

const NewVRM = () => {
  const router = useNavigate();
  const headers = useHeaders();
  const [user, setUser] = useState({
    username: "",
    phone: "",
    email: "",
    first_name: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    await axios
      .post(
        `${process.env.REACT_APP_HOST_URL}/api/agent/register/`,
        user,
        headers
      )
      .then((response) => {
        if (response.status === 201) {
          SuccessAlert("User Created", "success");
          router("/vrmlist");
        }
      })
      .catch((error) => {
        SuccessAlert(error.message, "error");
      });
  };

  const handleBlur = (e) => {
    const newData = { ...user };
    newData[e.target.name] = e.target.value;
    setUser(newData);
  };

  return (
    <div className="h-[87vh] mx-4">
      <div className="mt-3">
        <HeadingTitle title="Add New VRM" />
      </div>
      <div className="border px-4 pt-4 lg:pb-0 pb-2 bg-white rounded-md lg:w-2/3 w-full mx-auto lg:mt-6 mt-3">
        <form onSubmit={handleSubmit}>
          <h4 className="text-lg mb-3 text-[#1E40AF]">Add New VRM</h4>
          <div className="lg:flex md:flex block items-center gap-x-4 mb-3">
            <div className="w-full md:w-1/2 mb-2">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Username
              </label>
              <input
                className="appearance-none block w-full text-gray-700 border border-[#B3B3B3] rounded py-2 px-4 leading-tight focus:outline-sky-500 focus:bg-white"
                id="grid-first-name"
                type="number"
                name="username"
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                }}
                maxLength="11"
                placeholder="01X0000000"
                required
                onBlur={handleBlur}
              />
            </div>
            <div className="w-full md:w-1/2 mb-2">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Email
              </label>
              <input
                className="ppearance-none block w-full text-gray-700 border border-[#B3B3B3] rounded py-2 px-4 leading-tight focus:outline-sky-500 focus:bg-white"
                id="grid-last-name"
                type="email"
                name="email"
                placeholder="email"
                required
                onBlur={handleBlur}
              />
            </div>
          </div>

          <div className="lg:flex md:flex block items-center gap-x-4 mb-3">
            <div className="w-full md:w-1/2 mb-2">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Name
              </label>
              <input
                className="ppearance-none block w-full text-gray-700 border border-[#B3B3B3] rounded py-2 px-4 leading-tight focus:outline-sky-500 focus:bg-white"
                id="grid-first-name"
                type="text"
                name="first_name"
                required
                onBlur={handleBlur}
                placeholder="Name"
              />
            </div>
            <div className="w-full md:w-1/2 mb-2">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Phone
              </label>
              <input
                className="ppearance-none block w-full text-gray-700 border border-[#B3B3B3] rounded py-2 px-4 leading-tight focus:outline-sky-500 focus:bg-white"
                id="grid-first-name"
                type="number"
                name="phone"
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                }}
                maxLength="11"
                required
                onBlur={handleBlur}
                placeholder="01X0000000"
              />
            </div>
          </div>
          <button
            className="bg-[#1E40AF] border border-[#1E40AF] hover:bg-transparent hover:text-[#1E40AF] duration-300 py-2 px-4 float-left rounded-md w-24 text-white text-md mt-4"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewVRM;
