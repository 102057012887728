import React, { useState, useEffect } from "react";
import HeadingTitle from "./../../Shared/HeadingTitle/HeadingTitle";
import PolicyTable from "./PolicyTable";
import axios from "axios";
import useHeaders from "./../../Shared/Header/Header";
import AddPolicyModal from "./AddPolicyModal";
import usePermission from "./../../lib/Hook/UsePermission";

const NewPolicy = () => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const [policy, setPolicy] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [changes, setChanges] = useState(0);

  useEffect(() => {
    setisLoading(true);
    axios
      .get(`${process.env.REACT_APP_HOST_URL}/api/new_policy/`, headers)
      .then((resp) => {
        setPolicy(resp?.data?.results);
        setisLoading(false);
      });
  }, [changes]);

  return (
    <div>
      <div className="min-h-screen ">
        <div className="my-2 mx-3">
          <div className="flex justify-between items-center mt-2 mb-1">
            <HeadingTitle title="New Policy" />
            {checkPermission("add_newpolicy") && (
              <button
                onClick={() => setShowModal(true)}
                className="bg-[#1E40AF] rounded-md text-white font-bold py-2 px-4 border border-[#1E40AF] hover:bg-transparent hover:text-[#1E40AF] duration-300 cursor-pointer"
              >
                Add New Policy
              </button>
            )}
          </div>
          {checkPermission("view_newpolicy") && (
            <PolicyTable
              policy={policy}
              isLoading={isLoading}
              changes={changes}
              setChanges={setChanges}
            />
          )}
        </div>
        {showModal && (
          <AddPolicyModal
            showModal={showModal}
            setShowModal={setShowModal}
            changes={changes}
            setChanges={setChanges}
          />
        )}
      </div>
    </div>
  );
};

export default NewPolicy;
