import React from "react";

const ChatEmptyPage = () => {
  return (
    <div className="rounded-md flex justify-center items-center lg:w-[60vw] md:w-[50vw] w-[50vw] border-l border-l-gray-200 min-h-screen">
      <div className="w-full h-screen bg-white flex justify-center">
        <img
          src="/chat.png"
          alt="EmployeeImage"
          className="w-1/3 h-1/2"
        />
      </div>
    </div>
  );
};

export default ChatEmptyPage;
