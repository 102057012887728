import axios from "axios";
import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { MdOutlineFileDownloadDone } from "react-icons/md";
import useHeaders from "../../Shared/Header/Header";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const AddBenefitModal = ({ setShowModal, changes, setChanges }) => {
  const headers = useHeaders();
  const [title, setTitle] = useState("");
  const [benefit_one, setBenefit_one] = useState("");
  const [benefit_two, setBenefit_two] = useState("");
  const [benefit_three, setBenefit_three] = useState("");

  const handleBenefitSubmit = (event) => {
    event.preventDefault();
    const data = {
      title,
      benefit_one,
      benefit_two,
      benefit_three,
    };

    axios
      .post(
        `${process.env.REACT_APP_HOST_URL}/api/user_benefit/`,
        data,
        headers
      )
      .then((response) => {
        if (response.status === 201) {
          SuccessAlert("Benefit Added Successfully", "success");
          setShowModal(false);
          setChanges(changes + 1);
        }
      })
      .catch((error) => {
        SuccessAlert(error?.message, "error");
      });
  };

  return (
    <div>
      <div className="fixed z-10 overflow-y-auto top-0 w-full h-full left-0">
        <div className="flex items-center justify-center px-4 text-center min-h-screen">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>

          <div className="bg-white rounded-md text-left shadow-xl transform my-8 2xl:w-[60%] xl:w-[70%] w-[80%] px-4">
            <div className="pt-5 px-2 pb-3">
              <h4 className="text-2xl font-semibold text-[#2563eb]">
                Add User Benefit
              </h4>
            </div>
            <div className="px-3 rounded bg-white text-center border-[#2563eb] mx-auto text-gray-700 h-full pb-5">
              <form onSubmit={handleBenefitSubmit}>
                <div className="w-full mb-2">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2 text-start mb-1"
                    htmlFor="title"
                  >
                    Benefit Title
                  </label>
                  <input
                    className="w-full mb-2 border border-gray-300 rounded py-2.5 px-2 focus:bg-gray-50 focus:outline-[#2563eb]"
                    type="text"
                    name="title"
                    placeholder="Enter Benefit Title"
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2 text-start mb-1"
                    htmlFor="benefit_one"
                  >
                    Benefit One
                  </label>
                  <ReactQuill
                    className="quill-editor text-left"
                    modules={quillModules}
                    theme="snow"
                    value={benefit_one}
                    onChange={setBenefit_one}
                    placeholder="Enter Benefit One Text"
                    style={{ color: "" }}
                    required
                  />
                </div>
                <div className="w-full mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2 text-start mb-1"
                    htmlFor="benefit_two"
                  >
                    Benefit Two
                  </label>
                  <ReactQuill
                    className="quill-editor text-left"
                    modules={quillModules}
                    theme="snow"
                    value={benefit_two}
                    onChange={setBenefit_two}
                    placeholder="Enter Benefit Two Text"
                    style={{ color: "" }}
                    required
                  />
                </div>
                <div className="w-full">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2 text-start mb-1"
                    htmlFor="benefit_three"
                  >
                    Benefit Three
                  </label>
                  <ReactQuill
                    className="quill-editor text-left"
                    modules={quillModules}
                    theme="snow"
                    value={benefit_three}
                    placeholder="Enter Benefit Three Text"
                    onChange={setBenefit_three}
                    style={{ color: "" }}
                    required
                  />
                </div>

                <div className="py-5 float-right">
                  <div className="flex items-center gap-x-4">
                    <button
                      type="submit"
                      className="py-1.5 px-3 text-white bg-[#2563eb] hover:text-[#2563eb] hover:bg-white border border-[#2563eb] rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                    >
                      <MdOutlineFileDownloadDone className="text-xl" />
                      <p> Submit</p>
                    </button>
                    <button
                      onClick={() => setShowModal(false)}
                      type="reset"
                      className="py-1.5 px-4 text-white bg-red-500 hover:text-red-600 hover:bg-white border border-red-600 rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                    >
                      <FaTimes />
                      <p> Cancel</p>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBenefitModal;

// Configure Quill modules with an extensive toolbar
const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }, { direction: "ltr" }],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link"],
    ["clean"],
  ],

  clipboard: {
    matchVisual: false,
  },
};
