import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import useHeaders from "../../Shared/Header/Header";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import PaymentStatusTable from "./PaymentStatusTable";
import usePermission from "./../../lib/Hook/UsePermission";

const PaymentStatus = () => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/payment/?status=Confirm`,
        headers
      );
      if (res) {
        setIsLoading(false);
        setData(res?.data?.results);
      } else {
        setIsLoading(false);
      }
    };
    loadData();
  }, []);

  return (
    <div className="h-[87vh] mx-4">
      <div className="mt-3">
        <HeadingTitle title="Payment Details" />
      </div>
      {checkPermission("view_payment") && (
        <PaymentStatusTable data={data} isLoading={isLoading} />
      )}
    </div>
  );
};

export default PaymentStatus;
