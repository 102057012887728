import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import useHeaders from "../../Shared/Header/Header";
import Loader from "./../../Shared/Loader/Loader";
import { RiAddLine } from "react-icons/ri";
import EditSpecialCommission from "../ManageSpecialCommission/EditSpecialCommission";
import NoDataFound from "../../Shared/NoDataFound/NoDataFound";
import AddSpecialCommission from "../ManageSpecialCommission/AddSpecialCommission";
import usePermission from "./../../lib/Hook/UsePermission";
const api = `${process.env.REACT_APP_HOST_URL}/api/agent_card_commission/`;

const SpecialCardCommission = () => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const [pathType, setPathType] = React.useState("");
  const [specialCommissions, setSpecialCommissions] = useState([]);
  const [showAddCommission, setShowAddCommission] = React.useState(false);
  const [showEditCommission, setShowEditCommission] = React.useState(false);
  const [id, setId] = React.useState(false);
  const [updated, setUpdated] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleAddCommission = () => {
    setShowAddCommission(true);
    setPathType("agent_card_commission");
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_HOST_URL}/api/agent_card_commission/`,
        headers
      )
      .then((res) => {
        setSpecialCommissions(res?.data?.results);
        setIsLoading(false);
      });
  }, [updated]);

  const editAlert = (id) => {
    setShowEditCommission(true);
    setId(id);
    setPathType("agent_card_commission");
  };

  const deleteAlert = (api, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${api}${id}/`, headers).then((res) => {
          if (res.status === 204) {
            const rest = specialCommissions?.filter((item) => item?.id !== id);
            setSpecialCommissions(rest);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        });
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="h-[87vh] mx-4">
      <div className="flex justify-between items-center mt-5">
        <div>
          <HeadingTitle title="Special Card Commission List" />
        </div>
        {checkPermission("add_agentcommission") && (
          <div>
            <button
              onClick={handleAddCommission}
              className="text-white  bg-[#2563eb] px-3 py-2 border border-[#2563eb] rounded-[4px] hover:bg-white hover:text-[#2563eb] transition duration-300 ease-in-out flex items-center gap-x-1"
            >
              <RiAddLine className="text-2xl" />
              Add Special Card Commission
            </button>
          </div>
        )}
      </div>
      {checkPermission("view_agentcommission") && (
        <div className="flex flex-col mt-8">
          {specialCommissions?.length > 0 ? (
            <div className="py-2 overflow-x-auto">
              <div className="inline-block min-w-full overflow-hidden align-middle sm:rounded-md">
                <table className="min-w-full overflow-scroll">
                  <thead className=" bg-gray-700 text-white ">
                    <tr className="text-xs font-medium leading-4 tracking-wider text-center uppercase">
                      <th className="py-3 pl-4">#</th>
                      <th className="py-3">Agent Name</th>
                      <th className="py-3">Institute Name</th>
                      <th className="py-3">Product</th>
                      <th className="py-3">Commission</th>
                      <th className="py-3">Expire Date</th>
                      <th className="py-3">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {specialCommissions &&
                      specialCommissions?.map((item, index) => (
                        <tr
                          className="text-center border hover:bg-gray-100 duration-300 bg-white py-4 my-1.5 tableShadow"
                          key={index + 1}
                        >
                          <td>
                            <p className="text-sm leading-5 text-gray-500 bg-white py-4 my-1.5 tableShadow pl-4">
                              {index + 1}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm leading-5 text-gray-500 bg-white py-4 my-1.5 tableShadow">
                              {item?.agent_name || "N/A"}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm leading-5 text-gray-500 bg-white py-4 my-1.5 tableShadow">
                              {item?.bank_name || "N/A"}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm leading-5 text-gray-500 bg-white py-4 my-1.5 tableShadow">
                              {item?.product || "N/A"}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm leading-5 text-gray-500 bg-white py-4 my-1.5 tableShadow">
                              {item?.commission || "N/A"}
                            </p>
                          </td>
                          <td>
                            <p className="text-sm leading-5 text-gray-500 bg-white py-4 my-1.5 tableShadow">
                              {item?.expire_date
                                ? formatDate(item.expire_date)
                                : "N/A"}
                            </p>
                          </td>
                          <td>
                            <div className="flex justify-center items-center bg-white py-2 my-1.5 tableShadow">
                              {checkPermission("change_agentcommission") && (
                                <button
                                  onClick={() => editAlert(item?.id)}
                                  className="mx-1"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-9 h-9 bg-blue-100 border border-blue-100 hover:bg-transparent text-blue-500 hover:text-blue-700 duration-500 p-2 rounded-full"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                    />
                                  </svg>
                                </button>
                              )}
                              {checkPermission("delete_agentcommission") && (
                                <button
                                  onClick={() => deleteAlert(api, item?.id)}
                                  className="mx-2"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-9 h-9 text-red-500 hover:text-red-700 duration-500 bg-red-100 border border-red-100 p-2 rounded-full hover:bg-transparent"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <>{isLoading ? <Loader /> : <NoDataFound />}</>
          )}
        </div>
      )}
      {showAddCommission && (
        <AddSpecialCommission
          setAddCommission={setShowAddCommission}
          updated={updated}
          setUpdated={setUpdated}
          pathType={pathType}
        />
      )}
      {showEditCommission && (
        <EditSpecialCommission
          setEditCommission={setShowEditCommission}
          updated={updated}
          setUpdated={setUpdated}
          pathType={pathType}
          id={id}
        />
      )}
    </div>
  );
};

export default SpecialCardCommission;
