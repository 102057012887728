import React from "react";
import { Navigate, useLocation } from "react-router";
import { useSelector } from "react-redux";

import Loader from "../../../../Shared/Loader/Loader";
const PrivateRoute = ({ children }) => {
  // const user = localStorage.getItem("aamartaka");
  const user = useSelector((state) => state.userSlice.user);
  const isLoading = useSelector((state) => state.userSlice.isLoaDing);
  // const token = JSON.parse(user);

  const location = useLocation();
  // useEffect(() => {
  //   if (token) {
  //     setIsLoading(false);
  //   }
  // }, [token]);
  // if (isLoading) {
  //   return <h1>Loading</h1>;
  // }
  if (isLoading) {
    return <Loader />;
  }

  if (user?.token) {
    return children;
  } else return <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;
