import React, { useState } from "react";
import Loader from "../../Shared/Loader/Loader";
import PaymentModal from "../PaymentStatus/PaymentModal/PaymentModal";
import { FaCheck } from "react-icons/fa";
import usePermission from "./../../lib/Hook/UsePermission";

const PaymentRequestTable = ({ data, isLoading }) => {
  const { checkPermission } = usePermission();
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(null);

  const handleRequestMoney = (id) => {
    setId(id);
  };

  return (
    <div className="flex flex-col mt-4">
      <div className="py-2 overflow-x-auto">
        {data?.length > 0 ? (
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-md">
            <table className="min-w-full overflow-scroll">
              <thead className=" bg-gray-700 text-white">
                <tr className="text-xs font-medium leading-4 tracking-wider text-center uppercase">
                  <th className="py-3 border-b border-gray-200"># ID</th>
                  <th className="py-3 border-b border-gray-200">Agent Name</th>
                  <th className="py-3 border-b border-gray-200">
                    Requested Amount
                  </th>
                  <th className="py-3 border-b border-gray-200">
                    Total Earned
                  </th>
                  <th className="py-3 border-b border-gray-200">Status</th>
                  <th className="py-3 border-b border-gray-200">Action</th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {data &&
                  data?.map((item, index) => (
                    <tr
                      className="text-center border hover:bg-gray-100 duration-300"
                      key={index + 1}
                    >
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {index + 1}
                        </p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.full_name || "N/A"}
                        </p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {item?.amount || "N/A"}
                        </p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500">
                          {"N/A"}
                        </p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-sm leading-5 text-black">
                          <span className="bg-yellow-100 px-2 py-2 rounded-full">
                            {" "}
                            {item?.status || "N/A"}
                          </span>
                        </p>
                      </td>
                      {checkPermission("change_payment") && (
                        <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                          <div className="text-sm leading-5 text-gray-500">
                            <button
                              onClick={() => {
                                handleRequestMoney(item?.id);
                                setShowModal(true);
                              }}
                              className="rounded-md text-white px-2 py-1.5 bg-[#2563eb] border border-[#2563eb] hover:bg-white hover:text-[#2563eb] flex justify-center items-center gap-x-1 duration-300 mx-auto font-semibold"
                            >
                              <FaCheck />
                              Confirm
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="flex justify-center items-center">
                <h1 className=" text-2xl text-gray-300 my-4">No Data Found</h1>
              </div>
            )}
          </div>
        )}
      </div>
      <PaymentModal id={id} showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default PaymentRequestTable;
