import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  value: 0,
  status: "",
  user: [],
  commission: [],
  addMoreData: [],
  isLoaDing: true,
  commissionData: [],
  inputList: [{ product_type: "", from: 0, commission: 0, to: 0 }],
  cardtype: [],
  cardList: [],
  sidebar: false,
  cardCommission: [],
  newCardCommission: [],
  companyType: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    cardCommission: (state, action) => {
      state.cardCommission = action.payload;
    },
    newCardCommission: (state, action) => {
      const { e, index } = action.payload;
      const { name, value } = e.target;
      const list = [...state.cardCommission];
      state.cardCommission[index][name] = value;
      state.cardCommission = list;
    },
    addCardCommission: (state, action) => {
      state.cardCommission = [
        ...state.cardCommission,
        { product_type: "", from: 0, commission: 0, to: 0 },
      ];
    },
    removeCardCommission: (state, action) => {
      const { index } = action.payload;
      const list = [...state.cardCommission];
      list.splice(index, 1);
      state.cardCommission = list;
    },
    specialCommission: (state, action) => {
      state.commission = action.payload;
    },
    addMoreFn: (state, action) => {
      state.addMoreData = [...state.addMoreData, action.payload];
    },
    saveUser: (state, action) => {
      const user = localStorage.getItem("aamartaka");
      state.isLoaDing = user?.isLoading;
      // state.user = action.payload;
      if (action.payload) {
        state.user = JSON.parse(user);
        state.isLoaDing = true;
      }
      if (user) {
        state.isLoaDing = user?.isLoading;
        state.user = JSON.parse(user);
      } else state.user = [];

      // const user = JSON.parse(localStorage.getItem("aamartaka"));
      // state.user = action.payload;
    },
    isLoaDing: (state, action) => {
      state.isLoaDing = action.payload;
    },
    inputChange: (state, action) => {
      const { name, value, index } = action.payload;

      const list = [...state.inputList];
      if (name !== "product_type") {
        list[index][name] = parseInt(value);
      } else {
        list[index][name] = value;
      }
      state.inputList = list;
    },

    AddClick: (state, action) => {
      state.inputList = [
        ...state.inputList,
        { product_type: "", from: 0, commission: 0, to: 0 },
      ];
    },
    RemoveClick: (state, action) => {
      const { index } = action.payload;
      const list = [...state.inputList];
      list.splice(index, 1);
      state.inputList = list;
    },

    addMoreFuncton: (state, action) => {
      // state.commissionData = action.payload;
    },
    cardList: (state, action) => {
      state.cardList = action.payload;
    },

    cardType: (state, action) => {
      const token = state.user.token;
      let config = {
        headers: {
          Authorization: `token ${token}`,
        },
      };
      const id = action.payload;

      const loadData = async () => {
        const data = await axios.get(
          `${process.env.REACT_APP_ADMIN_URL}/api/v1/credit_card/?institute=${id}`,
          config
        );
        state.cardtype = data?.data?.results;
      };
      loadData();
    },
    handleSideBar: (state, action) => {
      state.sidebar = !state.sidebar;
    },

    handleStatusType: (state, action) => {
      state.status = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  // findSubmitedLead,
  // addSubmitedLead,
  handleStatusType,
  cardCommission,
  newCardCommission,
  addCardCommission,
  removeCardCommission,
  handleSideBar,
  RemoveClick,
  AddClick,
  inputChange,
  isLoaDing,
  saveUser,
  specialCommission,
  addMoreFn,
  addMoreFuncton,
  cardType,
  cardList,
} = userSlice.actions;

export default userSlice.reducer;
