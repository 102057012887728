import React from "react";
import { FaTimes } from "react-icons/fa";
import MiniLoader from "./../../Shared/Loader/MiniLoader";

const ViewPolicyModal = ({ policyData, setViewModal, loading }) => {
  return (
    <div>
      <div className="fixed z-10 overflow-y-auto top-0 w-full left-0">
        <div className="flex items-center justify-center h-screen px-4 text-center">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div className="bg-white rounded-md text-left overflow-hidden shadow-xl transform my-8 2xl:w-[60%] xl:w-[65%] w-[60%] px-4">
            <div className="pt-5 px-2">
              <h4 className="text-2xl font-semibold text-[#2563eb]">
                Policy Details
              </h4>
            </div>
            <div className="px-3 rounded bg-white text-center border-[#2563eb] mx-auto text-gray-700 h-full pb-5">
              {!loading ? (
                <div className="my-4">
                  <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-md">
                    <table className="min-w-full overflow-scroll">
                      <thead className=" bg-gray-700 text-white">
                        <tr className="text-xs font-medium leading-4 tracking-wider uppercase">
                          <th className="py-3.5 border-b border-gray-200 whitespace-nowrap "></th>
                          <th className="py-3.5 px-2 border-b border-gray-200 whitespace-nowrap">
                            Bank Name
                          </th>
                          <th className="py-3.5 px-2 border border-gray-200 whitespace-nowrap">
                            Product Name
                          </th>
                          <th className="py-3.5 px-2 border border-gray-200 whitespace-nowrap">
                            Subject
                          </th>
                          <th className="py-3.5 px-2 border border-gray-200">
                            Remarks
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        <tr className="text-center border hover:bg-gray-100 duration-300">
                          <td className="py-3 whitespace-no-wrap border-b border-gray-200"></td>
                          <td className="py-3 whitespace-no-wrap border px-2 border-gray-200">
                            <p className="text-sm leading-5 text-gray-500 whitespace-nowrap">
                              {policyData?.bank_name}
                            </p>
                          </td>
                          <td className="py-3 whitespace-no-wrap border px-2 border-gray-200">
                            <p className="text-sm leading-5 text-gray-500 whitespace-nowrap">
                              {policyData?.product_name}
                            </p>
                          </td>

                          <td className="py-3 whitespace-no-wrap border px-2 border-gray-200">
                            <p className="text-sm leading-5 text-gray-500 px-2 whitespace-nowrap">
                              {policyData?.subject}
                            </p>
                          </td>
                          <td className="py-3 whitespace-no-wrap border px-2 border-gray-200">
                            <p className="text-sm leading-5 text-gray-500 text-justify px-2">
                              {policyData?.remarks}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <MiniLoader />
              )}
              <div className="flex justify-end items-center">
                <button
                  onClick={() => setViewModal(false)}
                  type="reset"
                  className="py-1.5 px-4 text-white bg-red-500 hover:text-red-600 hover:bg-white border border-red-600 rounded-md flex justify-center items-center gap-x-2 transition duration-300 ease-in-out"
                >
                  <FaTimes />
                  <p> Cancel</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPolicyModal;
