import React from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import Loader from "../../Shared/Loader/Loader";
import usePermission from "./../../lib/Hook/UsePermission";

const FeatureTable = ({ data, deleteAlert, isLoading }) => {
  const { checkPermission } = usePermission();
  const api = `${process.env.REACT_APP_HOST_URL}/api/feature/`;
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <div className="flex flex-col mt-4">
      <div className="py-2 overflow-x-auto">
        {data?.length > 0 ? (
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-md">
            <table className="min-w-full overflow-scroll">
              <thead className=" bg-gray-700 text-white">
                <tr className="text-xs font-medium leading-4 tracking-wider text-center uppercase">
                  <th className="py-3 border-b border-gray-200 whitespace-nowrap px-3">ID</th>
                  <th className="py-3 border-b border-gray-200 whitespace-nowrap px-3">Institute</th>
                  <th className="py-3 border-b border-gray-200 whitespace-nowrap px-3">
                    Product Type
                  </th>
                  <th className="py-3 border-b border-gray-200 whitespace-nowrap px-3">Product</th>
                  <th className="py-3 border-b border-gray-200 whitespace-nowrap px-3">Feature</th>
                  <th className="py-3 border-b border-gray-200 whitespace-nowrap px-3">Eligibility</th>
                  <th className="py-3 border-b border-gray-200 whitespace-nowrap px-3">
                    Short Feature
                  </th>
                  <th className="py-3 border-b border-gray-200 whitespace-nowrap px-3">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data &&
                  data?.map((item, index) => (
                    <tr
                      className="text-center border hover:bg-gray-100 duration-300"
                      key={index + 1}
                    >
                      <td className="py-2 border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500 px-3 whitespace-no-wrap">
                          {index + 1}
                        </p>
                      </td>

                      <td className="py-2 border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500 whitespace-nowrap px-3">
                          {item?.institute_name || "N/A"}
                        </p>
                      </td>
                      <td className="py-2 border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500 whitespace-nowrap px-3">
                          {item?.product_type || "N/A"}
                        </p>
                      </td>
                      <td className="py-2 border-b border-gray-200">
                        <p className="text-sm leading-5 text-gray-500 whitespace-nowrap px-3">
                          {item?.product || "N/A"}
                        </p>
                      </td>
                      <td className="py-2 border-b border-gray-200">
                        <p
                          className="text-sm leading-5 text-gray-500 whitespace-nowrap px-3"
                          dangerouslySetInnerHTML={createMarkup(
                            item?.feature?.length > 30
                              ? item?.feature.slice(0, 30) + "..."
                              : item?.feature
                          )}
                        ></p>
                      </td>
                      <td className="py-2 border-b border-gray-200">
                        <p
                          className="text-sm leading-5 text-gray-500 whitespace-nowrap px-3"
                          dangerouslySetInnerHTML={createMarkup(
                            item?.eligibility?.length > 30
                              ? item?.eligibility.slice(0, 30) + "..."
                              : item?.eligibility
                          )}
                        ></p>
                      </td>

                      <td className="py-2 border-b border-gray-200">
                        <p
                          className="text-sm leading-5 text-gray-500 whitespace-nowrap px-3"
                          dangerouslySetInnerHTML={createMarkup(
                            item?.short_feature?.length >30 ? item?.short_feature.slice(0, 30) + "..." : item?.short_feature
                          )}
                        ></p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <div className="flex justify-center items-center px-3">
                          {checkPermission("view_feature") && (
                            <Link to={`/viewfeature/${item?.id}`}>
                              <FaEye className="text-xl text-blue-600 hover:text-black duration-300" />
                            </Link>
                          )}
                          {checkPermission("change_feature") && (
                            <Link
                              to={`/editfeature/${item?.id}`}
                              className="mx-1"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-blue-500 hover:text-blue-700 duration-300"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                />
                              </svg>
                            </Link>
                          )}
                          {checkPermission("delete_feature") && (
                            <button onClick={() => deleteAlert(api, item?.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-red-500 hover:text-red-700 duration-300"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : isLoading ? (
          <Loader />
        ) : (
          <p className="text-xl text-gray-400 py-10 text-center">
            No Data Found
          </p>
        )}
      </div>
    </div>
  );
};

export default FeatureTable;
