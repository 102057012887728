import React from "react";
import { useDispatch } from "react-redux";
import { saveUser } from "../../Redux/Slices/userSlice";
import { FaCog, FaSignInAlt, FaUnlock, FaUser } from "react-icons/fa";

const Menu = () => {
  const cookies = localStorage.getItem("aamartaka");
  const { user_data } = JSON.parse(cookies);
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleLogout = () => {
    localStorage.removeItem("aamartaka");

    dispatch(saveUser());
    // router("/");
  };

  return (
    <div className=" ">
      <div className="flex flex-wrap ">
        <div className="w-full">
          <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-[#F1F5F9] rounded-full">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
              <div className="w-full  flex justify-between  px-4 static ">
                <button
                  className="text-md text-[#1B399D] font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
                >
                  Hello! <span className="text-black"> {user_data?.first_name + " " + user_data?.last_name} </span>
                </button>

                <ul className="flex justify-between items-center">
                  <li className="nav-item">
                    <div
                      className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black"
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      <FaCog className="text-[#2563eb] text-lg leading-lg cursor-pointer"/>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {menuOpen && (
        <div className="bg-[#1E40AF] w-60 float-right absolute top-20 right-10 text-white p-3 rounded-md mr-2 z-50">
          <h1 className=" font-bold text-sm">
            {user_data &&
              (user_data?.first_name + " " + user_data?.last_name ||
                user_data?.username)}
          </h1>
          <h1>{user_data && user_data?.email}</h1>
          <hr className="border-dashed my-3 border-gray-400" />
          <div className="flex items-center cursor-pointer duration-300 hover:text-gray-300 pl-5 rounded-md">
            <FaUser className="text-md leading-lg"/>
            <h1 className="my-2 font-medium text-sm mx-2">Profile</h1>
          </div>
          <div className="flex items-center cursor-pointer duration-300 hover:text-gray-300 pl-5 rounded-md">
            <FaUnlock className="text-md leading-lg"/>
            <h1 className="my-2 font-medium text-sm mx-2">Reset Password</h1>
          </div>
          <hr className="border-dashed my-2 border-gray-400" />
          <button
            onClick={handleLogout}
            className="transition duration-300 ease-in-out hover:bg-red-400 flex items-center w-full px-2 rounded pl-6"
          >
            <FaSignInAlt className="text-md leading-lg"/>
            <h1 className="my-2 font-medium text-sm mx-4">Logout</h1>
          </button>
        </div>
      )}
    </div>
  );
};

export default Menu;
