import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeatureTable from "./FeatureTable";
import Swal from "sweetalert2";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import useHeaders from "../../Shared/Header/Header";
import usePermission from "./../../lib/Hook/UsePermission";

const Feature = () => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    const loadData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/feature/`,
        headers
      );
      if (response?.status === 200) {
        setIsLoading(false);
        setData(response?.data?.results);
      } else {
        setIsLoading(false);
      }
    };
    loadData();
  }, []);

  const deleteAlert = (api, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${api}${id}/`, headers).then((res) => {
          if (res.status === 204) {
            const rest = data.filter((item) => item.id !== id);
            setData(rest);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        });
      }
    });
  };
  return (
    <div className="h-[87vh] mx-4">
      <div className="flex justify-between items-center mt-2 mb-1">
        <HeadingTitle title="Feature List" />
        {checkPermission("add_feature") && (
          <div className="bg-[#1E40AF] rounded-md text-white font-bold py-2 px-4 border border-[#1E40AF] hover:bg-transparent hover:text-[#1E40AF] duration-300 cursor-pointer">
            <Link to="/addfeature">
              <span>Add New Feature</span>
            </Link>
          </div>
        )}
      </div>
      {checkPermission("view_feature") && (
        <FeatureTable
          data={data}
          deleteAlert={deleteAlert}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default Feature;
