import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import HeadingTitle from "../../../Shared/HeadingTitle/HeadingTitle";
import useHeaders from "../../../Shared/Header/Header";
import CreatableSelect from "react-select/creatable";
import { SuccessAlert } from "../../../Shared/Alert/SuccessAlert";
import Loader from "../../../Shared/Loader/Loader";

const EditCardLead = () => {
  const navigate = useNavigate();
  const headers = useHeaders();
  const { id } = useParams();
  const contentType = process.env.REACT_APP_CARD_CONTENT_TYPE;
  const [leadData, setLeadData] = React.useState();
  const [institutes, setInstitutes] = useState([]);
  const [cardTypeOptions, setCardTypeOptions] = useState([]);
  const [companyNameOptions, setcompanyNameOptions] = useState([]);
  const [profession, setProfession] = useState("");
  const [salaryType, setSalaryType] = useState("");
  const [selectedInstitute, setSelectedInstitute] = useState("");
  const [objectId, setObjectId] = React.useState();
  const [selectedCompany, setSelectedCompany] = React.useState();
  const [selectedCompanyType, setSelectedCompanyType] = useState(null);
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [mobileNo, setMobileNo] = React.useState(leadData?.mobile_no);
  const [searchText, setSearchText] = useState("");
  const [selectedOptionData, setSelectedOptionData] = useState({
    name: leadData?.name,
    yearly_transaction: leadData?.yearly_transaction,
    rental_income: leadData?.rental_income,
    remarks: leadData?.remarks,
  });

  // All Event Handler
  const handleChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newData = { ...selectedOptionData };
    newData[field] = value;
    setSelectedOptionData(newData);
  };

  const handleInstituteChange = (selectedInstitute) => {
    setSelectedInstitute(selectedInstitute);
  };

  const handleCardTypeChange = (selectedOption) => {
    setObjectId(selectedOption);
  };

  const handleCompanyChange = (selectedOption) => {
    setSelectedCompany(selectedOption);
    if (selectedOption && selectedOption.type) {
      setSelectedCompanyType({
        value: selectedOption.type,
        label: selectedOption.type,
      });
    } else {
      setSelectedCompanyType(null);
    }
  };

  useEffect(() => {
    // Get All Institutes
    axios
      .get(
        `${process.env.REACT_APP_HOST_URL}/api/commission-wise-unique-institutes/?content_type=${contentType}`,
        headers
      )
      .then((res) => {
        setInstitutes(res?.data);
      });

    // Get All Company Data
    axios
      .get(`${process.env.REACT_APP_ADMIN_URL}/company`, headers)
      .then((resp) => {
        setcompanyNameOptions(resp?.data?.results);
      });
  }, []);


 // Get Company Data By Search
 useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN_URL}/company/?search=${searchText}`,
        headers
      );
      setcompanyNameOptions(response?.data?.results);
    } catch (error) {}
  };
  fetchData();
}, [searchText]);

const handleInputChange = (newValue) => {
  setSearchText(newValue);
};


  // Get All Default Data For Input
  useEffect(() => {
    fetch(`${process.env.REACT_APP_HOST_URL}/api/card_lead/${id}/`, headers)
      .then((response) => response.json())
      .then((res) => {
        setLeadData(res);
        setMobileNo(res?.mobile_no)
      });
  }, [id, setLeadData]);

  // Get Card Type Options Data
  useEffect(() => {
    if (selectedInstitute?.value) {
      axios
        .get(
          `${process.env.REACT_APP_HOST_URL}/api/commission-wise-unique-product-institutes/?institute_id=${selectedInstitute?.value}&content_type=${contentType}`,
          headers
        )
        .then((res) => {
          setCardTypeOptions(res?.data);
        });
    }
  }, [selectedInstitute?.value]);

  // Get Profession Data
  const professionData = [
    { value: "salaried", label: "Salaried" },
    { value: "business", label: "Business" },
    { value: "landloard", label: "Landloard" },
    { value: "professional", label: "Professional" },
  ];

  // Get Salary Type Data
  const salaryTypeData = [
    { value: "bank salary", label: "Bank Salary" },
    { value: "hand cash", label: "Hand Cash" },
    { value: "both", label: "Hand Cash + Bank Salary" },
  ];

  // Get Status Data
  const statusData = [
    { label: "New", value: "New" },
    { label: "CNI", value: "CNI" },
    { label: "Non Eligible", value: "Non Eligible" },
    { label: "Follow", value: "Follow" },
  ];

  // Submit The Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ...selectedOptionData,
      institute: selectedInstitute?.value || leadData?.institute,
      profession: profession?.value || leadData?.profession,
      salary_type: salaryType?.value || leadData?.salary_type,
      object_id: objectId?.value || leadData?.object_id,
      company: selectedCompany?.value || leadData?.company,
      company_type: selectedCompany?.type || leadData?.company_type,
      status: status?.value || leadData?.status,
      user: leadData?.user,
      content_type: contentType,
      remarks: remarks || leadData?.remarks,
      mobile_no: mobileNo || leadData?.mobile_no,
      product: objectId?.label || leadData?.product,
    };
if(mobileNo?.length ===11){
  axios
    .put(
      `${process.env.REACT_APP_HOST_URL}/api/card_lead/${id}/`,
      data,
      headers
    )
    .then((resp) => {
      SuccessAlert("Updated Successful", "success");
      navigate("/cardLead");
    })
    .catch((error) => {
      SuccessAlert(error?.message, "error");
    });
}else{
  SuccessAlert("Invalid Phone Number", "error");
}
  };

  return (
    <div className="h-screen mx-4">
      <div className="flex items-center gap-x-2 mt-2 mb-5">
        <HeadingTitle title="Edit Card Lead" />
      </div>
      {leadData ? (
        <div className="lg:w-4/5 md:w-4/5 w-full mx-auto bg-white p-6 rounded-md shadow-md">
          <h4 className="text-[#1E40AF] font-semibold text-lg mb-4">
            Edit Card Lead
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="lg:grid block grid-cols-3 place-items-center place-content-between w-full gap-x-4">
              <div className="w-full">
                <label> Institute Name </label>
                <Select
                  isSearchable={true}
                  defaultInputValue={leadData?.institute_name}
                  onChange={handleInstituteChange}
                  name="interested_bank"
                  options={institutes?.map((institute) => ({
                    value: institute?.id,
                    label: institute?.name,
                  }))}
                  placeholder="Select Institute Name"
                  classNamePrefix="select"
                  className="mt-1 mb-2.5"
                />
              </div>
              <div className="w-full">
                <label> Card Type </label>
                <Select
                  isSearchable={true}
                  onChange={handleCardTypeChange}
                  defaultInputValue={leadData?.product}
                  name="interested_product"
                  options={cardTypeOptions?.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                  }))}
                  placeholder="Select Card Type"
                  classNamePrefix="select"
                  className="mt-1 mb-2.5"
                />
              </div>
              <div className="w-full">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  defaultValue={leadData?.name}
                  placeholder="Your Name"
                  className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                />
              </div>
              <div className="w-full">
                <label> Phone</label>
                <input
                  type="text"
                  name="mobile_no"
                  defaultValue={leadData?.mobile_no}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  maxLength="11"
                  onChange={(e) => setMobileNo(e.target?.value)}
                  placeholder="Your Phone Number"
                  className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                />
              </div>
              <div className="w-full">
                <label> Profession</label>
                <Select
                  isSearchable={false}
                  name="profession"
                  onChange={(selectedOption) => setProfession(selectedOption)}
                  defaultValue={professionData.find(
                    (option) => option.value === leadData?.profession
                  )}
                  options={professionData}
                  placeholder="Your Profession"
                  className="mt-1 mb-2.5"
                />
              </div>

              {profession?.value === "salaried" && (
                <div className="w-full">
                  <label> Company Name</label>
                  <CreatableSelect
                    isSearchable={true}
                    name="company_name"
                    options={companyNameOptions?.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                      type: item?.company_type_name,
                    }))}
                    placeholder="Select Company Name"
                    onChange={handleCompanyChange}
                    onInputChange={handleInputChange}
                    defaultInputValue={leadData?.company_name}
                    className="mt-1 mb-2.5"
                  />
                </div>
              )}
              {profession?.value === "business" && (
                <div className="w-full">
                  <label> Create or Select Company Name</label>
                  <CreatableSelect
                    isSearchable={true}
                    name="company_name"
                    options={companyNameOptions?.map((item) => ({
                      value: item?.id,
                      label: item?.name,
                      type: item?.company_type_name,
                    }))}
                    placeholder="Select Company Name"
                    onChange={handleCompanyChange}
                    onInputChange={handleInputChange}
                    defaultInputValue={leadData?.company_name}
                    className="mt-1 mb-2.5"
                  />
                </div>
              )}
              {profession?.value === "business" && selectedCompany && (
                <div className="w-full">
                  <label> Company Type</label>
                  <CreatableSelect
                    isDisabled={selectedCompany}
                    value={selectedCompanyType}
                    name="companyType"
                    placeholder="Select Company Type"
                    className="mt-1 mb-2.5"
                  />
                </div>
              )}
              {profession?.value !== "business" && selectedCompany && (
                <div className="w-full">
                  <label> Company Type</label>
                  <CreatableSelect
                    isDisabled={selectedCompany}
                    value={selectedCompanyType}
                    required
                    name="companyType"
                    placeholder="Select Company Type"
                    className="mt-1 mb-2.5"
                  />
                </div>
              )}
              {profession?.value === "salaried" && (
                <div className="w-full">
                  <label> Salary Type</label>
                  <Select
                    name="salary_type"
                    onChange={setSalaryType}
                    options={salaryTypeData}
                    placeholder="Salary Type"
                    className="mt-1 mb-2.5"
                  />
                </div>
              )}

              {(profession?.value === "professional" ||
                profession?.value === "business") && (
                <div className="w-full">
                  <label>Yearly Transaction</label>
                  <input
                    type="number"
                    name="yearly_transaction"
                    max="100000000"
                    min="1000"
                    onChange={handleChange}
                    defaultValue={leadData?.yearly_transaction}
                    placeholder="ex: 45000"
                    className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                  />
                </div>
              )}

              {profession?.value === "landloard" && (
                <div className="w-full">
                  <label>Rental Income</label>
                  <input
                    type="number"
                    name="rental_income"
                    max="1000000000"
                    min="1000"
                    onChange={handleChange}
                    defaultInputValue={leadData?.rental_income}
                    placeholder="ex: 45000"
                    className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                  />
                </div>
              )}

              {salaryType?.value === "bank salary" && (
                <div className="w-full">
                  <label>Bank Salary Amount</label>
                  <input
                    type="number"
                    name="salary_ac_amount"
                    max="100000000"
                    min="1000"
                    onChange={handleChange}
                    defaultValue={leadData?.salary_ac_amount}
                    placeholder="ex: 45000"
                    className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                  />
                </div>
              )}

              {salaryType?.value === "hand cash" && (
                <div className="w-full">
                  <label>Hand Cash Salary Amount</label>
                  <input
                    type="number"
                    name="hand_cash_amount"
                    max="100000000"
                    min="1000"
                    onChange={handleChange}
                    defaultValue={leadData?.hand_cash_amount}
                    placeholder="ex: 45000"
                    className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                  />
                </div>
              )}

              {salaryType?.value === "both" && (
                <div className="w-full">
                  <label>Hand Cash Salary Amount</label>
                  <input
                    type="number"
                    name="hand_cash_amount"
                    max="100000000"
                    min="1000"
                    onChange={handleChange}
                    defaultValue={leadData?.hand_cash_amount}
                    placeholder="ex: 45000"
                    className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                  />
                </div>
              )}
              {salaryType?.value === "both" && (
                <div className="w-full">
                  <label>Bank Salary Amount</label>
                  <input
                    type="number"
                    name="salary_ac_amount"
                    max="100000000"
                    min="1000"
                    onChange={handleChange}
                    defaultValue={leadData?.salary_ac_amount}
                    placeholder="ex: 45000"
                    className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                  />
                </div>
              )}

              <div className="w-full">
                <label>Status</label>
                <Select
                  name="status"
                  onChange={setStatus}
                  options={statusData}
                  defaultInputValue={leadData?.status}
                  className="mt-1 mb-2.5"
                />
              </div>
              <div className="w-full">
                <label>Remarks</label>
                <input
                  type="text"
                  name="remarks"
                  onChange={(e) => setRemarks(e.target.value)}
                  placeholder="Your Remarks Here"
                  defaultValue={leadData?.remarks}
                  className="mt-1 mb-2.5 focus:outline-[#2684FF] focus:duration-400 font-exo w-full border py-[7px] px-3 rounded-[3px] border-[#CCCCCC]"
                />
              </div>
            </div>

            <button
              className="w-32 mt-4 mx-auto text-white bg-[#1E40AF] border border-[#1E40AF] hover:bg-transparent hover:text-[#1E40AF] text-md px-5 py-2 rounded-md font-exo duration-300"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default EditCardLead;
