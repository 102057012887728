import React, { useState, useEffect } from "react";
import Loader from "../../Shared/Loader/Loader";
import { FaEye } from "react-icons/fa";
import EditPolicy from "./EditPolicy";
import axios from "axios";
import useHeaders from "./../../Shared/Header/Header";
import ViewPolicyModal from "./ViewPolicyModal";
import Swal from "sweetalert2";
import usePermission from "./../../lib/Hook/UsePermission";

const PolicyTable = ({ policy, isLoading, changes, setChanges }) => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const [policyData, setPolicyData] = useState();
  const [viewModal, setViewModal] = useState(false);
  const [id, setId] = useState();
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleView = (item) => {
    setId(item);
    setViewModal(true);
  };

  const handleEdit = (item) => {
    setId(item);
    setEditModal(true);
  };

  //   Find Single Data By ID
  useEffect(() => {
    setLoading(true);
    if (id) {
      axios
        .get(`${process.env.REACT_APP_HOST_URL}/api/new_policy/${id}`, headers)
        .then((resp) => {
          setPolicyData(resp?.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [id, viewModal, editModal]);

  //   Delete Function Here
  const deleteAlert = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed && id) {
        axios
          .delete(
            `${process.env.REACT_APP_HOST_URL}/api/new_policy/${id}`,
            headers
          )
          .then((res) => {
            if (res.status === 204) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              setChanges(changes + 1);
            }
          });
      }
    });
  };

  return (
    <div className="my-4">
      <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-md">
        {policy?.length > 0 ? (
          <table className="min-w-full overflow-scroll">
            <thead className=" bg-gray-700 text-white">
              <tr className="text-xs font-medium leading-4 tracking-wider text-center uppercase">
                <th className="w-5"></th>
                <th className="py-3.5 border-b border-gray-200">ID</th>
                <th className="py-3.5 border-b border-gray-200 whitespace-nowrap text-center">
                  Bank Name
                </th>
                <th className="py-3.5 border-b border-gray-200 whitespace-nowrap">
                  Product Name
                </th>
                <th className="py-3.5 border-b border-gray-200 whitespace-nowrap text-center">
                  Subject
                </th>
                <th className="py-3.5 border-b border-gray-200 whitespace-nowrap">
                  Remarks
                </th>
                <th className="py-3.5 border-b border-gray-200 whitespace-nowrap">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {policy &&
                policy?.map((item, index) => (
                  <tr
                    className="text-center border hover:bg-gray-100 duration-300"
                    key={index + 1}
                  >
                    <td className="w-5"></td>
                    <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                      <p className="text-sm leading-5 text-gray-500 whitespace-nowrap">
                        {index + 1}
                      </p>
                    </td>
                    <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                      <p className="text-sm leading-5 text-gray-500 whitespace-nowrap center pl-4">
                        {item?.bank_name || "N/A"}
                      </p>
                    </td>

                    <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                      <p className="text-sm leading-5 text-gray-500 px-2 whitespace-nowrap text-center">
                        {item?.product_name || "N/A"}
                      </p>
                    </td>
                    <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                      <p className="text-sm leading-5 text-gray-500  px-2 whitespace-nowrap text-center">
                        {item?.subject || "N/A"}
                      </p>
                    </td>
                    <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                      <p className="text-sm leading-5 text-gray-500 text-justify px-4">
                        {item?.remarks?.slice(0, 105) + "..." || "N/A"}
                      </p>
                    </td>

                    <td className="py-3 whitespace-no-wrap border-b border-gray-200">
                      <div className="flex justify-center items-center gap-x-2">
                        {checkPermission("view_newpolicy") && (
                          <button onClick={() => handleView(item?.id)}>
                            <FaEye className="text-xl text-blue-600 hover:text-black duration-300" />
                          </button>
                        )}
                        {checkPermission("change_newpolicy") && (
                          <button
                            onClick={() => handleEdit(item?.id)}
                            className="mx-1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-blue-500 hover:text-blue-700 duration-300"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </button>
                        )}
                        {checkPermission("delete_newpolicy") && (
                          <button onClick={() => deleteAlert(item?.id)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-red-500 hover:text-red-700 duration-300"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="flex justify-center items-center">
                <h1 className=" text-2xl text-gray-300 my-4">No Data Found</h1>
              </div>
            )}
          </div>
        )}
      </div>
      {editModal && (
        <EditPolicy
          setEditModal={setEditModal}
          policyData={policyData}
          loading={loading}
          setChanges={setChanges}
          changes={changes}
          id={id}
        />
      )}
      {viewModal && (
        <ViewPolicyModal
          setViewModal={setViewModal}
          policyData={policyData}
          loading={loading}
        />
      )}
    </div>
  );
};

export default PolicyTable;
