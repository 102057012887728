import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { bcbdAdmin } from "../API/APIQuery";
import userSlice from "../Slices/userSlice";
export const store = configureStore({
  reducer: {
    [bcbdAdmin.reducerPath]: bcbdAdmin.reducer,
    userSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(bcbdAdmin.middleware),
});
setupListeners(store.dispatch);
