import React, { useEffect, useState } from "react";
import axios from "axios";
import { SuccessAlert } from "../../Shared/Alert/SuccessAlert";
import { useNavigate, useParams } from "react-router-dom";
import useHeaders from "../../Shared/Header/Header";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import Loader from "../../Shared/Loader/Loader";
const EditTeam = () => {
  const headers = useHeaders();
  const { id } = useParams();
  const router = useNavigate();

  const [team, setTeam] = useState("");
  const [singleTeam, setSingleTeam] = useState([]);
  const [singlePermission, setSinglePermission] = useState([]);
  const [content_type, setContent_type] = useState([]);
  const [description, setDescription] = useState("");

  const handleChange = (e) => {
    const { name, checked, value } = e.target;
    if (checked) {
      setSingleTeam([...singleTeam, parseInt(name)]);
    }
    if (!checked) {
      const restData = singleTeam.filter((item) => item !== parseInt(name));

      setSingleTeam(restData);
    }};

  useEffect(() => {
    const loadPermission = async () => {
      const content_type = await fetch(
        `${process.env.REACT_APP_HOST_URL}/accounts/content_type/`,
        headers
      );
      const rest = await content_type.json();
      setContent_type(rest);
    };
    const loadSingleData = async () => {
      const content_type = await fetch(
        `${process.env.REACT_APP_HOST_URL}/api/team/${id}`,
        headers
      );
      const rest = await content_type.json();
      setSingleTeam(rest?.permissions);
      setSinglePermission(rest);
    };

    loadSingleData();
    loadPermission();
  }, [id]);

  const handleName = (e) => {
    setTeam(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmitFile = async (event) => {
    event.preventDefault();

    const data = {
      name: team || singlePermission?.name,
      permissions: singleTeam,
      description: description || singlePermission?.name,
    };

    axios
      .patch(`${process.env.REACT_APP_HOST_URL}/api/team/${id}/`, data, headers)
      .then((response) => {
        if (response.status === 200) {
          SuccessAlert("Team Permission Updated", "success");
          router(-1);
        }
      })
      .catch((error) => {
        SuccessAlert(error.message, "error");
      });
  };

  return (
    <div className="h-screen overflow-scroll mx-4">
      <div className="mt-4">
        <HeadingTitle title="Edit Team" />
      </div>
      {content_type?.length > 0 ?
        <form onSubmit={handleSubmitFile} className="border bg-white px-4 py-3 rounded-md mt-2">
          <div className="mb-4">
            <label className=" mb-1 text-[15px] block font-medium"> Team Name</label>
            <input
              defaultValue={singlePermission?.name}
              required
              className="w-full px-4 py-2.5 rounded-md focus:outline-[0.5px] focus:outline-sky-500 border"
              type="text"
              name="name"
              id=""
              placeholder="Name"
              onChange={handleName}
            />
          </div>
          <p className="text-[15px] mb-1 font-medium">
            Permission
          </p>
          <div className="hidden md:grid grid-cols-5 place-content-center place-items-center py-2 text-[15px] uppercase bg-gray-700 text-white rounded-t-md text-center">
            <h4>Model</h4>
            <h4>View</h4>
            <h4>Add</h4>
            <h4>Change</h4>
            <h4>Delete</h4>
          </div>
          <div className="border border-gray-200">
            {content_type &&
              content_type?.map((item,index) => (
                <div className="grid grid-cols-1 gap-4 md:grid-cols-5 py-2.5 px-2.5 border-b border-b-100 bg-white hover:bg-gray-200 duration-300 hover:border-gray-300" key={index+1}>
                  <p className=" lg:text-black md:text-black text-[#1E40AF] lg:text-[14px] md:text-[14px] text-[17px] uppercase lg:text-center md:text-center text-left py-1 lg:border-b-0 md:border-b-0 border-b">
                    {item?.model}{" "}
                  </p>
                  <div className="grid grid-cols-2 md:grid-cols-1 place-items-center">
                    <p className="block md:hidden text-[16px]">View</p>
                    <input
                      type="checkbox"
                      value={item?.permissions?.view}
                      name={item?.permissions?.view}
                      checked={singleTeam?.find((it) =>
                        it === item?.permissions?.view ? true : false
                      )}
                      onChange={handleChange}
                      className="mx-auto cursor-pointer"
                    />
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-1 place-items-center">
                    <p className="block md:hidden text-[16px]">Add</p>
                    <input
                      type="checkbox"
                      value={item?.permissions?.add}
                      name={item?.permissions?.add}
                      checked={singleTeam?.find((it) =>
                        it === item?.permissions?.add ? true : false
                      )}
                      onChange={handleChange}
                      className="mx-auto cursor-pointer"
                    />
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-1 place-items-center">
                    <p className="block md:hidden text-[16px]">Change</p>
                    <input
                      type="checkbox"
                      value={item?.permissions?.change}
                      name={item?.permissions?.change}
                      checked={singleTeam?.find((it) =>
                        it === item?.permissions?.change ? true : false
                      )}
                      onChange={handleChange}
                      className="mx-auto cursor-pointer"
                    />
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-1 place-items-center">
                    <p className="block md:hidden text-[16px]">Delete</p>
                    <input
                      type="checkbox"
                      value={item?.permissions?.delete}
                      name={item?.permissions?.delete}
                      checked={singleTeam?.find((it) =>
                        it === item?.permissions?.delete ? true : false
                      )}
                      onChange={handleChange}
                      className="mx-auto cursor-pointer"
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className="mt-5">
            <h1 className="text-[15px] mb-1 font-medium">
              Description
            </h1>
            <textarea
              required
              defaultValue={singlePermission?.description}
              className="w-full h-24 px-4 py-2 rounded-md focus:outline-[0.5px] focus:outline-sky-500 border"
              type="text"
              name="description"
              id=""
              placeholder="Description"
              onChange={handleDescription}
            />
          </div>
          <button
            className="border border-[#1E40AF] rounded-md bg-[#1E40AF] hover:bg-transparent text-white hover:text-[#1E40AF] duration-300 px-10 py-2 mt-2 mb-4"
            type="submit"
          >
            Update
          </button>
        </form> :
        <Loader />}
    </div>
  );
};

export default EditTeam;
