import React, { useEffect, useState } from "react";
import VrmListTable from "./VrmListTable";
import axios from "axios";
import Swal from "sweetalert2";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import useHeaders from "../../Shared/Header/Header";
import { useSelector } from "react-redux";
import usePermission from "./../../lib/Hook/UsePermission";
import { RiAddLine } from "react-icons/ri";
import AddUserModal from "./AddUserModal";

const VRMList = () => {
  const { checkPermission } = usePermission();
  const userData = useSelector((state) => state.userSlice.user);
  const [addUser, setAddUser] = useState(false);
  const [users, setUsers] = useState([]);
  const headers = useHeaders();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const id = userData?.user_data?.user;
  const [changes, setChanges] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    let apiUrl = `${
      process.env.REACT_APP_HOST_URL
    }/api/agent/register/?${search && `search=${search}`}`;
      axios.get(apiUrl, headers).then((res) => {
        if (res?.status === 200) {
          setIsLoading(false);
          setUsers(res.data.results);
        }
      });
  }, [search, id, changes]);
  

  const deleteAlert = (api, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${api}${id}/`, headers).then((res) => {
          if (res.status === 204) {
            const rest = users.filter((item) => item.id !== id);
            setUsers(rest);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        });
      }
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="h-[95vh] overflow-scroll mx-4">
      <div className="flex justify-between items-center mt-3">
        <HeadingTitle title="VRM User List" />
        <button
          onClick={() => setAddUser(true)}
          className="bg-[#2563eb] border border-[#2563eb] hover:bg-transparent hover:text-[#2563eb] duration-300 px-3 py-1.5 rounded-md text-white flex items-center gap-x-1"
        >
          <RiAddLine className="text-2xl" />
          <span>Add VRM User</span>
        </button>
      </div>
      {checkPermission("view_agentprofile") && (
        <VrmListTable
          handleSearch={handleSearch}
          deleteAlert={deleteAlert}
          users={users}
          isLoading={isLoading}
        />
      )}
      {addUser && (
        <AddUserModal
          addUser={addUser}
          setAddUser={setAddUser}
          setChanges={setChanges}
          changes={changes}
        />
      )}
    </div>
  );
};

export default VRMList;
