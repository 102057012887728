import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import useHeaders from "../../../Shared/Header/Header";
import HeadingTitle from "../../../Shared/HeadingTitle/HeadingTitle";
import Loader from "../../../Shared/Loader/Loader";
const ViewFeature = () => {
  const headers = useHeaders();
  const { id } = useParams();
  const [feature, setFeature] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/feature/${id}/`,
        headers
      );
      setFeature(response?.data);
    };

    loadData();
  }, [id]);

  const featureLength = Object.keys(feature).length;
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <div className="h-screen overflow-y-scroll mx-4 mt-4 ">
      <div>
        <HeadingTitle title="View Features" />
      </div>
      {featureLength > 0 ? (
        <div className="border bg-white px-5 py-8 rounded-md mt-3">
          <div className="flex gap-4 lg:gap-6 w-full items-center mb-4">
            <div className="border rounded-md lg:px-5 px-2 py-4 w-full bg-white shadow">
              <h2 className="text-gray-700 text-[16px]">Bank Name</h2>
              <h4 className="text-[18px] font-semibold text-[#1E40AF]">
                {feature?.institute_name || "N/A"}
              </h4>
            </div>

            <div className="border rounded-md lg:px-5 px-2 py-4 w-full bg-white shadow">
              <h2 className="text-gray-700 text-[16px]">Product Type</h2>
              <h4 className="text-[18px] font-semibold text-[#1E40AF]">
                {feature?.product_type || "N/A"}
              </h4>
            </div>

            <div className="border rounded-md lg:px-5 px-2 py-4 w-full bg-white shadow">
              <h2 className="text-gray-700 text-[16px]">Product</h2>
              <h4 className="text-[18px] font-semibold text-[#1E40AF]">
                {feature?.product || "N/A"}
              </h4>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
            <div className="border rounded-md lg:px-5 px-2 py-4 w-full bg-white shadow">
              <h1 className="text-gray-800 text-xl font-semibold">Feature</h1>
              <div
                className="my-2 text-gray-600"
                dangerouslySetInnerHTML={createMarkup(feature?.feature)}
              ></div>
            </div>

            <div className="border rounded-md lg:px-5 px-2 py-4 w-full bg-white shadow">
              <h1 className="text-gray-800 text-xl font-semibold">
                Short Feature
              </h1>
              <div
                className="my-2 text-gray-600"
                dangerouslySetInnerHTML={createMarkup(feature?.short_feature)}
              ></div>
            </div>

            <div className="border rounded-md lg:px-5 px-2 py-4 w-full bg-white shadow">
              <h1 className="text-gray-800 text-xl font-semibold">
                Eligibility
              </h1>
              <div
                className="my-2 text-gray-600"
                dangerouslySetInnerHTML={createMarkup(feature?.eligibility)}
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ViewFeature;
