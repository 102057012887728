import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import HeadingTitle from "../../Shared/HeadingTitle/HeadingTitle";
import Loader from "../../Shared/Loader/Loader";
import useHeaders from "../../Shared/Header/Header";

const ViewUserBenefit = () => {
  const { id } = useParams();
  const headers = useHeaders();
  const [userBenefits, setUserBenefits] = useState({});

  useEffect(() => {
    const loadData = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_URL}/api/user_benefit/${id}`,
        headers
      );
      if (res?.status === 200) {
        setUserBenefits(res?.data);
      }
    };
    loadData();
  }, [id]);
  const objectLength = Object.keys(userBenefits).length;

  return (
    <div className="h-screen m-3 p-3 bg-white">
      <HeadingTitle title="User Benefits" />
      {objectLength > 0 ? (
        <div className="lg:grid block grid-cols-2 gap-x-4 border boxShadow my-8 mx-4 p-6 rounded-2xl">
          <div className="px-4 py-3 rounded-md border my-2 shadow-md bg-white">
            <h4 className="text-gray-800 text-xl font-semibold">Benefit One</h4>
            <div className="my-2 text-gray-500">
              <div
                dangerouslySetInnerHTML={{
                  __html: userBenefits?.benefit_one,
                }}
              />
            </div>
          </div>

          <div className="px-4 py-3 rounded-md border my-2 shadow-md bg-white">
            <h1 className="text-gray-800 text-xl font-semibold">Benefit Two</h1>
            <div className="my-2 text-gray-500">
              <div
                dangerouslySetInnerHTML={{
                  __html: userBenefits?.benefit_two,
                }}
              />
            </div>
          </div>

          <div className="px-4 py-3 rounded-md border my-2 shadow-md bg-white">
            <h1 className="text-gray-800 text-xl font-semibold">
              Benefit Three
            </h1>
            <div className="my-2 text-gray-500">
              <div
                dangerouslySetInnerHTML={{
                  __html: userBenefits?.benefit_three,
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ViewUserBenefit;
