import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import Swal from "sweetalert2";
import LeadListTable from "../LeadList/LeadListTable";
import useHeaders from "../../Shared/Header/Header";
import { RiAddLine } from "react-icons/ri";
import usePermission from "./../../lib/Hook/UsePermission";

const CardLeads = ({ vrmID, vrmUser }) => {
  const headers = useHeaders();
  const { checkPermission } = usePermission();
  const [leadList, setLeadList] = React.useState();
  const [cardLeadData, setCardLeadData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const path = "cardLead";

  useEffect(() => {
    setIsLoading(true);
    if (vrmUser?.user) {
      axios
        .get(
          `${process.env.REACT_APP_HOST_URL}/api/card_lead/?user=${vrmUser?.user}`,
          headers
        )
        .then((resp) => {
          setCardLeadData(resp?.data?.results);
          setIsLoading(false);
        });
    }
  }, [vrmUser?.user]);

  const deleteAlert = (api, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${api}${id}/`, headers).then((res) => {
          if (res.status === 204) {
            const rest = leadList.filter((item) => item.id !== id);
            setLeadList(rest);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        });
      }
    });
  };

  return (
    <div className="h-screen mx-2">
      <div className="mt-5 flex flex-col justify-end items-end">
        <div className="flex items-center gap-x-2">
          {checkPermission("add_virtualagentlead") && (
            <div className="bg-[#2563eb] border border-[#2563eb] hover:bg-transparent hover:text-[#2563eb] duration-300 px-4 py-2 rounded-md text-white">
              <Link
                to={
                  vrmID
                    ? `/newCardlead/${cardLeadData?.id || vrmID}`
                    : `/newCardlead`
                }
              >
                <div className="flex items-center gap-x-1">
                  <RiAddLine className="text-2xl" />
                  <span>Create Card Lead</span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
      {checkPermission("view_virtualagentlead") && (
        <div>
          <LeadListTable
            deleteAlert={deleteAlert}
            data={cardLeadData}
            path={path}
            isloading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default CardLeads;
