import React from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";

const DataShowTable = ({ item, handleDelete }) => {
  return (
    <div className="flex flex-col mt-2">
      {item?.length > 0 ? (
        <div className="py-2 overflow-x-auto">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-md">
            <table className="min-w-full overflow-scroll">
              <thead className=" bg-gray-700 text-white">
                <tr className="text-xs font-medium leading-4 tracking-wider text-center uppercase">
                  <th className="py-3 border-b border-gray-200 pl-3">#</th>
                  <th className="py-3 border-b border-gray-200">Group Name</th>
                  <th className="py-3 border-b border-gray-200">
                    Group Detaills
                  </th>
                  <th className="py-3 border-b border-gray-200">Created By</th>
                  <th className="py-3 border-b border-gray-200">Created At</th>
                  <th className="py-3 border-b border-gray-200">Action</th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {item &&
                  item?.map((team, index) => (
                    <tr className="text-center border hover:bg-gray-100 duration-300" key={index+1}>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200 pl-3">
                        <p className="text-md leading-5 text-gray-600">
                          {index + 1}
                        </p>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-md leading-5 text-gray-600">
                          {team?.name}
                        </p>
                      </td>

                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <p className="text-md leading-5 text-gray-600">
                          {team?.description}
                        </p>
                      </td>

                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                          {/* {team?.created_by} */}
                          Super Admin
                        </span>
                      </td>

                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                          {team?.created_at?.split(" ")[0]}
                        </span>
                      </td>
                      <td className="py-2 whitespace-no-wrap border-b border-gray-200">
                        <div className="flex justify-center items-center gap-x-1">
                          <Link to={`/createteam/${team.id}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-blue-500"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                          </Link>
                          {/* <button onClick={() => handleDelete(team)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-red-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </button> */}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default DataShowTable;
