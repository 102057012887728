import React from "react";

const HeadingTitle = ({ title }) => {
  return (
    <>
      <h1 className="text-lg text-[#1E40AF] font-medium">{title} </h1>
    </>
  );
};

export default HeadingTitle;
