import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const ChatTabs = ({
  handleSelectUser,
  chatUser,
  closeUser,
  handleAciveTab,
  handleCloseTab,
}) => {
  return (
    <Tabs className="w-full ">
      <TabList>
        <Tab onClick={handleAciveTab}>Live</Tab>
        <Tab onClick={handleCloseTab}>Closed</Tab>
      </TabList>
      <TabPanel>
        <div className="mt-3">
          {chatUser?.length > 0 ? (
            <>
              {chatUser?.map((item, index) => (
                <div key={index + 1}>
                  <button
                    onClick={() => handleSelectUser(item)}
                    className="px-3 py-2 flex items-center hover:bg-[#2563eb] focus:bg-[#2563eb] focus:text-white active:bg-[#2563eb] active:text-white hover:text-white duration-300 w-full border-b border-b-gray-200 bg-white mb-1"
                  >
                    <img
                      className="rounded-full h-9 w-9 mx-2"
                      src={item?.user_image}
                      alt="Profile"
                    />
                    <div className="flex flex-col justify-center items-start ml-2">
                      <h4 className="text-[16px]">{item?.user_name}</h4>
                      <p className="text-gray-400 text-sm">{item?.user}</p>
                    </div>
                  </button>
                </div>
              ))}
            </>
          ) : (
            <p className="text-center py-10 text-lg text-gray-400">
              No Active Chat Found
            </p>
          )}
        </div>
      </TabPanel>
      <TabPanel>
        <div className="mt-3">
          {closeUser ? (
            <>
              {closeUser?.map((item, index) => (
                <div key={index + 1}>
                  <button
                    onClick={() => handleSelectUser(item)}
                    className="px-3 py-2  hover:bg-[#2563eb] focus:bg-[#2563eb] focus:text-white active:bg-[#2563eb] active:text-white hover:text-white duration-300 w-full border-b border-b-gray-200 bg-white mb-1"
                  >
                    <div className="flex items-center">
                      <img
                        className="rounded-full h-9 w-9 mx-2"
                        src={item?.user_image}
                        alt="Profile"
                      />
                      <div className="flex flex-col justify-center items-start ml-2">
                        <h4 className="text-[16px]">{item?.user_name}</h4>
                        <p className="text-gray-400 text-sm">{item?.user}</p>
                      </div>
                    </div>
                  </button>
                </div>
              ))}
            </>
          ) : (
            <p className="text-center py-10 text-lg text-gray-400">
              No Close Chat Found
            </p>
          )}
        </div>
      </TabPanel>
    </Tabs>
  );
};

export default ChatTabs;
