import React from "react";
import { FiSend } from "react-icons/fi";
import { CgClose } from "react-icons/cg";

const SendMessageForm = ({
  handleMessageSend,
  setNewFile,
  handleChat,
  setNewMessage,
  chatUser,
  handleEnd,
}) => {
  return (
    <div>
      <form
        onSubmit={handleMessageSend}
        className="flex justify-between items-center relative top-0 border-y border-gray-200 py-2 px-2"
      >
        <div className="w-[82%]">
          <div className="flex items-center gap-x-2">
            {/* <div className="wrapper"> */}
            <input
              onChange={(e) => setNewFile(e.target.files[0])}
              type="file"
              id="file-input"
              style={{ display: "none" }}
            />
            <input
              onKeyPress={handleChat}
              onChange={(e) => setNewMessage(e.target.value)}
              className="w-full rounded-md h-12 px-4 border border-gray-200 focus:outline-0 focus:border-[#2563eb]"
              type="text"
              placeholder="Type Your Message"
            />
          </div>
        </div>
        <button type="submit" className="w-6%">
          <FiSend
            className="mx-2 text-[#2563eb] hover:text-sky-700 duration-300 cursor-pointer"
            size={30}
          />
        </button>
        {chatUser?.length > 0 && (
          <button
            onClick={handleEnd}
            type="button"
            className="w-[12%] bg-red-100 p-2 text-center rounded-full text-red-600 flex items-center justify-center gap-x-1 hover:bg-red-500 hover:text-white duration-300 cursor-pointer whitespace-nowrap"
          >
            <p>Close Chat</p>
            <CgClose className="text-xl" />
          </button>
        )}
      </form>
    </div>
  );
};

export default SendMessageForm;
