import React, { useState, useEffect } from "react";
import { Lists, ContentStyle } from "./styles";
import Tab from "./Tab";

function Content({ children, active, id }) {
  return (
    <ContentStyle className={active ? "show" : "hidden"}>
      {children}
    </ContentStyle>
  );
}

export default function Tabs({ items, data }) {
  const [active, setActive] = useState({
    id: "Dashboard",
    label: "Dashboard",
    content: "Dashboard",
  });

  useEffect(() => {
    setActive(items[1]);
  }, [items]);

  return (
    <>
      <Lists className="pb-2 pt-1 border-b">
        {items.map((item, index) => (
          <Tab
            label={item.label}
            key={index + 1}
            active={active === item}
            onClick={() => setActive(item)}
          />
        ))}
      </Lists>
      <div>
        {items.map((item, index) => (
          <Content active={active === item} key={index + 1}>
            {item.content}
          </Content>
        ))}
      </div>
    </>
  );
}
